import React from 'react';
import { Table, TableHead, TableBody, TableCell, TableRow, Icon } from '@material-ui/core/';

import classNames from 'classnames';


class AlertTable extends React.Component {
    state = {  }
    render() { 
        const { unit, classes } = this.props
        return ( 
            <Table className={classes.alertsTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>Alert level</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Solved</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {unit[0].historicAlerts 
                    ? 
                    (unit[0].historicAlerts.map((historicAlert, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    <Icon className={classNames("fa fa-exclamation-circle", classes.iconSolved, classes.icon)} />
                                </TableCell>
                                <TableCell>
                                    {historicAlert.message}
                                </TableCell>
                                <TableCell>
                                    2019-10-10
                                </TableCell>
                            </TableRow>
                        )
                    }))
                    :
                    (                  
                        <TableRow />
                    )
                }
                </TableBody>
            </Table>
        );
    }
}
 

        
        
export default AlertTable;