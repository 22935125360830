import { createMuiTheme } from '@material-ui/core/styles';
import { red, yellow, orange } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles';
import Logotype from '../assets/images/logoLightTheme.svg'
import LogoFlow from '../assets/images/logoFlow.svg'
import LogoStore from '../assets/images/logoStore.svg'
import Roboto from '../assets/fonts/Roboto/Roboto-Regular.ttf';
import PlayFair from '../assets/fonts/Playfair_Display/PlayfairDisplay-Regular.ttf';

const roboto = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    src: `
    local('Roboto'),
    local('Roboto-Regular'),
    url(${Roboto}) format('ttf')
  `,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const playFair = {
    fontFamily: 'PlayfairDisplay-Regular',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    src: `
    local('PlayfairDisplay'),
    local('PlayfairDisplay-Regular'),
    url(${PlayFair}) format('ttf')
  `,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const lightTheme = createMuiTheme({
    logotype: {
        logo: Logotype
    },
    palette: {
        primary: { main: "#44799E", transparent: "#44799E1A" }, // blue
        secondary: { main: "#41AB9E" }, // teal
        body: { main: "#f5f5f5", variant: "#f2f2f2" }, // white
        card: { main: '#ffffff' }, // white
        darkBlue: { main: "#2F7BB0" }, // dark blue
        lightBlue: { main: "#41C8E0" }, // light blue
        white: { main: "#ffffff" }, // white
        black: { main: "#001421" }, //Black
        grey: { main: "#3E3E3E" }, // Grey
        darkGrey: { main: "#707070" }, //Dark Grey
        text: { main: '#292F36' }, // black
        error: { main: red[500], transparent: "#f443361a" }, // red
        warning: { main: orange[500] }, // orange
        minor: { main: yellow[600] }
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [roboto],
            },
        },
    },
});

export const redTheme = createMuiTheme({
    logotype: {
        logo: LogoFlow
    },
    palette: {
        primary: { main: "#7D1128", transparent: "#7D11281A" }, // blue
        secondary: { main: "#E63946" }, // teal
        body: { main: "#f5f5f5", variant: "#f2f2f2" }, // white
        card: { main: '#ffffff' }, // white
        darkBlue: { main: "#2F7BB0" }, // dark blue
        lightBlue: { main: "#41C8E0" }, // light blue
        white: { main: "#ffffff" }, // white
        black: { main: "#001421" }, //Black
        grey: { main: "rgba(0, 20, 33, 0.54)" }, // Grey
        darkGrey: { main: "#707070" }, //Dark Grey
        text: { main: '#292F36' }, // black
        error: { main: red[500], transparent: "#f443361a" }, // red
        warning: { main: orange[500] }, // orange
        minor: { main: yellow[600] }
    },
    typography: {
        fontFamily: 'PlayfairDisplay-Regular',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [playFair],
            },
        },
    },
});

export const LightGreenTheme = createMuiTheme({
    logotype: {
        logo: LogoStore
    },
    palette: {
        primary: { main: "#929487", transparent: "#9294871a" }, // blue
        secondary: { main: "#C3DAC3" }, // teal
        body: { main: "#f2f2f2", variant: "#f2f2f2" }, // white
        card: { main: '#ffffff' }, // white
        darkBlue: { main: "#2F7BB0" }, // dark blue
        lightBlue: { main: "#41C8E0" }, // light blue
        white: { main: "#ffffff" }, // white
        black: { main: "#001421" }, //Black
        grey: { main: "rgba(0, 20, 33, 0.54)" }, // Grey
        darkGrey: { main: "#707070" }, //Dark Grey
        text: { main: '#292F36' }, // black
        error: { main: red[500], transparent: "#f443361a" }, // red
        warning: { main: orange[500] }, // orange
        minor: { main: yellow[600] }
    },
    typography: {
        fontFamily: 'monospace',
    },
});

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));
