export const styles = theme => ({
    paper: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    icon: {
        fontSize: "1.5em",
        color: theme.palette.secondary.main
    },
    leftFat: {
        margin: "0",
        padding: "0px 16px",
        textAlign: "left",
        fontSize: "16px",
        fontWeight: "700"
    },
    rightFat: {
        float: "right",
        fontSize: "16px",
        fontWeight: "700",
        color: "rgba(0, 185, 85, 0.87)"
    },
    thinText: {
        fontSize: "12px",
        fontWeight: "200",
    },
    formControl: {
        marginTop: '-5px'
    },
    selectHolder: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    selectHolderInner: {
        display: 'flex'
    }
});