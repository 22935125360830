export const styles = theme => ({
    container: {
        margin: "12px 0px", 
        padding: "0"
    },
    icon: {
        fontSize: "1.5em",
        width: "100%"
    },
    paper: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    avatar: {
        width: "50px",
        height: "50px",
        boxShadow: "1px 2px 3px -1px rgba(0,0,0,.7)",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "3px",
        padding: "15px",
        marginRight: "15px",
        float: "left",
    },
    title: {
        fontWeight: "700",
        color: theme.palette.black.main,
        borderBottom: "3px solid",
        borderBottomColor: theme.palette.primary.main,
        marginBottom: "24px"
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
        margin: "0 auto",
        width: "100%",
        height: "36px",
        borderRadius: "2px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)",
        '&:hover': {
            backgroundColor: theme.palette.darkBlue.main
        }
    },
});