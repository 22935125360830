import React from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, TableRow, TableCell, Paper, Card, CardContent, Icon, Avatar, Typography } from "@material-ui/core";
import { BrowserRouter as Route, Link, Switch } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { SignalCellularOff, SignalCellular0Bar, SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar } from '@material-ui/icons';
import CountDown from '../countDown/CountDown';
import classNames from 'classnames';
import { styles } from './fleetTableStyle';
import mopedImage from '../../../assets/images/moped.jpg';
import vespaImage from '../../../assets/images/vespa.jpg';

import SingleUnitContainer from '../../singleUnit/singleUnitContainer';
import './fleetTable.css';
import { classes } from "istanbul-lib-coverage";

class FleetTableView extends React.Component {
    getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MuiToolbar: {
                    root: {
                        paddingLeft: '0px !important',

                    }
                },
                MuiTypography: {
                    root: {
                        fontWeight: 'bold !important'
                    }
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(even)': {
                            '@media(max-width:959.95px)': {
                                width: "40% !important",
                                height: "auto!important",
                                display: "inline-block",
                                fontSize: "14px !important",
                                whiteSpace: "nowrap",
                                '&:last-child': {
                                    padding: "10px 40px 10px 16px"
                                }
                            }
                        },
                        '&:nth-child(odd)': {
                            '@media(max-width:959.95px)': {
                                width: "60% !important",
                                height: "auto !important",
                                display: "inline-block",
                                fontSize: "14px !important",
                            }
                        },
                    }
                },
                MuiTableCell: {
                    root: {
                        textAlign: 'center',
                        /*'&:nth-child(1)': {
                            '& div': {
                                display: 'flex',
                                justifyContent: 'center'
                            }
                        }*/
                    }
                },
                MUIDataTableHeadCell: {
                    root: {
                        '& span': {
                            justifyContent: 'center'
                        },
                        '&:nth-child(1)': {
                            '& span': {
                                justifyContent: 'flex-start'
                            }
                        }
                    }
                },
                MUIDataTablePagination: {
                    root: {
                        borderBottom: '0px'
                    }
                }
            }
        });

    constructor(props) {
        super(props);
        this.state = {
            units: this.props.units,
            rowHover: '',
            colsNotToDisplay: []
        }
        this.visibleMarkers = []
        this.columns = [
            {
                name: "registrationNumber",
                label: "RegNr.",
                options: this.getRegColumnOptions()
            },
            {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (active) => {
                        return (

                            <span>{active ? "Yes" : "No"}</span>

                        );
                    }
                }
            },
            {
                name: "outOffBounds",
                label: "Out of bounds",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (bounds) => {
                        return (

                            <span>{bounds ? "Yes" : "No"}</span>

                        );
                    }
                }
            },
            {
                name: "alertAmount",
                label: "Alerts",
                options: this.getAlertOptions()
            },
            {
                name: "batteryLevel",
                label: "Battery Level",
                options: this.getBatteryLevelOptions()
            },
            {
                name: "simCoverage",
                label: "Sim Coverage",
                options: {
                    sort: true,
                    customBodyRender: (coverage) => {
                        if (coverage === 0)
                            return (
                                <Tooltip title="Bad coverage">
                                    <SignalCellular0Bar />
                                </Tooltip>
                            );
                        if (coverage === 1)
                            return (
                                <Tooltip title="Low coverage">
                                    <SignalCellular1Bar />
                                </Tooltip>
                            );
                        if (coverage === 2)
                            return (
                                <Tooltip title="Medium coverage">
                                    <SignalCellular2Bar />
                                </Tooltip>
                            );
                        if (coverage === 3)
                            return (
                                <Tooltip title="Good coverage">
                                    <SignalCellular3Bar />
                                </Tooltip>
                            );
                        if (coverage === 4)
                            return (
                                <Tooltip title="Great coverage">
                                    <SignalCellular4Bar />
                                </Tooltip>
                            );
                        else
                            return (
                                <Tooltip title="No coverage found">
                                    <SignalCellularOff />
                                </Tooltip>
                            );
                    }
                }
            },
            {
                name: "nextService",
                label: "Next service",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (nextService) => {
                        return (
                            <span>{nextService} {<CountDown date={nextService} subHeaderText={true} />}</span>
                        );
                    }
                }
            },
        ];
    }

    getAlertOptions() {
        let options = {
            filter: true,
            sort: true,
            customBodyRender: (alerts) => {
                return (
                    <span>{alerts}</span>
                );
            }
        }

        if (this.props.hash === '#alerts') {
            options.sortDirection = 'desc'
        }
        return options
    }

    getBatteryLevelOptions() {
        let options = {
            filter: true,
            sort: true,
            customBodyRender: (batteryLevel) => {
                return (
                    <span>{batteryLevel}</span>
                );
            }
        }

        if (this.props.hash === '#batteryLevel') {
            options.sortDirection = 'asc'
        }

        return options
    }

    getRegColumnOptions() {
        let options = {
            filter: true,
            sort: true,
            viewColumns: false,
            customBodyRender: (id, i) => {
                let image = this.props.units.filter((unit) => unit.registrationNumber === id)[0].image
                return (
                    <div className='registrationTd' id={id}>
                        <div className='registrationText'>
                            <Link className="link" to={`/case1/singleunit#${id}`}>{id}</Link>
                            <Switch>
                                <Route path='/case1/singleunit' component={SingleUnitContainer} />
                            </Switch>
                        </div>
                        <img
                            src={image === "moped" ? mopedImage : vespaImage}
                            alt="picture of the vehicle"
                            className="image"
                        />
                    </div>
                );
            }
        }
        if (this.props.hash.length === 0) {
            options.sortDirection = 'asc'
        }
        return options
    }

    componentWillReceiveProps() {
        this.visibleMarkers = []
    }

    componentDidMount() {
        this.props.addMarkers(this.visibleMarkers)
    }

    checkVisibleMarkers(row) {
        let markerExists = false
        this.visibleMarkers.forEach((marker) => {
            if (marker === row[0].props.id) {
                markerExists = true
            }
        })

        if (!markerExists) {
            this.visibleMarkers.push(row[0].props.id)
        }
    }

    checkColumn(i) {
        let show = true
        this.state.colsNotToDisplay.forEach((c) => {
            if (c === i) {
                show = false
            }
        })
        return show
    }

    render() {
        const { units, activeInFleetViewReg, addMarkers, setActiveOnMap, classes } = this.props
        const options = {
            responsive: "stacked",
            filterType: 'dropdown',
            selectableRows: 'none',
            download: false,
            print: false,
            filter: true,
            rowsPerPageOptions: [3, 5, 10, 20],
            rowsPerPage: 5,
            selectableRowsOnClick: false,
            rowHover: true,
            onTableChange: () => {
                addMarkers(this.visibleMarkers)
                setActiveOnMap('', undefined)
            },
            onColumnViewChange: (col, action) => {
                const cols = this.state.colsNotToDisplay.slice()
                if (action === 'remove') {
                    cols.push(this.columns.map((c) => c.name).indexOf(col))
                } else {
                    cols.splice(cols.indexOf(col), 1)
                }
                this.setState({ ...this.state, colsNotToDisplay: cols })
            },
            customRowRender: (row, i) => {
                this.checkVisibleMarkers(row, i)
                return (
                    <TableRow
                        className={this.props.activeInFleetViewReg === row[0].props.id ? 'tableRow active' : 'tableRow'}
                        key={i}
                        onClick={() => {
                            if (this.props.activeInFleetViewReg === row[0].props.id) {
                                setActiveOnMap('', undefined)
                            } else {
                                setActiveOnMap(row[0].props.id, units.filter((unit) => unit.registrationNumber === row[0].props.id)[0].position)
                            }
                        }}
                    >
                        {row.map((cell, i) => {
                            if (this.checkColumn(i)) { return <TableCell key={i}>{cell}</TableCell> }
                        })}
                    </TableRow>
                )
            }
        };
        return (
            <MuiThemeProvider theme={this.getMuiTheme()}>
                <Card className={classes.card}>
                    <CardContent className={classes.tableCard}>
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            <Icon className={classNames(classes.icon, "fa fa-motorcycle")} />
                        </Avatar>
                        <Typography
                            align="right"
                            className={classes.title}
                        >
                            Vehicles
                        </Typography>
                        <MUIDataTable
                            title={"Vehicles (" + units.length + " in total)"}
                            data={units}
                            columns={this.columns}
                            options={options}
                            className={classes.fleetTable}
                        />
                    </CardContent>
                </Card>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(styles)(FleetTableView);