import React from 'react'
import { Typography, Grid, IconButton, Icon, Avatar } from '@material-ui/core/'
import { Close } from '@material-ui/icons'
import classNames from 'classnames'

import { styles } from './sideListStyle'


export default function SideList(props) {
    const classes = styles();

    return (
        <div
            className={classes.list}
            role="presentation"
        >
            <div className={classes.drawerHeader}>
                <Typography variant="h6" noWrap>
                    <p>Settings</p>
                </Typography>
                <IconButton
                    className={classes.iconButton}
                    onClick={props.toggleDrawer(props.side, false)}
                >
                    <Close />
                </IconButton>
            </div>
            <Grid container justify="flex-start" alignItems="flex-end">
                <Avatar>
                    <Icon className={classNames(classes.icon, "fa fa-user")} />
                </Avatar>
                <Typography variant="h6" className={classes.userName}>John Doe </Typography>
            </Grid>
            <p>
                Lorem Ipsum är en utfyllnadstext från tryck- och förlagsindustrin.
                Lorem ipsum har varit standard ända sedan 1500-talet, när en okänd boksättare tog att antal bokstäver och blandade dem för att göra ett provexemplar av en bok.
                Lorem ipsum har inte bara överlevt fem århundraden, utan även övergången till elektronisk typografi utan större förändringar.
            </p>
            <br />
        </div>
    )
}