export const styles = theme => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
        margin: "0 auto",
        width: "100%",
        height: "36px",
        borderRadius: "2px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)",
        '&:hover': {
            backgroundColor: theme.palette.darkBlue.main
        }
    },
    buttonContainer: {
        marginTop: theme.spacing(2)
    },
    icon: {
        marginLeft: "8px",
        fontSize: "medium",
    }
})