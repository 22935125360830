import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import FleetTable from '../components/fleetTable/fleetTableContainer'
import FleetMap from '../components/maps/fleetMap'
import { styles } from './fleetViewStyle'

class FleetView extends React.Component {

    render() {
        const { visibleMarkers, units, activeInFleetViewReg, activeInFleetViewPos, addMarkers, setActiveInTable, classes } = this.props
        let unitsFound = []
        visibleMarkers.forEach((visibleMarker) => {
            units.forEach((unit) => {
                if (visibleMarker === unit.registrationNumber) {
                    unitsFound.push(unit)
                }
            })
        })

        return (
            <Container className={classes.mapTableContainer} maxWidth={false}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.map}>
                        <FleetMap
                            units={unitsFound}
                            height="225px"
                            activeInFleetViewReg={activeInFleetViewReg}
                            activeInFleetViewPos={activeInFleetViewPos}
                            setActiveInTable={setActiveInTable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="fleetListHolder">
                            <FleetTable
                                units={units}
                                addMarkers={(units) => addMarkers(units)}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(styles)(FleetView)