import * as React from "react";
import { Map, TileLayer, Polygon, Circle } from "react-leaflet";
import L from 'leaflet';
import { withStyles } from '@material-ui/core/styles';
import 'leaflet/dist/leaflet.css';
import { thailand } from './geoFences/thailand';
import { styles } from './overviewMapStyle'
import Hidden from '@material-ui/core/Hidden'



class previewMap extends React.Component {
    constructor(props) 
    {
        super(props)
    }
    state = {
        center: [13.758039, 100.516913],
        zoom: 8,
        maxZoom: 18,
        minZoom: 2,
        scrollWheelZoom: false,
        scrollOverLay: false,
        zoneType: this.props.zoneType,
    }
    
    handleScroll() {
        this.setState({
            scrollWheelZoom: !this.state.scrollWheelZoom
        })
    }

    addScrollOverlay() {
        this.setState({
            scrollOverLay: true
        })
    }

    removeScrollOverlay() {
        this.setState({
            scrollOverLay: false
        })
    }

    render() {
        const { classes, height, mapRef, radius } = this.props;
        const { maxZoom, minZoom, zoom, center, zoneType } = this.state

        return (
            <div className={classes.mapHolder}>
                <Map
                    ref={mapRef}
                    maxBounds={L.latLngBounds([-90, -180], [90, 180])}
                    center={center}
                    maxZoom={maxZoom}
                    minZoom={minZoom}
                    zoom={zoom}
                    className={this.state.scrollOverLay && !this.state.scrollWheelZoom ? "scrollOverlay" : "singleMapPadded"}
                    style={{ height: height }}
                    scrollWheelZoom={this.state.scrollWheelZoom}
                    onClick={() => this.handleScroll()}
                    onMouseOver={() => this.addScrollOverlay()}
                    onMouseOut={() => this.removeScrollOverlay()}
                >
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        noWrap={true}
                    />
                    <Hidden xlDown={zoneType === "radius" ? true : false}>
                        <Polygon positions={thailand} color="grey" />
                    </Hidden>
                    <Hidden xlDown={zoneType === "polygon" ? true : false}>
                        <Circle center={center} radius={radius} color="grey" />
                    </Hidden>
                </Map>
            </div>
        );
    }
}

export default withStyles(styles)(previewMap);