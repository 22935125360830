import React from 'react'
import { Grid } from '@material-ui/core'
import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/images/marker-shadow.png'

import Overview from '../components/overview/overview' // TEST DESIGN
import BarChartComponent from '../components/charts/barChartComponent' // TEST DESIGN
import AreaChartComponent from '../components/charts/areaChartComponent'
import DashboardTable from '../components/table/dashboardTable'
import OverviewMap from '../components/maps/overviewMap'
import CardList from '../components/cardList/cardList.jsx'


// Dashboard barchart data
const BarChartSeries = [{
    name: 'Alerts',
    data: [14, 32, 44, 30, 8, 10, 29, 23, 30, 4, 11, 16]
}]

// Dashboard BarChart categories
const BarChartCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const AreaChartSeries = [{
    name: 'Incomes',
    data: [3100, 4000, 2800, 5100, 4200, 10900, 10000, 4200, 10900, 10000, 4500, 6500]
}, 
{
    name: 'Expenses',
    data: [1100, 3200, 4500, 3200, 3400, 5200, 4100, 1100, 3200, 4500, 3200, 5700]
}]

class AdminDashboardView extends React.Component {
    render() {
        const { units, theme } = this.props

        return (
            <>
                <Overview units={units} />
                {/* <Overview /> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={3}>
                        <Grid item xs={12}>
                            <CardList units={units} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} lg={9}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={12} lg={6}>
                                <BarChartComponent
                                    series={BarChartSeries}
                                    categories={BarChartCategories}
                                    height="220px"
                                    title={"Alert count history"}
                                    colors={[theme.palette.white.main]}
                                    suffix={false}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <DashboardTable />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <AreaChartComponent
                                    series={AreaChartSeries}
                                    height="220px"
                                    fillColors={[theme.palette.white.main, theme.palette.grey.main]}
                                    colors={[theme.palette.white.main, theme.palette.grey.main]}
                                    strokeColors={[theme.palette.grey.main, theme.palette.white.main]}
                                    markerColors={[theme.palette.white.main, theme.palette.grey.main]}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <OverviewMap
                                    units={units}
                                    height="340px"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default AdminDashboardView;