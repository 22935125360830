import { connect } from "react-redux";
import ThemeSwitch from './themeSwitch';
import { switchTheme } from '../../commonActions/commonActions';

const mapStateToProps = (state) => {
    return {
        theme: state.themeReducer.theme,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchTheme: (theme) => dispatch(switchTheme(theme)),
    };
}

const ThemeSwitchContainer = connect(mapStateToProps, mapDispatchToProps)(ThemeSwitch);

export default ThemeSwitchContainer;