import React, { Component } from 'react';

class CustomCard extends Component {

    state = {}

    /* Using this component instead of material-ui card because of styling issues */
    render() {
        return (
            <>
                {this.props.children}
            </>
        );
    }
}

export default CustomCard;