import React from 'react';
import { Route, Link, Switch } from "react-router-dom";
import { Drawer, Divider, Grid, CardContent, Table, TableBody, TableCell, TableRow, Typography, Tooltip, Hidden } from '@material-ui/core';
import { Error, Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import SingleUnitContainer from '../../singleUnit/singleUnitContainer'
import Card from '../card';
import { styles } from './expandableDrawerViewStyle'
import './expandable.css'

class ExpandableDrawerView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bottom: this.props.expanded,
            side: this.props.expanded
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.toggleMarkerInfo();
        this.props.setCurrentMarker(undefined)
    }

    render() {
        const { classes, marker } = this.props;

        return (
            <div>
                <Drawer
                    anchor="bottom"
                    open={this.props.expanded}
                    variant="persistent"
                >
                    {
                        marker ?
                            <Grid
                                container>
                                <Grid item xs={12} md={6} className={classes.detailsBox}>
                                    <Card>
                                        <CardContent>
                                            <Typography
                                                align="left"
                                                className={classes.title}
                                            >
                                                General Information
                                                <Hidden mdUp>
                                                    <Close onClick={this.handleClick} className={classes.iconClose} />
                                                </Hidden>
                                            </Typography>
                                            <Table className={classes.table}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="value">RegNr:</TableCell>
                                                        <TableCell>
                                                            <Link to={`/case1/singleunit#${marker.registrationNumber}`}>{marker.registrationNumber}</Link>
                                                            <Switch>
                                                                <Route path='/case1/singleunit' component={SingleUnitContainer} />
                                                            </Switch>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="value">Active:</TableCell>
                                                        <TableCell>{marker.active ? "Yes" : "No"}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="value">Next service:</TableCell>
                                                        <TableCell>{marker.nextService}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="value">Out off bounds:</TableCell>
                                                        <TableCell>{marker.outOffBounds ? "Yes" : "No"}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="value">Mobile Coverage:</TableCell>
                                                        <TableCell>{marker.simCoverage}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Card>
                                        <CardContent>
                                            <Typography
                                                align="left"
                                                className={classes.title}
                                            >
                                                Active Alerts
                                                <Hidden smDown>
                                                    <Tooltip title="Close" placement="top">
                                                        <Close onClick={this.handleClick} className={classes.iconClose} />
                                                    </Tooltip>
                                                </Hidden>
                                            </Typography>
                                            <div>
                                                {marker.alerts.map((alerts, index) => {
                                                    if (alerts.level === 1) {
                                                        return (
                                                            <div key={index}>
                                                                <Typography align="left" className={classes.text}>
                                                                    <Error className={classes.iconCritical} />
                                                                    {alerts.message + " " + alerts.time}
                                                                </Typography>
                                                                <Divider />
                                                            </div>
                                                        )
                                                    } else if (alerts.level === 2) {
                                                        return (
                                                            <div key={index}>
                                                                <Typography align="left" className={classes.text}>
                                                                    <Error className={classes.iconMajor} />
                                                                    {alerts.message + " " + alerts.time}
                                                                </Typography>
                                                                <Divider />
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div key={index}>
                                                                <Typography align="left" className={classes.text}>
                                                                    <Error className={classes.iconMinor} />
                                                                    {alerts.message + " " + alerts.time}
                                                                </Typography>
                                                                <Divider />
                                                            </div>
                                                        )
                                                    }
                                                })}
                                                {Object.keys(marker.alerts).length === 0 ? "No active alerts" : ""}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            :
                            <span></span>
                    }
                </Drawer>
            </div>
        );
    }
}

export default withStyles(styles)(ExpandableDrawerView);
