import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Kpi from '../components/kpi/kpi'
import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/images/marker-shadow.png'
import { withStyles } from '@material-ui/core/styles'

import SortableMapContainer from '../components/maps/sortableMap/sortableMapContainer'
import ExpandableContainer from '../components/expandable/expandableContainer'
import FilteringPagingUnits from '../components/filteringPagingUnits/filteringPagingUnits'
import { styles } from './landingPageStyle'
import * as randomMovement from "../../randomMovement.js"
import _ from "lodash"

class LandingPageView extends React.Component {

    constructor(props) {
        super(props)
        const { units, savedFilter, pageInc } = this.props
        this.state = {
            unitsToMove: units,
            outOffBoundsChecked: savedFilter ? savedFilter.outOffBoundsChecked : true,
            insideBoundsChecked: savedFilter ? savedFilter.insideBoundsChecked : true,
            alertsChecked: savedFilter ? savedFilter.alertsChecked : true,
            healthyChecked: savedFilter ? savedFilter.healthyChecked : true,
            unitsAmount: pageInc ? pageInc : 25,
            pageIncrement: pageInc ? pageInc : 25,
        }
    }

    pageAndFilterUnits() {
        const { unitsToMove, alertsChecked, outOffBoundsChecked, insideBoundsChecked, healthyChecked } = this.state
        let pagedAndFilteredUnits = unitsToMove.filter((unit, i) => {
            if (i >= this.state.unitsAmount - this.state.pageIncrement && i <= this.state.unitsAmount) {
                return unit
            }
        })

        if (!alertsChecked) {
            pagedAndFilteredUnits = pagedAndFilteredUnits.filter((unit) => unit.alerts.length === 0)
        }
        if (!outOffBoundsChecked) {
            pagedAndFilteredUnits = pagedAndFilteredUnits.filter((unit) => !unit.outOffBounds)
        }
        if (!insideBoundsChecked) {
            pagedAndFilteredUnits = pagedAndFilteredUnits.filter((unit) => unit.outOffBounds)
        }
        if (!healthyChecked) {
            pagedAndFilteredUnits = pagedAndFilteredUnits.filter((unit) => unit.alerts.length >= 1)
        }

        if (this.reInitializeMovement) {
            clearInterval(this.intervalID)
            this.move()
            setTimeout(() => this.move(), 10)
            this.intervalID = setInterval(() => this.move(), 75000)
            this.reInitializeMovement = false
        }

        return pagedAndFilteredUnits
    }

    subtractPaging() {
        let unitsAmount = this.state.unitsAmount

        if (unitsAmount - this.state.pageIncrement <= 0) {
            unitsAmount = this.state.pageIncrement
        } else {
            unitsAmount = unitsAmount - this.state.pageIncrement
        }
        this.reInitializeMovement = true
        this.setState({ ...this.state, unitsAmount: unitsAmount })
    }

    addPaging() {
        let unitsAmount = this.state.unitsAmount

        if (unitsAmount <= this.props.units.length) {
            unitsAmount = unitsAmount + this.state.pageIncrement
        }
        this.reInitializeMovement = true
        this.setState({ ...this.state, unitsAmount: unitsAmount })
    }

    changePageInc(d) {
        this.reInitializeMovement = true
        this.setState({ ...this.state, pageIncrement: d.props.value, unitsAmount: d.props.value })
        this.props.savePageInc(d.props.value)
    }

    move() {
        const data = randomMovement.getCordinates()
        this.props.updateUnits(data.units)
        this.setState({
            unitsToMove: data.units.concat(this.props.initialUnits)
        })
    }

    componentDidMount() {
        this.move()
        setTimeout(() => this.move(), 10)
        this.intervalID = setInterval(() => this.move(), 75000)
    }

    reinitializeMovement() {
        this.reInitializeMovement = true
    }

    render() {
        const { units, setCurrentMarker, saveFilter, updateUnits, classes } = this.props
        const { outOffBoundsChecked, insideBoundsChecked, alertsChecked, healthyChecked } = this.state

        return (
            <Container className={classes.container} maxWidth={false}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <FilteringPagingUnits
                            outOffBoundsChecked={outOffBoundsChecked}
                            insideBoundsChecked={insideBoundsChecked}
                            alertsChecked={alertsChecked}
                            healthyChecked={healthyChecked}
                            subtractPaging={() => this.subtractPaging()}
                            addPaging={() => this.addPaging()}
                            changePageInc={(d) => this.changePageInc(d)}
                            setState={(data) => {
                                this.setState({ ...this.state, ...data })
                                saveFilter(data)
                            }}
                            pageIncrement={this.state.pageIncrement}
                            units={units}
                            unitsAmount={this.state.unitsAmount}
                            reinitializeMovement={() => this.reinitializeMovement()}
                        />
                        <Kpi units={units} />
                        <SortableMapContainer
                            height="calc(100vh - 64px)"
                            units={this.pageAndFilterUnits()}
                            updateUnits={updateUnits}
                        />
                        <ExpandableContainer setCurrentMarker={setCurrentMarker} />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withStyles(styles)(LandingPageView)
