import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AlertTable from './AlertTable'
import AlertHistoryTable from './AlertHistoryTable'

function TabPanel(props) {
    const { children, value, index, ...other } = props
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    }
}

const NoAlerts = (props) => {
    const { open, handleClose, scrollType, unit, classes } = props
    const [value, setValue] = React.useState(0)
  
    const handleChange = (event, newValue) => {
        setValue(newValue)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            scroll={scrollType}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{"Alerts"}</DialogTitle>
            <DialogContent>
            <div className={classes.root}>
                <AppBar position="static" className={classes.appBar}>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Active alerts" {...a11yProps(0)} />
                    <Tab label="Historic alerts" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <AlertTable unit={unit} classes={classes} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AlertHistoryTable unit={unit} classes={classes} />
                </TabPanel>

            </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NoAlerts;