export const styles = theme => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
        margin: "0 auto",
        width: "100%",
        height: "36px",
        borderRadius: "2px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)",
        '&:hover': {
            backgroundColor: theme.palette.darkBlue.main
        }
    },
    iconSolved: {
        margin: "0px 8px",
        color: theme.palette.grey.main
    },
    smallText: {
        paddingRight: "16px",
        fontSize: "0.9em",
        textAlign: "right",
        alignItems: "left",
        fontWeight: "100",
        color: theme.palette.primary.main,
        cursor: "pointer",
        '& :hover': {
            color: theme.palette.lightBlue.main
        }
    },
    table: {
        paddingTop: "8px",
        '& th': {
            fontSize: "0.775rem",
            padding: "10px 20px 10px 12px"
        },
        '& td': {
            fontSize: "0.775rem",
            padding: "10px 20px 10px 12px"
        },
    },
    alertsTable: {
        paddingTop: "8px",
        '& th': {
            fontWeight: "700",
            fontSize: "0.775rem",
            padding: "10px 20px 10px 12px"
        },
        '& td': {
            fontSize: "0.775rem",
            padding: "10px 20px 10px 12px"
        },
    },
});