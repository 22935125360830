import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core/';
import SettingTabs from '../components/settingTabs/settingTabs';

class SettingsView extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (             
            <Container maxWidth={false} style={{ margin: "24px 0px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SettingTabs />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}
 
export default SettingsView;