import React from 'react';
import { connect } from "react-redux";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';

import NavigationSmall from "./components/navigation/navigationSmallContainer";
import CaseLauncher from "./caseLauncher/caseLauncherView";
import LoginContainer from './components/login/loginContainer';
import Interact from './interact/interact'
import { useStyles } from './appTheme';
import './app.css';

import { Route, Redirect } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router'
import { history } from '../configureStore'



const mapStateToProps = (state) => {
    return {
        case1: state,
    };
};

function AppView(props) {
    const classes = useStyles();
    const auth = props.case1.loginReducer.auth;
    const theme = props.case1.themeReducer.theme;
    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
    }, []);
    return (
        <div className={classes.root}>
            <MuiThemeProvider theme={theme}>
                <ConnectedRouter history={history}>
                    {auth
                        ?
                        <div>
                            <Route exact path="/" component={CaseLauncher} />
                            <Route path="/case1" component={NavigationSmall} />
                            <Route path="/case2" component={NavigationSmall} />
                            <Route path="/interact" component={Interact} />
                        </div>
                        :
                        <div>
                            <Redirect to="/" />
                            <Route exact path="/" component={LoginContainer} />
                        </div>
                    }
                </ConnectedRouter>
            </MuiThemeProvider>
        </div>
    );
}

export default connect(mapStateToProps)(AppView);
