import { EXPAND_MARKER_INFO, TOGGLE_MARKER_INFO } from "../commonActions/commonActionTypes";

const initialState = {
    expanded: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case EXPAND_MARKER_INFO:
            return {
                expanded: true
            }
        case TOGGLE_MARKER_INFO:
            return {
                expanded: !state.expanded
            }
        default:
            return state;
    }
} 