import React from 'react';
import { withStyles, Grid } from '@material-ui/core/';
import classNames from 'classnames';

import CardFooter from '../commons/cardFooter';
import Card from '../card';
import { styles } from './singleUnitActionsStyle';
import PowerAction from './powerAction';
import LockAction from './lockAction';
import SyncAction from './syncAction';
import SensorAction from './sensorAction';

class SingleUnitActions extends React.Component {
    state = {}

    render() {
        const { classes, handleVehicleLock, unit } = this.props;

        return (
            <div className={classes.root}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    className={classes.grid}
                >
                    <PowerAction />
                    <LockAction handleVehicleLock={handleVehicleLock} unit={unit} />
                    <SyncAction />
                    <SensorAction />
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(SingleUnitActions);