import * as React from "react";
import { Map, TileLayer, Marker, Polygon } from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { withStyles } from '@material-ui/core/styles';

import { thailand } from './geoFences/thailand';
import { styles } from './overviewMapStyle'


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

let newIcon = L.Icon.extend({
    options: {
        iconSize: [42, 42],
        iconAnchor: [21, 42],
        popupAnchor: [-3, -76],
        shadowSize: null,
        shadowAnchor: null
    }
});

let blueIcon = new newIcon({ iconUrl: require('../../../assets/images/icons/markerBlue.png') }),
    redIcon = new newIcon({ iconUrl: require('../../../assets/images/icons/markerRed.png') });

class OverviewMap extends React.Component {
    state = {
        center: this.props.activeInFleetViewPos ? this.props.activeInFleetViewPos : [13.758039, 100.516913],
        zoom: 8,
        maxZoom: 18,
        minZoom: 2,
        scrollWheelZoom: false,
        scrollOverLay: false
    }

    checkGeoFence(point, thailandFence, addAlert) {
        let x = point[0], y = point[1];

        let outOffBounds = true;
        for (let i = 0, j = thailandFence.length - 1; i < thailandFence.length; j = i++) {
            let xi = thailandFence[i][0], yi = thailandFence[i][1];
            let xj = thailandFence[j][0], yj = thailandFence[j][1];

            let intersect = ((yi > y) !== (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) outOffBounds = !outOffBounds;
        }

        return outOffBounds;
    };

    handleScroll() {
        this.setState({
            scrollWheelZoom: !this.state.scrollWheelZoom
        })
    }

    addScrollOverlay() {
        this.setState({
            scrollOverLay: true
        })
    }

    removeScrollOverlay() {
        this.setState({
            scrollOverLay: false
        })
    }

    render() {
        const { classes, height } = this.props;
        const { maxZoom, minZoom, zoom, center } = this.state
        const markers = this.props.units.map((marker, index) => {
            return (
                <Marker
                    position={marker.position}
                    key={marker.registrationNumber}
                    icon={this.checkGeoFence(marker.position, thailand) ? (redIcon) : (blueIcon)}
                >
                </Marker>
            )
        }
        );

        return (
            <div className={classes.mapHolder}>
                <Map
                    maxBounds={L.latLngBounds([-90, -180], [90, 180])}
                    center={center}
                    maxZoom={maxZoom}
                    minZoom={minZoom}
                    zoom={zoom}
                    className={this.state.scrollOverLay && !this.state.scrollWheelZoom ? "scrollOverlay" : "singleMapPadded"}
                    style={{ height: height }}
                    scrollWheelZoom={this.state.scrollWheelZoom}
                    onClick={() => this.handleScroll()}
                    onMouseOver={() => this.addScrollOverlay()}
                    onMouseOut={() => this.removeScrollOverlay()}
                >
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        noWrap={true}
                    />
                    <MarkerClusterGroup>
                        {markers}
                    </MarkerClusterGroup>
                    <Polygon positions={thailand} color="grey" />
                </Map>
            </div>
        );
    }
}

export default withStyles(styles)(OverviewMap);