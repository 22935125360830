import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import { styles } from './cardHeader1Style'

const CardHeader1 = (props) => {
    const { classes } = props;
    return (
        <div className={classes.cardHeader}>
            <Typography style={{ fontWeight: "700" }}>
                {props.children}
            </Typography>
        </div>
    )
};

export default withStyles(styles)(CardHeader1);