export const styles = theme => ({
    card: {
        marginBottom: '22px',
    },
    avatar: {
        backgroundColor: theme.palette.body.main,
        color: theme.palette.primary.main,
    },
    cardHeader: {
        backgroundColor: theme.palette.card.main,
        color: theme.palette.text.main,
        minHeight: "100px",
        '& .MuiCardHeader-title': {
            fontWeight: "600"
        }
    },
    text: {
        color: theme.palette.text.main
    },
    grid: {
        paddingRight: "8px !important"
    }
});
