import React, { Component } from 'react';
import { Button, Icon } from '@material-ui/core/';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import { styles } from './logoutViewStyle'

class LogoutView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: this.props.theme,
        }
    }

    handleClick() {
        localStorage.clear();
        this.props.logout();
    }

    render() {
        const { classes } = this.props;
        this.handleClick = this.handleClick.bind(this);
        return (
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.button}
                    onClick={this.handleClick}
                >
                    LOG OUT <Icon className={classNames(classes.icon, "fa fa-sign-out-alt")} />
                </Button>
            </div>
        );
    }
}

export default withStyles(styles)(LogoutView);