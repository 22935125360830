import React, { forwardRef } from 'react';
import { connect } from "react-redux";
import GeoFenceTab from './geoFenceTab';
import { switchPolygon } from '../../commonActions/commonActions';

const mapStateToProps = state => {
    return {
        mapConfig: state.mapReducer.mapConfig
    };
};

const mapDispatchToProps = dispatch => {
    return {
        switchPolygon: mapConfig => dispatch(switchPolygon({mapConfig})),
    };
}

const ConnectedGeoFenceTabContainer = connect(mapStateToProps, mapDispatchToProps)(GeoFenceTab)

export default forwardRef((props, mapRef) =>
  <ConnectedGeoFenceTabContainer {...props} mapRef={mapRef} />
);