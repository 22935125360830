import { SWITCH_THEME } from "../commonActions/commonActionTypes";
import { lightTheme, redTheme, LightGreenTheme } from '../appTheme';

const ThemeBrands = { lightTheme: lightTheme, redTheme: redTheme, LightGreenTheme: LightGreenTheme }

const initialState = {
    theme: lightTheme,
    themeName: 'lightTheme'
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SWITCH_THEME:
            return Object.assign({}, state, {
                theme: ThemeBrands[action.payload],
                themeName: action.payload
            });

        default:
            return state
    }
}