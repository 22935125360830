import React from 'react';
import { Table, TableHead, TableBody, TableCell, TableRow, Icon } from '@material-ui/core/';

import classNames from 'classnames';


class AlertTable extends React.Component {
    state = {  }
    render() { 
        const { unit, classes } = this.props
        return ( 
            <Table className={classes.alertsTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>Alert level</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {unit[0].alerts.map((alert, index) => {
                        if (alert.level === 3) {
                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        <Icon className={classNames(classes.icon, classes.iconCritical, "fa fa-exclamation-circle")} />
                                    </TableCell>
                                    <TableCell>
                                        {alert.message}
                                    </TableCell>
                                    <TableCell>
                                        {alert.time}
                                    </TableCell>
                                </TableRow>
                            )
                        } else if (alert.level === 2) {
                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        <Icon className={classNames(classes.icon, classes.iconMajor, "fa fa-exclamation-circle")} />
                                    </TableCell>
                                    <TableCell>
                                        {alert.message}
                                    </TableCell>
                                    <TableCell>
                                        {alert.time}
                                    </TableCell>
                                </TableRow>
                            )
                        } else {
                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        <Icon className={classNames(classes.icon, classes.iconMinor, "fa fa-exclamation-circle")} />
                                    </TableCell>
                                    <TableCell>
                                        {alert.message}
                                    </TableCell>
                                    <TableCell>
                                        {alert.time}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    })}
                </TableBody>
            </Table>
        );
    }
}
 

        
        
export default AlertTable;