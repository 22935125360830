export const styles = theme => ({
    paper: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingBottom: '11px'
    },
    smallBox: {
        minHeight: "150px",
        backgroundColor: theme.palette.white.main,
        padding: theme.spacing(0.5),
        color: theme.palette.text.main,
        display: "flex",
        alignItems: "center",
    },
    serviceBox: {
        padding: "52px 16px"
    },
    card: {
        marginTop: "24px",
        width: "100%",
        boxShadow: "none",
    },
    icon: {
        fontSize: "1.5em",
    },
    title: {
        fontWeight: "700",
        color: theme.palette.black.main,
        borderBottom: "3px solid",
        borderBottomColor: theme.palette.primary.main,
        marginBottom: "24px"
    },
    smallText: {
        textAlign: "center",
        fontSize: "0.9rem",
        color: "rgba(0, 0, 0, 0.54)",
        fontWeight: "100",
    },
    text: {
        textAlign: "center",
        padding: "5.5px 0px",
        color: theme.palette.white.main
    },
    content: {
        padding: "0px 0px 4px 0px !important",
    },
    leftFat: {
        margin: "0",
        padding: "0px 16px",
        textAlign: "left",
        fontWeight: "700"
    },
    rightFat: {
        float: "right",
        fontWeight: "700",
    },
    unitInfoContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    clientLink: {
        cursor: 'pointer'
    },
    exclamation: {
        marginLeft: '8px',
        fontSize: '1.2rem',
        marginTop: '-2px'
    },
    alertButton: {
        marginBottom: '8px',
        marginTop: '24px',
        color: theme.palette.darkGrey.main,
        '&:hover': {
            color: theme.palette.warning.main
        }
    },
    cardHeader: {
        padding: "16px",
        background: theme.palette.primary.main,
        color: "white",
        textAlign: "left",
        borderRadius: "5px",
        margin: "0 16px",
        marginTop: "-24px",
        boxShadow: "1px 2px 3px -1px rgba(0,0,0,.7)"
    },
    barchart: {
        width: '94.5%',
        margin: '16px',
        marginTop: '32px'
    },
    cardHeaderClass: {
        padding: "16px",
        background: theme.palette.primary.main,
        color: "white",
        textAlign: "left",
        borderRadius: "5px",
        margin: "0 16px",
        marginTop: "-24px",
        boxShadow: "1px 2px 3px -1px rgba(0,0,0,.7)"
    },
    cardContainerClass: {
        paddingBottom: '0px'
    }
}); 