import * as React from "react"
import { Map, TileLayer, Marker, Popup, Polygon } from "react-leaflet"
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { withStyles } from '@material-ui/core/styles'

import { thailand } from './geoFences/thailand'
import { styles } from './overviewMapStyle'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const newIcon = L.Icon.extend({
    options: {
        iconSize: [42, 42],
        iconAnchor: [21, 42],
        popupAnchor: [-3, -76],
        shadowSize: null,
        shadowAnchor: null,
    }
})

const newGreyIcon = L.Icon.extend({
    options: {
        iconSize: [42, 42],
        iconAnchor: [21, 42],
        popupAnchor: [-3, -76],
        shadowSize: null,
        shadowAnchor: null,
        className: 'markerIcon',
    }
})

class OverviewMap extends React.Component {
    state = {
        center: this.props.activeInFleetViewPos ? this.props.activeInFleetViewPos : [13.758039, 100.516913],
        zoom: 8,
        maxZoom: 18,
        minZoom: 2,
        scrollWheelZoom: false,
        scrollOverLay: false
    }

    checkGeoFence(point, thailandFence, addAlert) {
        let x = point[0], y = point[1];

        let outOffBounds = true;
        for (let i = 0, j = thailandFence.length - 1; i < thailandFence.length; j = i++) {
            let xi = thailandFence[i][0], yi = thailandFence[i][1];
            let xj = thailandFence[j][0], yj = thailandFence[j][1];

            let intersect = ((yi > y) !== (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) outOffBounds = !outOffBounds;
        }

        return outOffBounds;
    };

    componentDidUpdate() {
        const { activeInFleetViewPos } = this.props
        if (activeInFleetViewPos && this.state.center !== activeInFleetViewPos) {
            this.setState({ ...this.state, center: activeInFleetViewPos, zoom: 9 })
        }
    }

    setMarkerIcon(marker) {
        let icon

        if (marker.locked) {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedLocked.png') })
        } else if (marker.outOffBounds) {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedRed.png') })
        } else if (marker.batteryLevel < 11) {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/battery.png') })
        } else if (marker.alerts.length > 0) {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/warning.png') })
        } else if (!marker.active) {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedGrey.png') })
        } else {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedBlue.png') })
        }

        if (this.props.activeInFleetViewReg) {
            if (this.props.activeInFleetViewReg !== marker.registrationNumber) {
                if (marker.locked) {
                    icon = new newGreyIcon({ iconUrl: require('../../../assets/images/icons/mopedLocked.png') })
                } else if (marker.outOffBounds) {
                    icon = new newGreyIcon({ iconUrl: require('../../../assets/images/icons/mopedRed.png') })
                } else if (marker.batteryLevel < 11) {
                    icon = new newGreyIcon({ iconUrl: require('../../../assets/images/icons/battery.png') })
                } else if (marker.alerts.length > 0) {
                    icon = new newGreyIcon({ iconUrl: require('../../../assets/images/icons/warning.png') })
                } else if (marker.active) {
                    icon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedGrey.png') })
                } else {
                    icon = new newGreyIcon({ iconUrl: require('../../../assets/images/icons/mopedBlue.png') });
                }
            } else {
                this.markerPopupPos = marker.position
            }
        }
        return icon
    }

    handleScroll() {
        this.setState({
            scrollWheelZoom: !this.state.scrollWheelZoom
        })
    }

    addScrollOverlay() {
        this.setState({
            scrollOverLay: true
        })
    }

    removeScrollOverlay() {
        this.setState({
            scrollOverLay: false
        })
    }

    placeMarkerPopUp() {
        const { activeInFleetViewReg, classes } = this.props

        if (this.markerPopupPos) {
            return (
                <Popup
                    className={classes.markerPopup}
                    position={this.markerPopupPos}
                    onClose={() => {
                        this.markerPopupPos = undefined
                        this.props.setActiveInTable('', undefined)
                    }}
                >
                    <br />
                    Registration Number: {activeInFleetViewReg}
                    <br />
                    Position: {this.markerPopupPos[0]}, {this.markerPopupPos[1]}
                </Popup>
            )
        }
    }

    renderMarkers() {
        const { setActiveInTable, activeInFleetViewReg, units } = this.props

        return units.map((marker, index) => {
            return (
                <Marker
                    position={marker.position}
                    key={marker.registrationNumber}
                    zIndexOffset={activeInFleetViewReg === marker.registrationNumber ? 9999 : 1}
                    onClick={() => {
                        if (activeInFleetViewReg === marker.registrationNumber) {
                            setActiveInTable('', undefined)
                        } else {
                            setActiveInTable(marker.registrationNumber, marker.position)
                        }
                    }}

                    icon={this.setMarkerIcon(marker)}
                />
            )
        })
    }

    render() {
        const { classes, height, setActiveInTable, activeInFleetViewReg } = this.props;
        const { zoom, maxZoom, minZoom, center } = this.state
        const markers = this.renderMarkers()

        return (
            <div className={classes.mapHolder}>
                <Map 
                    maxBounds= {L.latLngBounds([-90, -180], [90, 180])}
                    center={center} 
                    maxZoom={maxZoom} 
                    minZoom={minZoom} 
                    zoom={zoom} 
                    className={this.state.scrollOverLay && !this.state.scrollWheelZoom ? "scrollOverlay" : "singleMap"} 
                    style={{ height: height }}
                    maxZoom="18"
                    minZoom="2"
                    scrollWheelZoom={this.state.scrollWheelZoom}
                    onClick={() => this.handleScroll()}
                    onMouseOver={() => this.addScrollOverlay()}
                    onMouseOut={() => this.removeScrollOverlay()}
                    >
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        noWrap={true}
                    />
                    {markers}
                    {this.placeMarkerPopUp()}
                    <Polygon positions={thailand} color="grey" />
                </Map>
            </div>
        );
    }
}

export default withStyles(styles)(OverviewMap)