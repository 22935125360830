import { SWITCH_POLYGON } from "../commonActions/commonActionTypes";

const initialState = {
    mapConfig: { zoneType: "polygon", location: "bangkok", radius: 30000, latLon: [13.864413726164663, 100.61553955078126] }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SWITCH_POLYGON:
            return {
                ...action.payload
            };
        default:
            return state;
    }
} 