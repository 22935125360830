import { SWITCH_THEME, LOGIN, LOGOUT, SET_CURRENT_MARKER, EXPAND_MARKER_INFO, TOGGLE_MARKER_INFO, SWITCH_CASE_1, SWITCH_CASE_3, SWITCH_CASE_2, SWITCH_POLYGON } from "./commonActionTypes";

export function switchTheme(payload) {
    return {
        type: SWITCH_THEME,
        payload
    }
};

export function login(payload) {
    return {
        type: LOGIN,
        payload
    }
}

export function logout(payload) {
    return {
        type: LOGOUT,
        payload
    }
}

export function setCurrentMarker(payload) {
    return {
        type: SET_CURRENT_MARKER,
        payload
    }
}

export function expandMarkerInfo(payload) {
    return {
        type: EXPAND_MARKER_INFO,
        payload
    }
}

export function toggleMarkerInfo(payload) {
    return {
        type: TOGGLE_MARKER_INFO,
        payload
    }
}

export function switchCase1(payload) {
    return {
        type: SWITCH_CASE_1,
        payload
    }
}

export function switchCase2(payload) {
    return {
        type: SWITCH_CASE_2,
        payload
    }
}

export function switchCase3(payload) {
    return {
        type: SWITCH_CASE_3,
        payload
    }
}

export function switchPolygon(payload) {
    return {
        type: SWITCH_POLYGON,
        payload
    }
}
