import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { styles } from './cardFooterStyle'

const CardFooter = (props) => {
    const { classes } = props;
    return (
        <>
            <Divider
                variant="middle"
            />
            <CardContent
                className={classes.cardFooter}
            >
                <span className={classes.smallText}>{props.children}</span>
            </CardContent>
        </>
    )
};

export default withStyles(styles)(CardFooter);