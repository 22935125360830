import React from 'react'
import { Drawer, Icon } from '@material-ui/core/'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'

import LogoutContainer from '../login/logoutContainer'
import ThemeSwitch from '../themeSwitch/themeSwitchContainer'
import CaseSwitcher from '../caseSwitcher/caseSwitcher'
import RoleSwitcher from '../roleSwitcher/roleSwitcher'
import SideList from '../sideList/sideList'
import { styles } from './drawerRightStyle'
import './navigation.css'

export default function DrawerRight(props) {

    const classes = styles();
    const [state, setState] = React.useState({
        right: false,
        checkedA: true,
        checkedB: false,
        role: 'default'
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    return (
        <div className="navBarRight">

            <IconButton className={classes.settingsButton} aria-label="4 pending messages" onClick={toggleDrawer('right', true)}>
                <Icon className={classNames(classes.icon, "fas fa-sliders-h")} />
            </IconButton>
            <Drawer color="secondary" anchor="right" open={state.right} onClose={toggleDrawer('right', false)} classes={{ paper: classes.drawerPaper }}
            >
                <SideList toggleDrawer={toggleDrawer} side="right" />
                <RoleSwitcher userRole={props.userRole} changeUserRole={props.changeUserRole} />
                <ThemeSwitch themeName={props.themeName} />
                <br />
                <CaseSwitcher />
                <LogoutContainer />
            </Drawer>
        </div>
    );
}