import { connect } from "react-redux";
import ExpandableDrawerView from './expandableDrawerView';
import { expandMarkerInfo, toggleMarkerInfo } from '../../commonActions/commonActions';
import SortableMapView from "../maps/sortableMap/sortableMapView";

const mapStateToProps = state => {
    return {
        marker: state.currentMarker.currentSelectedMarker,
        expanded: state.expandMarkerInfoReducer.expanded,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        expandMarkerInfo: expanded => dispatch(expandMarkerInfo(expanded)),
        toggleMarkerInfo: expanded => dispatch(toggleMarkerInfo(expanded)),
    };
}

const ExpandableContainer = connect(mapStateToProps, mapDispatchToProps)(ExpandableDrawerView, SortableMapView);

export default ExpandableContainer;