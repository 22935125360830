import React from 'react';
import { Container, Grid, Paper, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/images/marker-shadow.png';
import SingleMap from '../components/maps/singleMap'
import DataListsSingle from '../components/list/dataListsSingle';
import SingleUnitHeader from '../components/singleUnitHeader/singleUnitHeader';
import SingleUnitActions from '../components/singleUnitActions/singleUnitActions';
import LineChartComponent from '../components/charts/lineChartComponent';
import AutoComplete from '../components/autoComplete/AutoComplete';
import { styles } from './singleUnitStyle.js';
import NoVehicle from '../components/noVehicle/NoVehicle';

class SingleUnitView extends React.Component {
    componentDidMount() {
        if (this.props.selectedUnit && this.props.hash.length === 0) {
            this.props.changeRoute(this.props.selectedUnit)
        }
        if (this.props.hash.length !== 0) {
            this.props.saveSelectedUnit(this.props.hash.substr(1))
        }
    }

    componentDidUpdate() {
        if (this.props.hash.length !== 0 && this.props.selectedUnit !== this.props.hash.substr(1)) {
            this.props.saveSelectedUnit(this.props.hash.substr(1))
        }
        if (this.props.selectedUnit && this.props.hash.length === 0) {
            this.props.changeRoute(this.props.selectedUnit)
        }
    }

    render() {
        const { markAlertAsSeen, hash, units, addGeoAlert, addBatteryAlert, addHistory, classes, updateBatteryLevel, handleVehicleLock, userRole, theme } = this.props;

        return (
            hash && units.filter((unit) => unit.registrationNumber === hash.substr(1)).length ?
                (
                    <Container maxWidth={false} className={classes.container}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <AutoComplete
                                    unit={units}
                                    changeRoute={this.props.changeRoute}
                                    clearOnEscape={true}
                                />
                                <SingleUnitHeader
                                    addBatteryAlert={addBatteryAlert}
                                    unit={units.filter((unit) => unit.registrationNumber === hash.substr(1))}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper>
                                    <LineChartComponent
                                        colors
                                        showGrid={true}
                                        fillColors={[theme.palette.white.main, theme.palette.grey.main]}
                                        colors={[theme.palette.white.main, theme.palette.grey.main]}
                                        strokeColors={[theme.palette.grey.main, theme.palette.white.main]}
                                        markerColors={[theme.palette.white.main, theme.palette.grey.main]}
                                        dataLabels={false}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper>
                                    <SingleMap
                                        height={290}
                                        unit={units.filter((unit) => unit.registrationNumber === hash.substr(1))}
                                        addGeoAlert={addGeoAlert}
                                        addHistory={addHistory}
                                        updateBatteryLevel={updateBatteryLevel}
                                        markAlertAsSeen={markAlertAsSeen}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <Hidden xlDown={userRole === "default" ? true : false}>
                            <Grid item xs={12} >
                                <SingleUnitActions
                                    addGeoAlert={addGeoAlert}
                                    unit={units.filter((unit) => unit.registrationNumber === hash.substr(1))}
                                    handleVehicleLock={handleVehicleLock}
                                    unit={units.filter((unit) => unit.registrationNumber === hash.substr(1))}
                                />
                            </Grid>
                        </Hidden>
                        <DataListsSingle unit={units.filter((unit) => unit.registrationNumber === hash.substr(1))} />
                    </Container>
                )
                :
                (
                    <NoVehicle classes={classes} units={units} changeRoute={this.props.changeRoute} />
                )
        );
    }
}

export default withStyles(styles)(SingleUnitView);