import React from 'react';
import { withStyles, Paper, Grid, CardContent, Avatar, Typography, Icon, Button } from '@material-ui/core/';
import classNames from 'classnames';

import CardFooter from '../commons/cardFooter';
import Card from '../card';
import { styles } from './singleUnitActionsStyle';

class PowerAction extends React.Component {
    state = { 
        power: false
    }

    handleClickPower = () => {
        this.setState({
            power: !this.state.power
        })
    }

    render() { 
        const { classes } = this.props
        const { power } = this.state
        return ( 
            <Grid item xs={12} md={6} lg={2}>
                <Paper className={classes.paper}>
                    <Card>
                        <CardContent>
                            <Avatar className={power ? classes.avatarGreen : classes.avatarRed}>
                                <Icon className={classNames(classes.icon, "fa fa-power-off")} />
                            </Avatar>
                            <Typography
                                align="center"
                                className={classes.title}
                            >
                                Engine power
                            </Typography>
                            <Typography
                                align="center"
                                className={power ? classes.textGreen : classes.textRed}
                            >
                                {power ? "On" : "Off"}
                            </Typography>
                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={this.handleClickPower}
                            >
                                {power ? "TURN OFF" : "TURN ON"}
                            </Button>
                        </CardContent>
                        <CardFooter />
                    </Card>
                </Paper>
            </Grid>
        );
    }
}
 
export default withStyles(styles)(PowerAction);