import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './themeSwitchStyle';
import { Typography } from '@material-ui/core';


const themeSwitch = (props) => {
    const { switchTheme, classes } = props
    return (
        <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid className={classes.switchContainer} item xs={12}>
                <Typography className={classes.typography}>
                    <b>Change theme</b>
                </Typography>
                <ButtonGroup fullWidth color="secondary" aria-label="small outlined button group">
                    <Button
                        variant={props.themeName === 'lightTheme' ? 'contained' : 'outlined'}
                        className={classes.button}
                        onClick={() => switchTheme("lightTheme")}
                    >
                        Theme1
                    </Button>
                    <Button
                        variant={props.themeName === 'redTheme' ? 'contained' : 'outlined'}
                        className={classes.button}
                        onClick={() => switchTheme("redTheme")}
                    >
                        Theme2
                    </Button>
                    <Button
                        variant={props.themeName === 'LightGreenTheme' ? 'contained' : 'outlined'}
                        className={classes.button}
                        onClick={() => switchTheme("LightGreenTheme")}
                    >
                        Theme3
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(themeSwitch);

