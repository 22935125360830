export const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingTop: "24px",
        textAlign: "center"
    },
    paper: {
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    cardContent: {
        padding: "5px !important"
    },
    cardFooter: {
        padding: "8px 0px 0px 16px !important",
        textAlign: "left",
        fontSize: "0.9em",
        fontWeight: "100",
        borderRadius: "0",
        justifyContent: "space-between",
        alignItems: "left",
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        margin: "0 auto"
    },
    avatarGreen: {
        backgroundColor: "rgba(0, 185, 85, 0.87)",
        margin: "0 auto"
    },
    avatarRed: {
        backgroundColor: theme.palette.error.main,
        margin: "0 auto"
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
        marginTop: "8px",
        height: "36px",
        borderRadius: "2px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)",
        '&:hover': {
            backgroundColor: theme.palette.darkBlue.main
        }
    },
    smallIcon: {
        fontSize: "1rem",
        margin: "0px 4px -3px 0px"
    },
    title: {
        fontSize: "1em",
        fontWeight: "100",
        padding: "8px"
    },
    text: {
        color: theme.palette.black.main,
        fontSize: "1.8em",
    },
    textGreen: {
        color: theme.palette.black.main,
        fontSize: "1.8em",
    },
    textRed: {
        color: theme.palette.black.main,
        fontSize: "1.8em",
    },
    sensorOn: {
        color: "#68ca00",
        fontSize: "10px"
    },
    sensorOff: {
        color: theme.palette.error.main,
        fontSize: "10px"
    },
    table: {
        paddingTop: "8px",
        '& th': {
            fontSize: "0.775rem",
            padding: "4px 20px 5px 12px"
        },
        '& td': {
            fontSize: "0.775rem",
            padding: "7px 20px 7px 12px;"
        },
    },
});