import React from 'react';
import { withStyles, Paper, Grid, CardContent, Avatar, Typography, Icon, Button, CircularProgress } from '@material-ui/core/';
import classNames from 'classnames';

import CardFooter from '../commons/cardFooter';
import Card from '../card';
import { styles } from './singleUnitActionsStyle';

class LockAction extends React.Component {
    state = {
        sync: true,
        newSync: false
    }

    handleClickSync = () => {
        this.setState({
            sync: false
        })
        setTimeout(() => {
            this.setState({
                sync: true,
                newSync: true,
            })
        }, 2000);
    }

    render() { 
        const { classes } = this.props
        const { sync, newSync } = this.state
        return ( 
            <Grid item xs={12} md={6} lg={2}>
                <Paper className={classes.paper}>
                    <Card>
                        <CardContent>
                            <Avatar className={classes.avatar}>
                                <Icon className={classNames(classes.icon, "fa fa-sync-alt")} />
                            </Avatar>
                            <Typography
                                align="center"
                                className={classes.title}
                            >
                                Last Sync
                            </Typography>
                            <div>
                                {(sync
                                    ? 
                                    <Typography align="center" className={classes.text}>{(
                                        newSync ? "1 Minute ago" : "48 Minutes ago")}</Typography> 
                                    : 
                                    <CircularProgress className={classes.progress} size={30}/>
                                )}
                            </div>
                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={this.handleClickSync}
                            >
                                SYNC
                            </Button>
                        </CardContent>
                        <CardFooter />
                    </Card>
                </Paper>
            </Grid>
        );
    }
}
 
export default withStyles(styles)(LockAction);