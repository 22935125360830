import {
    ADD_UNIT, SAVE_LANDING_PAGE_PAGE_INC, SAVE_LANDING_PAGE_FILTER,
    MARK_ALERT_AS_SEEN, UPDATE_UNITS, ADD_GEO_ALERT,
    ADD_BATTERY_ALERT, ADD_HISTORY, SAVE_SELECTED_UNIT, SET_ACTIVE_IN_FLEET_VIEW, UPDATE_BATTERY_LEVEL,
    HANDLE_VEHICLE_LOCK, CHANGE_USER_ROLE
} from "./appActionTypes"

export function addUnit(payload) {
    return { type: ADD_UNIT, payload }
}

export function updateUnits(payload) {
    return { type: UPDATE_UNITS, payload }
}

export function addGeoAlert(payload) {
    return { type: ADD_GEO_ALERT, payload }
}

export function addBatteryAlert(payload) {
    return { type: ADD_BATTERY_ALERT, payload }
}

export function markAlertAsSeen(payload) {
    return { type: MARK_ALERT_AS_SEEN, payload }
}

export function saveLandingPageFilter(payload) {
    return { type: SAVE_LANDING_PAGE_FILTER, payload }
}

export function saveLandingPagePageInc(payload) {
    return { type: SAVE_LANDING_PAGE_PAGE_INC, payload }
}

export function updateBatteryLevel(payload) {
    return { type: UPDATE_BATTERY_LEVEL, payload }
}

export function addHistory(payload) {
    return { type: ADD_HISTORY, payload }
}

export function saveSelectedUnit(payload) {
    return { type: SAVE_SELECTED_UNIT, payload }
}

export function setActiveInFleetView(payload) {
    return { type: SET_ACTIVE_IN_FLEET_VIEW, payload }
}

export function handleVehicleLock(payload) {
    return { type: HANDLE_VEHICLE_LOCK, payload }
}

export function changeUserRole(payload) {
    return { type: CHANGE_USER_ROLE, payload }
}