import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { styles } from './kpiViewStyle';
import CardChartBox1 from '../commons/cardChartBox1';
import Chart from "react-apexcharts";
import classNames from "classnames";
import { Typography, Grid, Paper, CardContent, Avatar, Slide, Fab, Tooltip, Zoom, Icon } from '@material-ui/core';

import CardFooter from '../commons/cardFooter';
import Card from '../card';
/* import './style.css'; */

class kpi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: true,
            options: {
                chart: {
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true | '<img src="/static/icons/reset.png" width="20">',
                            customIcons: []
                        },
                        autoSelected: 'zoom'
                    },
                },
                colors: ["#3E3E3E", "#ffffff"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: ["#3E3E3E", "#ffffff"],
                    width: 3,
                    dashArray: 0,
                },
                title: {
                    text: '',
                    align: 'left'
                },
                markers: {
                    size: 0,
                    opacity: 0.9,
                    colors: ["#3E3E3E", "#ffffff"],
                    strokeWidth: 2,
                },
                grid: {
                    show: false,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    labels: {
                        colors: ["#ffffff"],
                        useSeriesColors: false
                    },
                    markers: {
                        show: true,
                        size: 5,
                        opacity: 0.9,
                        colors: ["#3E3E3E", "#ffffff"],
                        strokeColor: "#3E3E3E",
                        strokeWidth: 2,
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " km"
                        }
                    }
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    show: false,
                    labels: {
                        show: false,
                    }
                },
            },
            series: [
                {
                    name: "2019",
                    data: [700, 1200, 400, 450, 600, 800, 69, 100, 300, 500, 700, 400]
                },
                {
                    name: "2018",
                    data: [100, 1000, 200, 250, 300, 500, 150, 150, 200, 350, 400, 350]
                }
            ],
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange() {
        this.setState({
            checked: !this.state.checked
        })
    }

    getActiveAlerts() {
        let alerts = 0

        this.props.units.forEach((unit) => { alerts += unit.alerts.length })

        return alerts
    }

    render() {
        const { classes } = this.props;
        let activeAlerts = this.getActiveAlerts()

        return (
            <div className={classes.root}>
                <Tooltip placement="left" TransitionComponent={Zoom} title={this.state.checked ? "Hide Kpi´s" : "Show Kpi´s"}>
                    <Fab aria-label="add" className={classes.fadeButton} onClick={this.handleChange}>
                        <Icon className={classNames(classes.icon, "fa fa-info-circle")} />
                    </Fab>
                </Tooltip>
                <Slide direction="down" in={this.state.checked} className={classes.fade}>
                    <Grid
                        container
                        spacing={2}
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-end"
                        className={classes.grid}
                    >
                        <Grid item xs>
                            <Paper className={classes.paper}>
                                <Card>
                                    <CardContent>
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            <Icon className={classNames(classes.icon, "fa fa-motorcycle")} />
                                        </Avatar>
                                        <Typography
                                            align="right"
                                            className={classes.title}
                                        >
                                            Active mopeds
                                        </Typography>
                                        <Typography
                                            align="right"
                                            className={classes.text}
                                        >
                                            {this.props.units.filter((unit) => unit.active).length} / {this.props.units.length}
                                        </Typography>
                                    </CardContent>
                                    <CardFooter>
                                        Updated 5 minutes ago
                                    </CardFooter>
                                </Card>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className={classes.paper}>
                                <Card>
                                    <CardContent>
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            <Icon className={classNames(classes.icon, "fa fa-bell")} />
                                        </Avatar>
                                        <Typography
                                            align="right"
                                            className={classes.title}
                                        >
                                            Active alerts
                                        </Typography>
                                        <Typography
                                            align="right"
                                            className={classes.text}
                                        >
                                            {activeAlerts}
                                        </Typography>
                                    </CardContent>
                                    <CardFooter>
                                        Updated 2 hours ago
                                    </CardFooter>
                                </Card>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper className={classes.paper} style={{ marginTop: "4px" }}>
                                <Card>
                                    <CardChartBox1>
                                        <Chart
                                            options={this.state.options}
                                            series={this.state.series}
                                            type="line"
                                            width="100%"
                                            height="150px"
                                        />
                                    </CardChartBox1>
                                    <p className={classes.leftFat}>Occupancy comparison <span className={classes.rightFat}>+ 6 %</span></p>
                                    <CardFooter>
                                        Updated 7 days ago
                                    </CardFooter>
                                </Card>
                            </Paper>
                        </Grid>
                    </Grid>
                </Slide>
            </div>
        );
    }
}

export default withStyles(styles)(kpi);