import { connect } from "react-redux";
import DashboardView from './dashboardView';

const mapStateToProps = state => {
    return {
        units: state.appReducer.units,
        userRole: state.appReducer.userRole,
        theme: state.themeReducer.theme
    };
};

const LoginContainer = connect(mapStateToProps)(DashboardView);

export default LoginContainer;