import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './CountDownStyle';

class CountDown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
        }
    }

    componentDidMount() {
        // update every second
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(this.props.date);
            date ? this.setState(date) : this.stop();
        }, 1000);
    }

    componentWillUnmount() {
        this.stop();
    }

    calculateCountdown(endDate) {
        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            millisec: 0,
        };

        // calculate time difference between now and expected date
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff = timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff = timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff = timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2 && value > 9) {
            value = '0' + value;
        }
        return value;
    }

    render() {
        const countDown = this.state;
        const { classes, spinner } = this.props;

        if (spinner) {
            return (
                <div className="Countdown">
                    <span className="Countdown-col">
                        <span className="Countdown-col-element">
                            <strong>{countDown.days > 0 ? this.addLeadingZeros(countDown.days) : "0"}</strong>
                            <span className={classes.text}>{this.props.subHeaderText ? countDown.days === 1 ? ' Day left' : ' Days left' : ''}</span>
                        </span>
                    </span>
                </div>
            );
        } else {
            return (
                <div className="Countdown">
                    <span className="Countdown-col">
                        <span className="Countdown-col-element">
                            <strong>{this.addLeadingZeros(countDown.days)}</strong>
                            <span className={countDown.days < 11 ? classes.textExpired : classes.text}>{this.props.subHeaderText ? countDown.days === 1 ? ' Day left' : ' Days left' : ''}</span>
                        </span>
                    </span>
                </div>
            );
        }
    }
}


CountDown.defaultProps = {
    date: new Date()
};

export default withStyles(styles)(CountDown);