import { connect } from "react-redux"
import FleetView from './fleetView'
import { addVisibleMarkers } from './actions'
import { setActiveInFleetView } from '../appActions'

const mapStateToProps = state => {

    return {
        units: state.appReducer.units,
        visibleMarkers: state.fleetReducer.visibleMarkers,
        activeInFleetViewReg: state.appReducer.activeInFleetViewReg,
        activeInFleetViewPos: state.appReducer.activeInFleetViewPos
    }
}


const mapDispatchToProps = dispatch => {
    return {
        addMarkers: units => {
            dispatch(addVisibleMarkers(units))
        },
        setActiveInTable: (reg, pos) => {
            dispatch(setActiveInFleetView({ reg, pos }))
        }
    }
}

const FleetContainer = connect(mapStateToProps, mapDispatchToProps)(FleetView)

export default FleetContainer