export const styles = theme => ({
    root: {
        width: "100%",
    },
    panel: {
        boxShadow: "none",
        position: "absolute",
        bottom: "0",
        margin: "0",
        zIndex: 1000,
        borderBottom: "solid 1px lightgray",
        width: "calc(100% - 153px)",
        '@media(max-width:959.95px) and (min-width:599.95px)': {
            width: "calc(100% - 137px)"
        },
        '@media(max-width:599.95px)': {
            width: "calc(100% - 121px)"
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        alignItems: 'center',
        minHeight: "280px"
    },
    detailsBox: {
        minHeight: "calc(280px - 30px)"
    },
    column: {
        flexBasis: '50%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    paper: {
        margin: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    iconCritical: {
        margin: "0px 8px",
        color: theme.palette.error.main
    },
    iconMajor: {
        margin: "0px 8px",
        color: theme.palette.warning.main
    },
    iconMinor: {
        margin: "0px 8px",
        color: theme.palette.minor.main
    },
    iconClose: {
        right: "0",
        margin: "-7px 12px 0 0",
        position: "absolute",
        color: "grey",
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.black.main
        }
    },
    title: {
        fontWeight: "700",
        color: theme.palette.black.main,
        borderBottom: "3px solid",
        borderBottomColor: theme.palette.primary.main,
        marginBottom: "24px"
    },
    table: {
        paddingTop: "8px",
        '& th': {
            fontSize: "0.775rem",
            padding: "10px 20px 10px 12px"
        },
        '& td': {
            fontSize: "0.775rem",
            padding: "10px 20px 10px 12px"
        },
    },
    text: {
        fontSize: "0.775rem",
        padding: "10px 0px 10px 0px",
        display: "flex",
        alignItems: "center"
    }
});