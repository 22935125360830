import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme => ({
    popover: {
        '& .MuiPopover-paper': {
            marginTop: "18px",
            maxHeight: "calc(100vh - 78px)",
        },
    },
    typography: {
        padding: theme.spacing(2),
    },
    icon: {
        fontSize: "1.25em",
        textAlign: "center",
        color: theme.palette.black.main
    },
    container: {
        padding: theme.spacing(2),
        maxWidth: "600px"
    },
    grid: {
        textAlign: 'center'
    },
    dateCell: {
        minWidth: '175px'
    },
    iconCritical: {
        margin: "0px 8px",
        color: theme.palette.error.main
    },
    iconMajor: {
        margin: "0px 8px",
        color: theme.palette.warning.main
    },
    iconMinor: {
        margin: "0px 8px",
        color: theme.palette.minor.main
    },
}));