export const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    grid: {
        paddingBottom: "8px"
    },
    cardContent: {
        padding: "5px !important"
    },
    cardFooter: {
        padding: "8px 0px 0px 16px !important",
        textAlign: "left",
        fontSize: "0.9em",
        fontWeight: "100",
        borderRadius: "0",
        justifyContent: "space-between",
        alignItems: "left",
    },
    avatar: {
        width: "80px",
        height: "80px",
        boxShadow: "1px 2px 3px -1px rgba(0,0,0,.7)",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "3px",
        padding: "15px",
        marginTop: "-36px",
        marginRight: "15px",
        float: "left",
    },
    avatar2: {
        width: "80px",
        height: "80px",
        boxShadow: "1px 2px 3px -1px rgba(0,0,0,.7)",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "3px",
        padding: "15px",
        marginTop: "-36px",
        marginRight: "15px",
        float: "left",
    },
    icon: {
        fontSize: "1.5em",
    },
    smallIcon: {
        fontSize: "1rem",
        margin: "0px 4px -3px 0px"
    },
    title: {
        fontWeight: "100"
    },
    text: {
        color: theme.palette.black.main,
        fontSize: "28px"
    }
});


