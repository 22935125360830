import React from 'react';
import { Link } from "react-router-dom";
import AutoComplete from '../autoComplete/AutoComplete'

class NoVehicle extends React.Component {
    state = {  }
    render() { 
        const { units, classes } = this.props;
        return ( 
            <div className={classes.noVehicle}>
            <div className={classes.noVehicleDiv}>
                <h3>No vehicle selected</h3>
                <span>Search for a vehicle or select one on the <Link to={`/case1/map`}>Map</Link> or <Link to={`/case1/fleet`}>Fleet view</Link>.</span>
                <div>
                    <AutoComplete unit={units} changeRoute={this.props.changeRoute}/>
                </div>
            </div>
      </div>
         );
    }
}
 
export default NoVehicle;