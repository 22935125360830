export const styles = theme => ({
    root: {
        height: "calc(100vh - 756px)"
    },
    cardHeader2: {
        padding: "16px",
        background: theme.palette.white.main,
        textAlign: "left",
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
    },
    cardFooter: {
        padding: "8px 0px 0px 16px !important",
        textAlign: "left",
        fontSize: "0.9em",
        fontWeight: "100",
        borderRadius: "0",
        justifyContent: "space-between",
        alignItems: "left",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    mapBox: {
        backgroundColor: theme.palette.white.main,
        margin: " 0",
        top: "-692px",
        minHeight: "700px"
    },
    smallText: {
        textAlign: "right",
        display: "block",
        padding: "0px 16px",
    },
    heading: {
        fontSize: "16px",
        fontWeight: "600",
        marginTop: "-9px",
        marginBottom: "16px",
    },
    filter: {
        display: "flex",
        flexDirection: 'column',
        marginBottom: "-90px",
        position: "relative",
        zIndex: "1000",
        padding: "4px 16px 4px 16px",
        top: "-680px",
        left: "25px",
        width: "max-content",
        fontSize: "0.75rem !important"
    },
    filterText: {
        marginRight: "25px",
    },
    filterInner: {
        display: 'flex'
    },
    filterPaging: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    filterFooter: {
        padding: '0px'
    },
    pagingLeft: {
        display: 'flex',
        marginTop: '7px'
    },
    pagingRight: {
        marginTop: '4px'
    },
    pagingText: {
        marginTop: '6px',
        marginRight: '8px'
    },
    pagingButton: {
        height: '35px',
        width: '35px',
        marginLeft: '8px'
    },
    pagingIcon: {
        fontSize: '0.75rem'
    },
    pagingDropdown: {
        fontSize: '0.75rem'
    }
});