import { connect } from "react-redux";
import LoginView from './loginView';
import { login } from '../../commonActions/commonActions';

const mapStateToProps = state => {
    return {
        isLoggedIn: state.loginReducer.auth,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: isLoggedIn => dispatch(login(isLoggedIn)),
    };
}

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(LoginView);

export default LoginContainer;