import { connect } from "react-redux"
import { setCurrentMarker } from '../commonActions/commonActions'
import LandingPageView from './landingPageView'
import { updateUnits, saveLandingPageFilter, saveLandingPagePageInc } from '../appActions'


const mapStateToProps = state => {

    return {
        units: state.appReducer.units,
        initialUnits: state.appReducer.initialUnits,
        savedFilter: state.appReducer.landingPageFilter,
        pageInc: state.appReducer.landingPagePageInc
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentMarker: marker => dispatch(setCurrentMarker(marker)),
        updateUnits: (data) => {
            dispatch(updateUnits(data))
        },
        saveFilter: (data) => {
            dispatch(saveLandingPageFilter(data))
        },
        savePageInc: (pageInc) => {
            dispatch(saveLandingPagePageInc(pageInc))
        }
    }
}

const LandingPageContainer = connect(mapStateToProps, mapDispatchToProps)(LandingPageView)

export default LandingPageContainer