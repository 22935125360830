export const styles = theme => ({
    root: {
        backgroundColor: theme.palette.card.main,
    },
    cardHeader: {
        padding: "16px",
        background: theme.palette.secondary.main,
        color: "white",
        textAlign: "left",
        borderRadius: "5px",
        margin: "0 16px",
        marginTop: "-24px",
        boxShadow: "1px 2px 3px -1px rgba(0,0,0,.7)"
    },
    paper: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    cardFooter: {
        padding: "8px 0px 0px 16px !important",
        textAlign: "left",
        fontSize: "0.9em",
        fontWeight: "100",
        borderRadius: "0",
        justifyContent: "space-between",
        alignItems: "left",
    },
});
