import React from 'react';
import { Typography, Grid, TextField, Table, TableBody, TableCell, TableRow, Checkbox, Button, Divider, Select, OutlinedInput, InputAdornment } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './tabStyles';
import SubmitButton from '../buttons/submitButton';
import PreviewMap from '../maps/previewMap';

class GeoFenceTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            zoneType: props.mapConfig.zoneType,
            geoFenceLocation: props.mapConfig.location,
            geoFence: true,
            lon: props.mapConfig.latLon[1],
            lat: props.mapConfig.latLon[0],
            radius: props.mapConfig.radius,
        }
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    invalidateSize() {
        if(this.refs.map) {
            this.refs.map.invalidateSize(true)
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
    
        this.setState({
          [name]: value
        });
    }

    render() {
        const { classes, mapConfig, mapRef } = this.props
        const { geoFence, zoneType, geoFenceLocation, lat, lon, radius } = this.state
        return ( 
            <>
                <h3 className={classes.title}>Geofence</h3>
                <Typography className={classes.infoText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
                <Divider />
                <PreviewMap
                    mapRef={mapRef}
                    height={"344px"}
                    center={mapConfig.latLon} 
                    radius={mapConfig.radius}
                    zoneType={mapConfig.zoneType}
                />
                <Grid container>
                    <Grid item xs={12}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow >
                                    <TableCell component="th" className={classes.th}>
                                        Geofencing
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            type="checkbox"
                                            name="geoFence"
                                            checked={geoFence}
                                            onChange={this.handleInputChange}
                                            value={geoFence}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow className={geoFence ? '' : classes.hidden}>
                                    <TableCell className={classes.th} component="th">
                                        Zone type
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            native
                                            disabled={geoFence ? false : true}
                                            value={zoneType}
                                            onChange={this.handleInputChange}
                                            className={classes.selectOutlined}
                                            variant="outlined"
                                            input={<OutlinedInput />}
                                            inputProps={{
                                                name: 'zoneType',
                                            }}
                                            >    
                                            <option value={"radius"}>Radius (Circle)</option>
                                            <option value={"polygon"}>Polygon</option>
                                        </Select>
                                    </TableCell>
                                </TableRow>
                                    <TableRow className={geoFence ? '' : classes.hidden}>
                                        <TableCell className={classes.th} component="th">
                                            Geofence location
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                native
                                                disabled={zoneType === 'polygon' ? false : true}
                                                name="geoFenceLocation"
                                                value={geoFenceLocation}
                                                onChange={this.handleInputChange}
                                                className={classes.selectOutlined}
                                                variant="outlined"
                                                input={<OutlinedInput />}
                                                inputProps={{
                                                    name: 'geoFenceLocation',
                                                }}
                                                >
                                                <option value={"bangkok"}>Bangkok</option>
                                                <option value={"paris"}>Paris</option>
                                                <option value={"berlin"}>Berlin</option>
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                <TableRow className={geoFence ? '' : classes.hidden}>
                                    <TableCell component="th">
                                        Radius
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled={zoneType === "radius" ? false : true}
                                            className={classes.textField}
                                            name="radius"
                                            placeholder="Radius"
                                            id="outlined-name"
                                            value={radius}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                            margin="normal"
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Meters</InputAdornment>,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow className={geoFence ? '' : classes.hidden}>
                                    <TableCell component="th">
                                        Latitude
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled={zoneType === "radius" ? false : true}
                                            className={classes.textField}
                                            name="lat"
                                            placeholder="lat"
                                            id="outlined-name"
                                            value={lat}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                            margin="normal"
                                            type="number"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow className={geoFence ? '' : classes.hidden}>
                                    <TableCell component="th">
                                        Longitude
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled={zoneType === "radius" ? false : true}
                                            className={classes.textField}
                                            name="lon"
                                            placeholder="lon"
                                            id="outlined-name"
                                            value={lon}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                            margin="normal"
                                            type="number"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid container>
                    <SubmitButton onClick={this.handleSubmit} />
                </Grid>
            </>
        );
    }
}
 
export default withStyles(styles) (GeoFenceTab);