import React from 'react';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { withStyles, Icon, TextField, Paper, MenuItem, FormControl, InputAdornment } from '@material-ui/core/';
import { styles } from './AutoCompleteStyle';
import { BrowserRouter as Redirect, Link } from "react-router-dom";
import classNames from 'classnames';

class AutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            selectedVehicle: null,
            suggestions: []
        }
    }

    componentDidMount() {
        const { unit } = this.props;  
        const fetchedSuggestions = []
        unit.map((thing) =>
            fetchedSuggestions.push(thing)
        )
        this.setState({
            suggestions: fetchedSuggestions
        })
    }

    renderInput(inputProps) {
        const { InputProps, classes, ref, ...other } = inputProps;
        return (
            <>
                <TextField
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon className={classNames("fa fa-search", classes.icon)} />
                        </InputAdornment>
                    ),
                    inputRef: ref,
                    classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    },
                    ...InputProps,
                    }}
                    {...other}
                />
            </>
        );
    }

    getSuggestions(value, { showEmpty = false } = {}) {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length
        const { suggestions } = this.state
        let count = 0;

        return inputLength === 0 && !showEmpty
            ? []
            : suggestions.filter(suggestion => {
                const keep =
                count < 5 && suggestion.registrationNumber.slice(0, inputLength).toLowerCase() === inputValue;
    
                if (keep) {
                    count += 1;
                }
    
                return keep;
            });
    }

    renderSuggestion(suggestionProps) {
        const { suggestion, index, itemProps, highlightedIndex } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
      
        return (
            <Link 
                to={`/case1/singleunit#${suggestion.registrationNumber}`}
                key={suggestion.registrationNumber}
            >
                <MenuItem
                    {...itemProps}
                    selected={isHighlighted}
                    component="div"
                >
                    {suggestion.registrationNumber}
                </MenuItem>
            </Link>
        );
    }

    render() {
        const { classes } = this.props;  
        return (
            <div className={classes.root}>
                <Downshift id="downshift-options">
                    {({
                        clearSelection,
                        getInputProps,
                        getItemProps,
                        getLabelProps,
                        getMenuProps,
                        highlightedIndex,
                        inputValue,
                        isOpen,
                        openMenu,
                        selectedItem,
                    }) => {
                        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                            onKeyDown: event => {
                                if (event.key === 'Enter') {
                                    if (selectedItem !== null) {
                                        this.props.changeRoute(selectedItem)            
                                    }
                                    clearSelection();
                                }
                            },
                            onChange: event => {
                                if (event.target.value === '') {
                                    clearSelection();
                                }
                            },
                            onFocus: openMenu,
                            placeholder: 'E.g. ABC123',
                        });
            
                        return (
                            <div className={classes.container}>
                                <FormControl className={classes.formControl} error>
                                {this.renderInput({
                                    fullWidth: true,
                                    classes,
                                    label: 'Search',
                                    InputLabelProps: getLabelProps({ shrink: true }),
                                    InputProps: { onBlur, onChange, onFocus },
                                    inputProps,
                                    variant: "outlined",
                                })}
                                </FormControl>
                                <div {...getMenuProps()}>
                                    {isOpen ? (
                                    <Paper 
                                        className={classes.paper} 
                                        square
                                    >
                                        {this.getSuggestions(inputValue, { showEmpty: true }).map((suggestion, index) =>
                                        this.renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion.registrationNumber }),
                                            highlightedIndex,
                                            selectedItem,
                                        }),
                                        )}
                                    </Paper>
                                    ) : null}
                                </div>
                            </div>
                        );
                    }}
                </Downshift>
            </div>
        );
    }
}
 
export default withStyles(styles)(AutoComplete);