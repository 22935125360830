import React, { Component } from 'react';
import { Card, CardHeader, Avatar, Grid, Icon } from '@material-ui/core/';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import { styles } from './cardListStyle'

class CardList extends Component {
    getActiveAlerts() {
        let alerts = 0

        this.props.units.forEach((unit) => { alerts += unit.alerts.length })

        return alerts
    }

    render() {
        const { classes, units } = this.props;
        let active = units.filter((unit) => unit.active)

        const data = [
            {
                title: '248kw/h',
                text: "Average Kw Consumption",
                avatar: <Icon className={classNames(classes.icon, "fa fa-bolt")} />
            },
            {
                title: '130 km',
                text: "Average Distance Traveled",
                avatar: <Icon className={classNames(classes.icon, "fa fa-road")} />
            },
            {
                title: active.length + ' / ' + units.length,
                text: "Active Vehicles",
                avatar: <Icon className={classNames(classes.icon, "fa fa-motorcycle")} />
            },
            {
                title: '78 %',
                text: "Average Connectivity",
                avatar: <Icon className={classNames(classes.icon, "fa fa-wifi")} />
            },
            {
                title: this.getActiveAlerts(),
                text: "Active Alerts",
                avatar: <Icon className={classNames(classes.icon, "fa fa-exclamation-circle")} />
            },
            {
                title: 'Custom data',
                text: "Some Other Data",
                avatar: <Icon className={classNames(classes.icon, "fa fa-asterisk")} />
            },
        ]

        const items = data.map((item, index) =>
            <Grid className={classes.grid} item key={index}>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.cardHeader}
                        avatar={
                            <Avatar aria-label="Recipe" className={classes.avatar}>
                                {item.avatar}
                            </Avatar>
                        }
                        title={item.title}
                        subheader={item.text}
                    />
                </Card>
            </Grid>
        );

        return (
            <div>
                {items}
            </div>
        );
    }
}

export default withStyles(styles)(CardList);