import React from "react";
import MUIDataTable from "mui-datatables";
import { withStyles } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Card from '../card';
import { styles } from './dashboardTableStyle'


class DashboardTable extends React.Component {

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {
                    paddingTop: "50px"
                },
                paper: {
                    boxShadow: "none",
                    padding: "1em 1em 1.2em 1em",
                    width: "100%"
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    '&:nth-child(even)': {
                        '@media(max-width:959.95px)': {
                            width: "40% !important",
                            height: "auto!important",
                            display: "inline-block",
                            fontSize: "14px !important",
                            whiteSpace: "nowrap",
                        }
                    },
                    '&:nth-child(odd)': {
                        '@media(max-width:959.95px)': {
                            width: "60% !important",
                            height: "auto !important",
                            display: "inline-block",
                            fontSize: "14px !important",
                        }
                    },
                }
            },
            MuiTableCell: {
                footer: {
                    padding: "0px",
                    '&:last-child': {
                        padding: "0px !important"
                    }
                }
            }
        }
    })

    render() {
        const { classes, cardContainerClass, cardHeaderClass, headerText } = this.props;

        const columns = ["Vehicle model", "Amount"];

        const data = [
            [
                "ATM50-A1",
                "12",
            ],
            [
                "GigaByke Groove",
                "38",
            ],
            [
                "eDrift UH-ES295",
                "40",
            ],
            [
                "Zbee",
                "17",
            ],
        ];

        const options = {
            responsive: "stacked",
            download: false,
            print: false,
            filter: false,
            viewColumns: false,
            pagination: false,
            search: false,
            sort: false,
            selectableRows: "none",
            elevation: 0
        };

        return (
            <Paper className={`${classes.paper} ${cardContainerClass}`}>
                <Card>
                    <div className={cardHeaderClass ? cardHeaderClass : classes.cardHeader}>
                        <Typography style={{ fontWeight: "700" }}>
                            {headerText ? headerText : 'Vehicle types'}
                        </Typography>
                    </div>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={options}
                            className={classes.root}
                        />
                    </MuiThemeProvider>
                </Card>
            </Paper>

        );
    }
}

export default withStyles(styles)(DashboardTable);