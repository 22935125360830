import { connect } from "react-redux";
import FleetTableView from './fleetTableView';
import { setActiveInFleetView } from '../../appActions';

const mapStateToProps = (state) => {
    return {
        units: state.appReducer.units,
        activeInFleetViewReg: state.appReducer.activeInFleetViewReg,
        activeInFleetViewPos: state.appReducer.activeInFleetViewPos,
        hash: state.router.location.hash
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveOnMap: (reg, pos) => {
            dispatch(setActiveInFleetView({ reg, pos }))
        }
    }
}

const FleetTableContainer = connect(mapStateToProps, mapDispatchToProps)(FleetTableView);

export default FleetTableContainer;