export const ADD_UNIT = "ADD_UNIT"
export const UPDATE_UNITS = "UPDATE_UNITS"
export const ADD_GEO_ALERT = "ADD_GEO_ALERT"
export const UPDATE_BATTERY_LEVEL = "UPDATE_BATTERY_LEVEL"
export const ADD_HISTORY = "ADD_HISTORY"
export const SAVE_SELECTED_UNIT = "SAVE_SELECTED_UNIT"
export const SET_ACTIVE_IN_FLEET_VIEW = "SET_ACTIVE_IN_FLEET_VIEW"
export const HANDLE_VEHICLE_LOCK = "HANDLE_VEHICLE_LOCK"
export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE"
export const SAVE_LANDING_PAGE_FILTER = "SAVE_LANDING_PAGE_FILTER"
export const SAVE_LANDING_PAGE_PAGE_INC = "SAVE_LANDING_PAGE_PAGE_INC"
export const ADD_BATTERY_ALERT = "ADD_BATTERY_ALERT"
export const MARK_ALERT_AS_SEEN = "MARK_ALERT_AS_SEEN"