export const styles = theme => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    filter: {
        display: "flex",
        flexDirection: 'column',
        marginBottom: "-90px",
        position: "sticky",
        zIndex: "1000",
        padding: "4px 16px 4px 16px",
        top: "80px",
        marginLeft: "18px",
        width: "max-content",
        fontSize: "0.75rem !important"
    },
    filterText: {
        marginRight: "25px",
    },
    filterInner: {
        display: 'flex'
    },
    filterPaging: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    filterFooter: {
        padding: '0px'
    },
    pagingLeft: {
        display: 'flex',
        marginTop: '7px'
    },
    pagingRight: {
        marginTop: '4px'
    },
    pagingText: {
        marginTop: '6px',
        marginRight: '8px'
    },
    pagingButton: {
        height: '35px',
        width: '35px',
        marginLeft: '8px'
    },
    pagingIcon: {
        fontSize: '0.75rem'
    },
    pagingDropdown: {
        fontSize: '0.75rem'
    }
});