export const styles = theme => ({
    cardHeader: {
        padding: "16px",
        background: theme.palette.primary.main,
        color: "white",
        textAlign: "left",
        borderRadius: "5px",
        margin: "0 16px",
        marginTop: "-24px",
        boxShadow: "1px 2px 3px -1px rgba(0,0,0,.7)"
    },
});