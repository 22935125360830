import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { styles } from './cardChartBox2Style'

const CardChartBox1 = (props) => {
    const { classes } = props;
    return (
        <div className={classes.chartBox}>
            <div className="chart">
                <div className="row">
                    <div className={classes.chart}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withStyles(styles)(CardChartBox1);