import React from 'react';
import { Typography, Grid, TextField, Table, TableBody, TableCell, TableRow, Checkbox, Button, Divider, InputAdornment } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './tabStyles';
import SubmitButton from '../buttons/submitButton';

class vehicleTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            speedLimit1: false,
            speedLimit2: false,
            speedLimit3: true,
            maxSpeed1: "35",
            maxSpeed2: "25",
            maxSpeed3: "45",
            name: '',
            ApiActive: true,
            smsNotifications: true,
            mailNotifications: true,
        }
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
    
        this.setState({
          [name]: value
        });
    }

    render() {
        const { classes } = this.props
        const { speedLimit1, speedLimit2, speedLimit3, maxSpeed1, maxSpeed2, maxSpeed3 } = this.state
        return ( 
            <>
                <h3 className={classes.title}>Vehicles</h3>
                <Typography className={classes.infoText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
                <Divider />

                <h3 className={classes.title}>Model: ATM50-A1</h3>
                <Typography className={classes.infoText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
                <Divider />

                <Grid container>
                    <Grid item xs={12}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow >
                                    <TableCell component="th" className={classes.th}>
                                        Speed limit
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            type="checkbox"
                                            name="speedLimit1"
                                            checked={speedLimit1}
                                            onChange={this.handleInputChange}
                                            value={speedLimit1}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.th} component="th">
                                        Max speed
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled={speedLimit1 ? false : true}
                                            className={classes.textField}
                                            name="maxSpeed1"
                                            value={maxSpeed1}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Km/h</InputAdornment>,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <h3 className={classes.title}>Model: GigaByke Groove</h3>
                <Typography className={classes.infoText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
                <Divider />

                <Grid container>
                    <Grid item xs={12}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow >
                                    <TableCell component="th" className={classes.th}>
                                        Speed limit
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            type="checkbox"
                                            name="speedLimit2"
                                            checked={speedLimit2}
                                            onChange={this.handleInputChange}
                                            value={speedLimit2}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.th} component="th">
                                        Max speed
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled={speedLimit2 ? false : true}
                                            className={classes.textField}
                                            name="maxSpeed2"
                                            value={maxSpeed2}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Km/h</InputAdornment>,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <h3 className={classes.title}>Model: eDrift UH-ES295</h3>
                <Typography className={classes.infoText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
                <Divider />

                <Grid container>
                    <Grid item xs={12}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow >
                                    <TableCell component="th" className={classes.th}>
                                        Speed limit
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            type="checkbox"
                                            name="speedLimit3"
                                            checked={speedLimit3}
                                            onChange={this.handleInputChange}
                                            value={speedLimit3}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.th} component="th">
                                        Max speed
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled={speedLimit3 ? false : true}
                                            className={classes.textField}
                                            name="maxSpeed3"
                                            value={maxSpeed3}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Km/h</InputAdornment>,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid container>
                    <SubmitButton />
                </Grid>
            </>
        );
    }
}
 
export default withStyles(styles) (vehicleTab);