import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/';
import { styles } from './rawDataModalStyle';
import RawDataTable from './rawDataTable';

const AlertHistory = (props) => {
    const { classes, unit } = props;
    const [open, setOpen] = React.useState(false);
    const scrollType = 'paper'

    const handleClickOpen = scrollType => () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <div className={classes.alertButtonContainer}>
            <Typography className={classes.smallText} onClick={handleClickOpen('paper')}>Raw data</Typography >
            <RawDataTable
                open={open}
                handleClose={handleClose}
                scroll={scrollType}
                classes={classes}
                unit={unit}
            />
        </div>
    );
}

export default withStyles(styles)(AlertHistory);