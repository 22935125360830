import { SET_CURRENT_MARKER } from "../commonActions/commonActionTypes";

const initialState = {
    currentSelectedMarker: undefined,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_MARKER:
            return Object.assign({}, state, {
                currentSelectedMarker: action.payload
            });

        default:
            return state
    }
}