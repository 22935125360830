import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './submitButtonStyle';
import SnackBar from '../snackBar/snackBar'

const SubmitButton = (props) => {
    const { classes } = props
    return ( 
        <div className={classes.buttonContainer}>
            <SnackBar />
        </div>
    );
}
 
export default withStyles(styles)(SubmitButton);