import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import logo from '../../../assets/images/logoLightTheme.svg';
import ipad from '../../../assets/images/ipad.png';

import './login.css'
import { styles } from './loginViewStyle'


class LoginView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: this.props.theme,
            username: '',
            password: '',
            fakeAdmin: 'admin',
            fakeAdminPass: 'admin',
            fakeUserTelenorConnection: 'tncx',
            fakeUserPassTelenorConnection: 'tncx',
            fakeUserTelenorSverige: 'tnse',
            fakeUserPassTelenorSverige: 'tnse',
            showFakeError: false,
            showPassword: false,
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    showError() {
        this.setState({
            showFakeError: true
        })
    }

    handleClick(event) {
        if (this.state.username === this.state.fakeAdmin && this.state.password === this.state.fakeAdminPass) {
            localStorage.setItem('rememberMe', true)
            this.props.login();
        } else if (this.state.username === this.state.fakeUserTelenorConnection && this.state.password === this.state.fakeUserPassTelenorConnection) {
            localStorage.setItem('rememberMe', true)
            this.props.login();
        } else if (this.state.username === this.state.fakeUserTelenorSverige && this.state.password === this.state.fakeUserPassTelenorSverige) {
            localStorage.setItem('rememberMe', true)
            this.props.login();
        } else {
            this.showError();
            event.preventDefault();
        }
    }
    handleKeyPress = (event) => {
        if (event.key == 'Enter') {
            if (this.state.username === this.state.fakeAdmin && this.state.password === this.state.fakeAdminPass) {
                localStorage.setItem('rememberMe', true)
                this.props.login();
            } else if (this.state.username === this.state.fakeUserTelenorConnection && this.state.password === this.state.fakeUserPassTelenorConnection) {
                localStorage.setItem('rememberMe', true)
                this.props.login();
            } else if (this.state.username === this.state.fakeUserTelenorSverige && this.state.password === this.state.fakeUserPassTelenorSverige) {
                localStorage.setItem('rememberMe', true)
                this.props.login();
            } else {
                this.showError();
                event.preventDefault();
            }
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        this.setState({
            showFakeError: false
        });
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    render() {
        const { classes } = this.props;
        return (
            <div id="container">
                <div id="stripes" aria-hidden="true">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <Container maxWidth="lg">
                    <Grid
                        spacing={8}
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                        style={{ height: "80vh", minHeight: "620px" }}
                    >
                        <Grid item xs={12} md={6} style={{ zIndex: "2", marginTop: "24px" }}>
                            <Typography variant="h2" id="h2" className={classes.text}>
                                <span style={{ fontWeight: "700" }}>Demo</span><span style={{ fontWeight: "300" }}>Portalen</span>
                            </Typography>
{/*                             <Typography variant="h6" id="h6" className={classes.text}>
                                Välkommen till Cogitels DemoPortal, en IoT plattform skapad för att <span style={{ color: "rgb(255, 207, 221)", textShadow: "0px 0px 2px black" }}>demonstrera</span> uppkopplad produkt verksamhet.
                            </Typography> */}
                        </Grid>
                        <Grid item xs={12} md={6} style={{ zIndex: "2" }}>
                            <Card className={classes.card} id="loginbox">
                                <CardContent>
                                    <img src={logo}
                                        alt="Logotype"
                                        className={classes.logo}
                                    />
                                    <CardHeader
                                        className={classes.cardHeader}
                                        title="Välkommen!"
                                        subheader="Logga in med användarnamn och lösenord."
                                    />
                                    <FormControl className={classes.input} onKeyDown={this.handleKeyPress}>
                                        <InputLabel htmlFor="username">Username</InputLabel>
                                        <Input
                                            id="username"
                                            name="username"
                                            className={classes.textField}
                                            value={this.state.username}
                                            onChange={this.handleInputChange}
                                            endAdornment={
                                                <InputAdornment position="end" >
                                                    <AccountCircle className={classes.adornment} />
                                                </InputAdornment>
                                            }
                                        />
                                        {/* Fake login validation */}
                                        {
                                            this.state.showFakeError === true
                                                ?
                                                <FormHelperText id="component-error-text" className={classes.error}>Invalid username</FormHelperText>
                                                :
                                                null
                                        }
                                        {/* Fake login validation */}
                                    </FormControl>

                                    <FormControl className={classes.input} onKeyDown={this.handleKeyPress}>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <Input
                                            id="password"
                                            name="password"
                                            className={classes.textField}
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                            endAdornment={
                                                <InputAdornment position="end" >
                                                    <IconButton
                                                        className={classes.icon}
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword}
                                                        onMouseDown={this.handleMouseDownPassword}
                                                    >
                                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {/* Fake login validation */}
                                        {
                                            this.state.showFakeError === true
                                                ?
                                                <FormHelperText id="component-error-text" className={classes.error}>Invalid password</FormHelperText>
                                                :
                                                null
                                        }
                                        {/* Fake login validation */}
                                    </FormControl>

                                </CardContent>
                                <CardActions className={classes.padding}>
                                    <Button
                                        className={classes.button}
                                        onClick={this.handleClick}
                                    >
                                        LOG IN
                                    </Button>
                                </CardActions>
                                <div className={classes.padding} style={{ textAlign: "center" }}>
                                    <span style={{ fontSize: "12px" }}>Forgot password..</span>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                    <div id="ipad">
                        <img alt="ipad" src={ipad}></img>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withStyles(styles)(LoginView);