import React from 'react';
import { withStyles, Paper, Grid, CardContent, Avatar, Typography, Icon, Button } from '@material-ui/core/';
import classNames from 'classnames';

import CardFooter from '../commons/cardFooter';
import Card from '../card';
import { styles } from './singleUnitActionsStyle';

class LockAction extends React.Component {
    state = {
        isLocked: this.props.unit[0].locked
    }

    handleClickLock = () => {
        this.props.handleVehicleLock(this.props.unit[0].registrationNumber);
        this.setState({
            isLocked: !this.state.isLocked
        })
    }

    render() {
        const { classes } = this.props
        const { isLocked } = this.state
        return (
            <Grid item xs={12} md={6} lg={2}>
                <Paper className={classes.paper}>
                    <Card>
                        <CardContent>
                            <Avatar className={isLocked ? classes.avatarRed : classes.avatarGreen}>
                                <Icon className={isLocked ? classNames(classes.icon, "fa fa-lock") : classNames(classes.icon, "fa fa-unlock")} />
                            </Avatar>
                            <Typography
                                align="center"
                                className={classes.title}
                            >
                                Safety lock
                            </Typography>
                            <Typography
                                align="center"
                                className={isLocked ? classes.textGreen : classes.textRed}
                            >
                                {isLocked ? "Locked" : "Unlocked"}
                            </Typography>
                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={this.handleClickLock}
                            >
                                {isLocked ? "UNLOCK" : "LOCK"}
                            </Button>
                        </CardContent>
                        <CardFooter />
                    </Card>
                </Paper>
            </Grid>
        );
    }
}

export default withStyles(styles)(LockAction);