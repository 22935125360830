import React from 'react'
import { BrowserRouter as Route, Link, Switch } from "react-router-dom"
import { Popover, Typography, IconButton, Badge, Grid, Table, TableHead, TableBody, TableRow, TableCell, Icon } from '@material-ui/core'
import classNames from 'classnames'
import { styles } from './badgeComponentStyle'

import SingleUnitContainer from '../../singleUnit/singleUnitContainer'

export default function SimplePopover(data) {
    const classes = styles()
    const [anchorEl, setAnchorEl] = React.useState(null)

    function handleClick(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    function getNotificationAmount(data) {
        let amount = 0

        data.units.forEach((unit) => {
            amount += unit.alerts.filter((alert) => !alert.seen).length
        })

        return amount
    }

    function getNotifications(data) {
        let notifications = []

        data.units.forEach((unit) => {
            unit.alerts.forEach((alert) => {
                alert.registrationNumber = unit.registrationNumber
                notifications.push(alert)
            })
        })

        return notifications
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    let notificationAmount = getNotificationAmount(data)
    let notifications = getNotifications(data)
    return (
        <div>
            <IconButton aria-label="4 pending messages" className={classes.icon} onClick={handleClick}>
                <Badge badgeContent={notificationAmount} color="error">
                    <Icon className={classNames(classes.icon, "fa fa-bell")} />
                </Badge>
            </IconButton>
            <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.container}
                >
                    {
                        notifications.length ?
                            (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Level</TableCell>
                                            <TableCell>Message</TableCell>
                                            <TableCell>Vehicle</TableCell>
                                            <TableCell>Time Stamp</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            notifications.filter((alert) => !alert.seen).sort((a, b) => (a.time < b.time) ? 1 : ((b.time < a.time) ? -1 : 0)).map((notification, index) => {
                                                let iconClass
                                                if (notification.level === 1) {
                                                    iconClass = classes.iconMinor
                                                }
                                                if (notification.level === 2) {
                                                    iconClass = classes.iconMajor
                                                }
                                                if (notification.level === 3) {
                                                    iconClass = classes.iconCritical
                                                }
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Icon className={classNames(classes.icon, "fa fa-exclamation-circle", iconClass)} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {notification.message}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link to={`/case1/singleunit#${notification.registrationNumber}`} onClick={() => handleClose()}>{notification.registrationNumber}</Link>
                                                            <Switch>
                                                                <Route path='/case1/singleunit' component={SingleUnitContainer} />
                                                            </Switch>
                                                        </TableCell>
                                                        <TableCell className={classes.dateCell}>
                                                            {notification.time}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            )
                            :
                            (
                                <>
                                    <Grid item xs={12} className={classes.grid}>
                                        <Icon className={classNames(classes.icon, "fa fa-exclamation-circle")} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.grid}>
                                        <Typography className={classes.typography}><b>No Notifications found.</b></Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.grid}>
                                        <Typography className={classes.typography}>Notifications, e.g. if you have reached a threshold, will show up here.</Typography>
                                    </Grid>
                                </>
                            )
                    }
                </Grid>
            </Popover>
        </div>
    )
}