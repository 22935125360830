export const styles = theme => ({
    hidden: {
        display: "none"
    },
    th: {
        width: "40%"
    },
    textField: {
        width: "100%"
    },
    infoText: {
        marginBottom: theme.spacing(2),
        color: theme.palette.grey.main,
        fontSize: "0.9em",
        fontWeight: "100",
    },
    selectOutlined: {
        width: "100%"
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12)",
        '& .MuiTabs-indicator': {
          backgroundColor: theme.palette.white.main
        },
      },
});