import React from 'react'
import { Container, withStyles } from '@material-ui/core/'
import { styles } from './dashboardStyle'

import AdminDashboard from './adminDashboard'
import TechnicianDashboard from './technicianDashboard'
import MainDashboard from './mainDashboard'

class DashboardView extends React.Component {
    renderDashboardContent() {
        const { units, userRole, theme } = this.props

        if (userRole === 'admin') {
            return <AdminDashboard units={units} theme={theme}/> //view still needs redesign
        }
        if (userRole === 'technician') {
            return <TechnicianDashboard units={units} theme={theme}/> //more to come in this view in later commit
        }
        if (userRole === 'default') {
            return <MainDashboard units={units} theme={theme}/> //more to come in this view in later commit
        }
    }
    render() {
        const { classes } = this.props
        return (
            <Container maxWidth={false} className={classes.container}>
                {this.renderDashboardContent()}
            </Container>
        );
    }
}

export default withStyles(styles)(DashboardView);