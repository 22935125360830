export const styles = theme => ({
    chart: {
        marginLeft: '0',
        backgroundColor: theme.palette.primary.main
    },
    chartBox: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "5px",
        border: "solid 5px",
        borderColor: theme.palette.primary.main,
        boxShadow: "1px 2px 3px -1px rgba(0,0,0,.7)",
        margin: " 0 16px",
        marginTop: theme.spacing(-3),
        marginBottom: theme.spacing(2)
    },
});