import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    icon: {
        color: theme.palette.black.main,
        width: "auto"
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
    },
    error: {
        color: theme.palette.error.light,
    },
    link: {
        textDecoration: "none",
        color: "inherit"
    },
    drawerPaper: {
        padding: "0px 16px",
        overflow: "hidden",
        border: "none",
        backgroundColor: theme.palette.black.main,
        color: theme.palette.white.main,
    },
    drawerHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        backgroundColor: theme.palette.black.main,
        color: theme.palette.white.main
    },
    icons: {
        color: theme.palette.white.main,
    },
    text: {
        color: theme.palette.white.main
    },
    settingsButton: {
        marginTop: '9px',
        marginLeft: '25px',
        marginRight: '-20px'
    },
    userName: {
        marginLeft: "8px"
    },
    switch: {
        '& .MuiSwitch-track': {
            backgroundColor: "White"
        }
    },
    iconButton: {
        color: 'grey'
    },
    radio: {
        color: '#707070'
    }
}));