import React from 'react';
import { Typography, Grid, TextField, Table, TableBody, TableCell, TableRow, Checkbox, Divider, InputAdornment, IconButton } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SubmitButton from '../buttons/submitButton';
import { styles } from './tabStyles';
import PreviewMap from '../maps/previewMap';

class GeneralTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            apiKey: '',
            showApi: true,
            email: '',
            phoneNumber: "0708033443",
            apiActive: true,
            smsNotifications: false,
            mailNotifications: true,
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleMouseDownApi = this.handleMouseDownApi.bind(this)
        this.handleClickShowApi = this.handleClickShowApi.bind(this)
    }

    handleClickShowApi = () => {
        this.setState({
            showApi: !this.state.showApi
        })
    };

    handleMouseDownApi = (event) => {
        event.preventDefault();
    };

    handleInputChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
    
        this.setState({
          [name]: value
        });
    }

    render() {
        const { classes } = this.props
        const { apiActive, apiKey, showApi, smsNotifications, mailNotifications, phoneNumber, email } = this.state
        return ( 
            <>
                <h3 className={classes.title}>General</h3>
                <Typography className={classes.infoText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
                <Divider />
                <Grid container>
                    <Grid item xs={12}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow >
                                    <TableCell component="th" className={classes.th}>
                                        Api key
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            type="checkbox"
                                            name="apiActive"
                                            checked={apiActive}
                                            onChange={this.handleInputChange}
                                            value={apiActive}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow className={apiActive ? '' : classes.hidden}>
                                    <TableCell className={classes.th} component="th">
                                        Api key
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            className={classes.textField}
                                            name="apiKey"
                                            value={apiKey}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                            placeholder="fak3-3c10-4179-aa2b-2d747b8e2c4b"
                                            type={showApi ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowApi}
                                                        onMouseDown={this.handleMouseDownApi}
                                                    >
                                                        {showApi ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <h3 className={classes.title}>Notifications Settings</h3>
                <Typography className={classes.infoText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>
                <Divider />
                <Grid container>
                    <Grid item xs={12}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow >
                                    <TableCell className={classes.th} component="th">
                                        Sms notification
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            type="checkbox"
                                            name="smsNotifications"
                                            checked={smsNotifications}
                                            onChange={this.handleInputChange}
                                            value={smsNotifications}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell component="th">
                                        Phone Number
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled={smsNotifications ? false : true}
                                            className={classes.textField}
                                            name="phoneNumber"
                                            placeholder="Phone number"
                                            id="outlined-name"
                                            value={phoneNumber}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                            margin="normal"
                                            type="number"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.th} component="th">
                                        Email notification
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            type="checkbox"
                                            name="mailNotifications"
                                            checked={mailNotifications}
                                            onChange={this.handleInputChange}
                                            value={mailNotifications}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell component="th">
                                        Email address
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled={mailNotifications ? false : true}
                                            className={classes.textField}
                                            name="email"
                                            placeholder="Email address"
                                            id="outlined-email"
                                            value={email}
                                            onChange={this.handleInputChange}
                                            variant="outlined"
                                            margin="normal"
                                            type="email"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <SubmitButton />
                </Grid>
            </>
        );
    }
}
 
export default withStyles(styles) (GeneralTab);