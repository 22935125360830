import * as React from "react";
import { PlayArrow, Pause, SkipNext, SkipPrevious } from "@material-ui/icons";
import { Button, Slider, withStyles } from '@material-ui/core';
import { styles } from './historySliderStyle';
import classNames from 'classnames';

import { thailand } from '../maps/geoFences/thailand';


class HistorySlider extends React.Component {
    state = {
        time: undefined,
        historyCurrentTime: undefined
    }

    componentDidMount() {
        const lastItem = this.props.unit[0].historicRoute.length - 1
        this.setState({
            historyCurrentTime: this.props.unit[0].historicRoute[lastItem][2]
        })
    }

    checkGeoFence(point, thailandFence, addAlert) {
        let x = point[0], y = point[1];

        let outOffBounds = true;
        for (let i = 0, j = thailandFence.length - 1; i < thailandFence.length; j = i++) {
            let xi = thailandFence[i][0], yi = thailandFence[i][1];
            let xj = thailandFence[j][0], yj = thailandFence[j][1];

            let intersect = ((yi > y) !== (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) outOffBounds = !outOffBounds;
        }
        if (outOffBounds && addAlert) {
            setTimeout(() => {
                this.props.addGeoAlert(this.props.unit[0], point)
            }, 1000)
        }
        return outOffBounds;
    };

    getSlidePos(pos) {
        this.props.updateReplayState({
            markerMove: this.props.unit[0].historicRoute[pos],
            currentPosition: pos,
            outOffBounds: this.checkGeoFence(this.props.unit[0].historicRoute[pos], thailand),
            animationTime: 250
        })
    }

    valueLabelFormat(currentPosition) {
        const { unit } = this.props
        let historyTimestamp

        if (unit[0].historicRoute[currentPosition]) {
            historyTimestamp = unit[0].historicRoute[currentPosition][2]
        } else if (unit[0].historicRoute[currentPosition - 1]) {
            historyTimestamp = unit[0].historicRoute[currentPosition - 1][2]
        } else {
            historyTimestamp = this.state.historyCurrentTime
        }

        return (
            historyTimestamp
        )
    }

    render() {
        const { unit, replay, currentPosition, classes } = this.props

        return (
            <div className="historyContainer">
                <Button
                    disabled={replay}
                    color="primary"
                    variant="contained"
                    className={replay ? "disabledButton" : ""}
                    onClick={() => {
                        this.props.updateReplayState({
                            currentPosition: 0,
                            markerMove: unit[0].historicRoute[0],
                            animationTime: 25,
                            outOffBounds: this.checkGeoFence(unit[0].historicRoute[0], thailand)
                        })
                    }}
                >
                    <SkipPrevious />
                </Button>
                {
                    replay ?
                        (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    this.props.updateReplayState({
                                        replay: false,
                                        markerMove: unit[0].historicRoute[currentPosition - 1],
                                        animationTime: 25
                                    })
                                }}
                            >
                                <Pause />
                            </Button>
                        )
                        :
                        (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    this.props.updateReplayState({

                                        replay: true,
                                        outOffBounds: this.checkGeoFence(unit[0].historicRoute[0], thailand),
                                        animationTime: 25,
                                        initPlay: true
                                    })
                                }}
                            >
                                <PlayArrow />
                            </Button>
                        )
                }
                <Button
                    color="primary"
                    variant="contained"
                    className={replay ? "disabledButton" : ""}
                    disabled={replay}
                    onClick={() => {
                        this.props.updateReplayState({
                            currentPosition: unit[0].historicRoute.length,
                            markerMove: unit[0].historicRoute[unit[0].historicRoute.length - 1],
                            animationTime: 25,
                            outOffBounds: this.checkGeoFence(unit[0].historicRoute[unit[0].historicRoute.length - 2], thailand)
                        })
                    }}
                >
                    <SkipNext />
                </Button>
                {<Slider
                    className={classNames(classes.slider, "slider")}
                    disabled={replay}
                    defaultValue={currentPosition}
                    aria-labelledby="discrete-slider"
                    onChange={(element, value) => {
                        this.getSlidePos(value)
                    }}
                    valueLabelDisplay="on"
                    value={replay ? currentPosition - 1 : currentPosition}
                    step={1}
                    marks
                    min={0}
                    max={unit[0].historicRoute.length - 1}
                    valueLabelFormat={() => this.valueLabelFormat(currentPosition)}
                />}
            </div>
        )
    }
}

export default withStyles(styles)(HistorySlider);