export const styles = theme => ({
    cardHeader2: {
        padding: "16px",
        background: theme.palette.white.main,
        textAlign: "left",
        borderTopRightRadius: "5px",
        borderTopLeftRadius: "5px",
    },
    cardFooter: {
        padding: "8px 0px 0px 16px !important",
        textAlign: "left",
        fontSize: "0.9em",
        fontWeight: "100",
        borderRadius: "0",
        justifyContent: "space-between",
        alignItems: "left",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    mapBox: {
        backgroundColor: theme.palette.white.main,
        borderRadius: "5px",
        marginTop: theme.spacing(-3),
        marginBottom: theme.spacing(2)
    },
    smallText: {
        textAlign: "right",
        display: "block",
        padding: "0px 16px",
    },
    heading: {
        fontSize: "16px",
        fontWeight: "600",
        marginTop: "-9px",
        marginBottom: "16px",
    },
    markerPopup: {
        marginBottom: '-78px',
        '& div': {
            '&:nth-child(2)': {
                width: '40px',
                height: '20px',
                position: 'absolute',
                left: '50%',
                marginLeft: '-20px',
                overflow: 'hidden',
                pointerEvents: 'none',
                transform: 'rotate(180deg)',
                marginTop: '-94px'
            }
        },
        '& a': {
            margin: '5px',
        }
    }
});
