export const styles = theme => ({
    bottom: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    CardHeader: {
        backgroundColor: theme.palette.card.main,
        padding: "16px 16px 8px 16px",
        height: "71px"
    },
    noVehicle: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "25vh",
        textAlign: "center"
    },
    noVehicleDiv: {
        width: "80%",
    },
});