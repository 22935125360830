export const styles = theme => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
        margin: "0 auto",
        width: "100%",
        height: "36px",
        borderRadius: "2px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)",
        '&:hover': {
            backgroundColor: theme.palette.darkBlue.main
        }
    },
    appBar: {
        color: theme.palette.text.main,
        backgroundColor: theme.palette.white.main,
        boxShadow: "none",
        borderBottom: "1px solid lightgrey",
    },
    alertButtonContainer: {
        paddingTop: "24px"
    },
    iconSolved: {
        margin: "0px 8px",
        color: "#9a9a9a"
    },
    iconCritical: {
        margin: "0px 8px",
        color: theme.palette.error.main
    },
    iconMajor: {
        margin: "0px 8px",
        color: theme.palette.warning.main
    },
    iconMinor: {
        margin: "0px 8px",
        color: theme.palette.minor.main
    },
    icon: {
        fontSize: "1.5em",
        width: "auto",
        overflow: "unset"
    },
});