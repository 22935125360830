import { connect } from "react-redux";
import { updateUnits, changeUserRole } from '../../appActions';
import NavigationSmallView from './navigationSmall';

const mapStateToProps = (state, props) => {

    return {
        units: state.appReducer.units,
        hash: props.location.hash,
        theme: state.themeReducer.theme,
        themeName: state.themeReducer.themeName,
        userRole: state.appReducer.userRole
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateUnits: (data) => {
            dispatch(updateUnits(data))
        },
        changeUserRole: (role) => {
            dispatch(changeUserRole(role))
        }
    };
}

const NavigationSmallContainer = connect(mapStateToProps, mapDispatchToProps)(NavigationSmallView);

export default NavigationSmallContainer;