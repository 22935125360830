import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, Container, Paper, Typography, Avatar, CardContent, Table, TableBody,
    TableCell, TableRow, Icon, Dialog, DialogTitle, DialogContent, DialogActions,
    DialogContentText, Button
} from '@material-ui/core';
import classNames from 'classnames';

import { styles } from './singleUnitHeaderStyle';
import mopedImage from '../../../assets/images/moped.jpg';
import vespaImage from '../../../assets/images/vespa.jpg';
import CardHeader1 from '../commons/cardHeader1';
import CardFooter from '../commons/cardFooter';
import CardChartBox1 from '../commons/cardChartBox1';
import Card from '../card';
import CountDown from '../countDown/CountDown';
import BatteryGauge from '../batteryGauges/horizontalBatteryGauge'
import './singleUnitHeader.css'

function createData(name, value) {
    return { name, value };
}


class SingleUnitHeader extends React.Component {
    state = {
        clientDetailsOpen: false
    }

    openClientDetails() {
        this.setState({ ...this.state, clientDetailsOpen: this.state.clientDetailsOpen ? false : true })
    }

    closeClientDetails() {
        this.setState({ ...this.state, clientDetailsOpen: false })
    }

    render() {
        const { classes, unit, addBatteryAlert } = this.props;

        const generalInformation = [
            createData('In use', unit[0].active ? "Yes" : "No"),
            createData('Active alerts', Object.keys(unit[0].alerts).length),
            createData('Rented until', '2019-09-13 15:00'),
            createData('Avg. speed', '25 MPH'),
            createData('Currently rented by:', <a onClick={() => this.openClientDetails()} className={classes.clientLink}>Anton Svensson</a>)
        ];

        const clientInformation = [
            createData('Name', 'Anton Svensson'),
            createData('Personal number', '1987-12-06'),
            createData('Phone number', '072 3456789'),
            createData('Driver licence since', '2010 (type B)'),
            createData('Currently rented vehicle', unit[0].registrationNumber),
        ];

        return (
            <Container maxWidth={false} style={{ margin: "12px 0px", padding: "0" }}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                >
                    <Grid item xs={12} md={12} lg={6}>
                        <Paper className={classes.paper}>
                            <CardContent>
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    <Icon className={classNames(classes.icon, "fa fa-info-circle")} />
                                </Avatar>
                                <Typography
                                    align="right"
                                    className={classes.title}
                                >
                                    {unit[0].registrationNumber}
                                </Typography>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                >
                                    <Grid item xs={4}>
                                        <img
                                            src={unit[0].image === "moped" ? mopedImage : vespaImage}
                                            alt="Background image, Moped"
                                            className={classes.image}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div className={classes.unitInfoContainer}>
                                            <Table className={classes.table}>
                                                <TableBody>
                                                    {generalInformation.map(row => (
                                                        <TableRow key={row.name}>
                                                            <TableCell component="th" scope="row">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell>{row.value}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Paper className={classes.paper}>
                            <Card>
                                <CardChartBox1>
                                    <div className={classes.serviceBox}>
                                        <Typography className={classes.text}>
                                            Days until service
                                        </Typography>
                                        <Typography className={classes.text} variant="h3">
                                            <CountDown date={unit[0].nextService} className={classes.countdown} spinner={true} />
                                        </Typography>
                                        <span className={classes.text} variant="h3">
                                            {unit[0].nextService}
                                        </span>
                                    </div>
                                </CardChartBox1>
                                <p className={classes.leftFat}>Last service <span className={classes.rightFat}>2018-10-31</span></p>
                                <CardFooter />
                            </Card>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Paper className={classes.paper}>
                            <CardHeader1 className={classes.header}>
                                Battery Status
                        </CardHeader1>
                            <CardContent>
                                <BatteryGauge
                                    batteryLevel={unit[0].batteryLevel}
                                    charging={unit[0].charging}
                                    unit={unit[0]}
                                    addBatteryAlert={addBatteryAlert}
                                />
                            </CardContent>
                            <p className={classes.leftFat}>Type <span className={classes.rightFat}>Li-Ion</span></p>
                            <CardFooter />
                        </Paper>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.clientDetailsOpen}
                    onClose={() => this.closeClientDetails()}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div className="clientTableHeader">
                            <Icon color="primary" className={"fa fa-user-circle clientDetailsIcon"} />
                            Client Details
                        </div>
                    </DialogTitle>
                    <DialogContent className="clientTableHolder">
                        <Table>
                            <TableBody>
                                {clientInformation.map(row => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell>{row.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button className="closeButton" onClick={() => this.closeClientDetails()} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        );
    }
}

export default withStyles(styles)(SingleUnitHeader);