import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import logo from '../../assets/images/logoLightTheme.svg';
import mopedBg from '../../assets/images/mopedBG.jpg';
import pumpBg from '../../assets/images/pumpBG.jpg';
import storeBg from '../../assets/images/storeBG.jpg';
import logoFlow from '../../assets/images/logoFlow.svg';
import logoStore from '../../assets/images/logoStore.svg';
import { styles } from './caseLauncherStyle.js'

const SPACES_URL = "https://dzhf3lo9j1kam.cloudfront.net/"

class CaseLauncher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { classes } = this.props
        return (
            <Container maxWidth="xl" style={{ height: "calc(100vh - 48px)" }}>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                    style={{ height: "calc(100vh - 48px)" }}
                >
                    <Grid item sm={12} md={4} className={classes.item}>
                        <Paper className={classes.paper} style={{ background: "url(" + mopedBg + ")" }}>
                            <div>
                                <div>
                                    <img src={logo}
                                        alt="Logotype"
                                        className={classes.logo}
                                    />
                                </div>
                                <span className={classes.span}>
                                    Movement
                                </span>
                                <Typography variant="h5" className={classes.text}>
                                    Use case <br />
                                    Moped Mania
                                </Typography>
                                <Typography className={classes.text}>
                                    Track, analyse and interact with any kind of vehicle. Compare results in different graphs, check vehicle route history, use Geo-fence and more.
                                </Typography>

                                <Link
                                    to="/case1/map"
                                    className={classes.link}
                                >
                                    <Button
                                        className={classes.button}
                                    >
                                        Launch
                                    </Button>
                                </Link>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} md={4} className={classes.item}>
                        <Paper className={classes.paper} style={{ background: "url(" + pumpBg + ")" }}>
                            <div>
                                <div>
                                    <img src={logoFlow}
                                        alt="Logotype"
                                        className={classes.logo}
                                    />
                                </div>
                                <span className={classes.span}>
                                    Stationary
                                </span>
                                <Typography variant="h5" className={classes.text}>
                                    Use case <br />
                                    Pumps and Flows
                                </Typography>
                                <Typography className={classes.text}>
                                    Maintain and track your stationary devices via DemoPortalen. Prevent unnecessary downtimes by getting notifications whenever and error occurs.
                                </Typography>

                                {/*<Link
                                    to="/case1/dashboard"
                                    className={classes.link}
                                > */}
                                <Button
                                    disabled
                                    className={classes.buttonDisabled}
                                >
                                    Not Available
                                    </Button>
                                {/* </Link> */}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} md={4} className={classes.item}>
                        <Paper className={classes.paper} style={{ background: "url(" + storeBg + ")" }}>
                            <div>
                                <div>
                                    <img src={logoStore}
                                        alt="Logotype"
                                        className={classes.logo}
                                    />
                                </div>
                                <span className={classes.span}>
                                    Space
                                </span>
                                <Typography variant="h5" className={classes.text}>
                                    Use case <br />
                                    Grocery Store
                                </Typography>
                                <Typography className={classes.text}>
                                    Analyse your customers movement for the best product placement. Maintain humidity, temperature and more. Save money, time and be climatesmart.
                                </Typography>

                                <a
                                    href={SPACES_URL}
                                    className={classes.link}
                                >
                                    <Button
                                        className={classes.button}
                                    >
                                        Launch
                                    </Button>
                                </a>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withStyles(styles)(CaseLauncher);