import React from 'react';
import { withStyles, Paper, Grid, CardContent, Avatar, Typography, Icon, Button, Table, TableHead, TableBody, TableCell, TableRow, CircularProgress } from '@material-ui/core/';
import classNames from 'classnames';

import CardFooter from '../commons/cardFooter';
import Card from '../card';
import { styles } from './singleUnitActionsStyle';

class LockAction extends React.Component {
    state = {
        tracking: true,
        temperature: true,
        active: false,
        speed: false,
        locked: true,
    }

    resetSensors = () => {
        this.setState({
            tracking: false,
            temperature: false,
            active: false,
            speed: false,
            locked: false,
        })
        setTimeout(() => {
            this.setState({
                tracking: true
            })
        }, 800);
        setTimeout(() => {
            this.setState({
                temperature: true
            })
        }, 1200);
        setTimeout(() => {
            this.setState({
                active: true
            })
        }, 1400);
        setTimeout(() => {
            this.setState({
                speed: true
            })
        }, 2000);
        setTimeout(() => {
            this.setState({
                locked: true
            })
        }, 2500);
    }

    render() { 
        const { classes } = this.props
        const { tracking, temperature, active, speed, locked } = this.state

        function createData(name, status) {
            return { name, status };
        }
          
        const rows = [
            createData('Tracking', tracking ),
            createData('Tempature', temperature),
            createData('Active', active ),
            createData('Speed', speed ),
            createData('Locked', locked ),
        ];

        return ( 
            <Grid item xs={12} md={6} lg={6}>
                <Paper className={classes.paper}>
                    <Grid
                        container
                        direction="row"
                    >
                        <Grid item xs={12} md={6} lg={4}>
                            <Card>
                                <CardContent>
                                    <Avatar className={classes.avatar}>
                                        <Icon className={classNames(classes.icon, "fa fa-rss")} />
                                    </Avatar>
                                    <Typography
                                        align="center"
                                        className={classes.title}
                                    >
                                        Sensors
                                    </Typography>
                                    <div>
                                        {(locked ? <Typography align="center" className={classes.text}>{(speed ? "5/5" : "3/5")}</Typography> : <CircularProgress className={classes.progress} size={30}/>)}
                                    </div>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        onClick={this.resetSensors}
                                    >
                                        Restart
                                    </Button>
                                </CardContent>
                                <CardFooter />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={8}>
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sensor name</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {<Icon className={classNames(row.status ? classes.sensorOn : classes.sensorOff, "fa fa-circle")} />} 
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>  
                </Paper>
            </Grid>
        );
    }
}
 
export default withStyles(styles)(LockAction);