import React from 'react'
import { Grid, Paper, Typography, Button, Icon } from '@material-ui/core'
import { Link } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'

import Card from '../components/card'
import CardFooter from '../components/commons/cardFooter'
import CardChartBox1 from '../components/commons/cardChartBox1'
import DashboardTable from '../components/table/dashboardTable'
import BarChartComponent from '../components/charts/barChartComponent'
import { styles } from './technicianDashboardStyle'


// Dashboard barchart data
const BarChartSeries = [{
    name: '2018',
    data: [14, 32, 44, 30, 8, 10, 29, 23, 30, 4, 11, 16]
}, {
    name: '2019',
    data: [12, 28, 33, 36, 11, 9, 22, 21, 35, 6, 7, 11]
}]

// Dashboard BarChart categories
const BarChartCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

class TechnicianDashboardView extends React.Component {
    render() {
        const { units, classes, theme } = this.props

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={3}>
                    <Paper className={classes.paper}>
                        <Card>
                            <CardChartBox1>
                                <div className={classes.serviceBox}>
                                    <Typography className={classes.text}>
                                        Active alerts
                                        </Typography>
                                    <Typography className={classes.text} variant="h3">
                                        {units.filter((unit) => unit.alerts.length).length}
                                    </Typography>
                                    <span className={classes.text} variant="h3">
                                        of {units.length} units
                                        </span>
                                </div>
                            </CardChartBox1>
                            <Link to={`/case1/fleet#alerts`}>
                                <Button color="primary" className={classes.alertButton}>View Alerts <Icon className={`fa fa-exclamation-circle ${classes.exclamation}`} /></Button>
                            </Link>
                            <CardFooter />
                        </Card>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                    <Paper className={classes.paper}>
                        <Card>
                            <CardChartBox1>
                                <div className={classes.serviceBox}>
                                    <Typography className={classes.text}>
                                        Low battery level
                                        </Typography>
                                    <Typography className={classes.text} variant="h3">
                                        {units.filter((unit) => unit.batteryLevel <= 20).length}
                                    </Typography>
                                    <span className={classes.text} variant="h3">
                                        of {units.length} units
                                        </span>
                                </div>
                            </CardChartBox1>
                            <Link to={`/case1/fleet#batteryLevel`}>
                                <Button color="primary" className={classes.alertButton}>View Battery Levels <Icon className={`fa fa-exclamation-circle ${classes.exclamation}`} /></Button>
                            </Link>
                            <CardFooter />
                        </Card>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <DashboardTable cardContainerClass={classes.cardContainerClass} cardHeaderClass={classes.cardHeaderClass} headerText={'Alerts by vehicle type'} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <BarChartComponent
                        height="375px"
                        title={"Alerts Comparison"}
                        colors={[theme.palette.grey.main, theme.palette.white.main]}
                        strokeColors={[theme.palette.white.main, theme.palette.grey.main]}
                        suffix=""
                        series={BarChartSeries} categories={BarChartCategories}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(TechnicianDashboardView)