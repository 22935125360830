import * as React from "react";
import { Map, TileLayer, Polygon, Polyline } from "react-leaflet";
import { DriftMarker } from "leaflet-drift-marker"
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import HistorySlider from '../historySlider/historySlider'
import AlertSnack from '../alertSnack/alertSnack'
import { thailand } from './geoFences/thailand';
import './singleMap.css'


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

let newIcon = L.Icon.extend({
    options: {
        iconSize: [54, 42],
        iconAnchor: [11, 31],
        popupAnchor: [-3, -76],
        shadowSize: null,
        shadowAnchor: null
    }
});

let blueIcon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedBlue.png') }),
    redIcon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedRed.png') }),
    greyIcon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedGrey.png') }),
    lockedIcon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedLocked.png') });


class SingleMap extends React.Component {
    state = {
        height: this.props.height,
        center: this.props.unit[0].position,
        zoom: 10,
        historyLen: this.props.unit[0].historicRoute.length,
        markerMove: this.props.unit[0].historicRoute[this.props.unit[0].historicRoute.length - 1],
        currentPosition: this.props.unit[0].historicRoute.length,
        animationTime: 2500,
        snackLevel: 'critical',
        snackText: 'This is a critical alert message. Vehicle has exited geo-fence area.',
        scrollWheelZoom: false,
        scrollOverLay: false,
        reg: this.props.unit[0].registrationNumber
    }

    componentDidMount() {
        // TODO: Add local environment
        const url = "wss://fc66bt3qu3.execute-api.eu-west-1.amazonaws.com/Prod"
        const connection = new WebSocket(url)

        connection.onerror = (error) => {
            console.log(`WebSocket error: ${error}`)
        }

        connection.onmessage = (e) => {
            const data = JSON.parse(e.data)
            switch (data.route) {
                case "singleMovementUpdate":
                    this.move(data.message.singleMovementUpdate)
                    break
                case "chargeBattery":
                    this.props.updateBatteryLevel(this.props.unit[0].registrationNumber, data.message.chargeBattery)
                    break
                default:
                    console.log("Not a valid message")
                    break
            }
        }
    }

    move(data) {
        const { addHistory, updateBatteryLevel, unit } = this.props
        let offBounds = this.checkGeoFence(data.coord, thailand, true)
        this.setState({
            ...this.state,
            markerMove: data.coord,
            center: data.coord,
            animationTime: 2500,
            historyLen: this.props.unit[0].historicRoute.length
        })

        addHistory(unit[0], data.coord)
        updateBatteryLevel(unit[0].registrationNumber)

        if (data.atOnce) {
            this.setState({
                ...this.state,
                outOffBounds: offBounds,
                snack: offBounds ? true : false,
                animationTime: 2500,
                historyLen: this.props.unit[0].historicRoute.length
            })
        } else {
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    outOffBounds: offBounds,
                    snack: offBounds ? true : false,
                    animationTime: 2500,
                    historyLen: this.props.unit[0].historicRoute.length
                })
            }, data.reset ? 850 : 1250);
        }
    }

    checkGeoFence(point, thailandFence, addAlert) {
        const { unit } = this.props
        let x = point[0], y = point[1];
        let outOffBounds = true;
        for (let i = 0, j = thailandFence.length - 1; i < thailandFence.length; j = i++) {
            let xi = thailandFence[i][0], yi = thailandFence[i][1];
            let xj = thailandFence[j][0], yj = thailandFence[j][1];

            let intersect = ((yi > y) !== (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) outOffBounds = !outOffBounds;
        }
        if (outOffBounds && addAlert) {
            setTimeout(() => {
                this.props.addGeoAlert(unit[0], point)
            }, 1000)
        }
        return outOffBounds;
    };

    updateReplayState(data) {
        if (data.replay) {
            this.setState({ ...this.state, ...data, historyLen: this.props.unit[0].historicRoute.length })
            setTimeout(() => {
                this.replayHistory()
                this.replayHistory()
            }, 25)
            this.interval = setInterval(() => { this.replayHistory() }, 2500);
        } else {
            clearInterval(this.interval);
            this.setState({ ...this.state, ...data, historyLen: this.props.unit[0].historicRoute.length })
        }

    }

    replayHistory() {
        const { unit } = this.props
        const { currentPosition, initPlay, replay } = this.state
        if (replay) {
            if (currentPosition < unit[0].historicRoute.length) {
                this.setState({
                    ...this.state,
                    markerMove: unit[0].historicRoute[currentPosition],
                    currentPosition: currentPosition + 1,
                    outOffBounds: this.checkGeoFence(unit[0].historicRoute[currentPosition], thailand),
                    animationTime: initPlay ? 250 : 2500,
                    initPlay: false,
                    historyLen: this.props.unit[0].historicRoute.length
                })
            } else {
                this.setState({ ...this.state, replay: false, historyLen: this.props.unit[0].historicRoute.length })
                clearInterval(this.interval);
            }
        }
    }

    closeSnackBar() {
        this.props.markAlertAsSeen(this.props.unit[0])
    }

    handleScroll() {
        const { scrollWheelZoom } = this.state
        this.setState({
            scrollWheelZoom: !scrollWheelZoom,
            historyLen: this.props.unit[0].historicRoute.length
        })
    }

    addScrollOverlay() {
        this.setState({
            scrollOverLay: true,
            historyLen: this.props.unit[0].historicRoute.length
        })
    }

    removeScrollOverlay() {
        this.setState({
            scrollOverLay: false,
            historyLen: this.props.unit[0].historicRoute.length
        })
    }

    getIcon() {
        const { outOffBounds } = this.state
        const { unit } = this.props

        let icon

        if (unit[0].locked) {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedLocked.png') })
        } else if (outOffBounds) {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedRed.png') })
        } else if (unit[0].batteryLevel < 11) {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/battery.png') })
        } else if (unit[0].alerts.length > 0) {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/warning.png') })
        } else if (!unit[0].active) {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedGrey.png') })
        } else {
            icon = new newIcon({ iconUrl: require('../../../assets/images/icons/mopedBlue.png') })
        }
        return icon
    }

    componentDidUpdate() {
        const alerts = this.props.unit[0].alerts.filter((alert) => !alert.seen)
        this.snackLevel = alerts.length ? alerts[0].level : this.snackLevel
        this.snackText = alerts.length ? alerts[0].message : this.snackText
    }

    componentWillReceiveProps() {
        const { unit } = this.props
        const newHistoryLen = unit[0].historicRoute.length
        if (this.state.historyLen < newHistoryLen) {
            this.setState({ ...this.state, markerMove: unit[0].historicRoute[unit[0].historicRoute.length - 1], currentPosition: newHistoryLen })
        }
        if (this.state.reg !== unit[0].registrationNumber) {
            let pos = unit[0].historicRoute[unit[0].historicRoute.length - 1]
            let offBounds = this.checkGeoFence(pos, thailand)
            this.setState({ ...this.state, markerMove: pos, outOffBounds: offBounds, reg: unit[0].registrationNumber, center: pos })
        }
    }

    render() {
        const { unit } = this.props
        const alerts = unit[0].alerts.filter((alert) => !alert.seen)
        const { scrollOverLay, scrollWheelZoom, zoom, center, height, markerMove, animationTime, replay, currentPosition, initPlay } = this.state

        return (
            <>
                <AlertSnack
                    closeSnackBar={() => this.closeSnackBar()}
                    snack={alerts.length ? true : false}
                    snackLevel={this.snackLevel}
                    snackText={this.snackText}
                    alert={alerts[0]}
                />
                <Map
                    maxBounds={L.latLngBounds([-90, -180], [90, 180])}
                    minZoom={2}
                    maxZoom={18}
                    className={scrollOverLay && !scrollWheelZoom ? "scrollOverlay" : "singleMap"}
                    center={center}
                    zoom={zoom}
                    style={{ height: height }}
                    scrollWheelZoom={scrollWheelZoom}
                    onClick={() => this.handleScroll()}
                    onMouseOver={() => this.addScrollOverlay()}
                    onMouseOut={() => this.removeScrollOverlay()}
                >
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        noWrap={true}
                    />
                    <DriftMarker
                        icon={this.getIcon()}
                        position={markerMove}
                        duration={animationTime}
                    >
                    </DriftMarker>
                    <Polygon positions={thailand} color="grey" />
                    {unit[0].historicRoute.map((pos, i) => {
                        if (i !== unit[0].historicRoute.length - 1) {
                            return (
                                <Polyline
                                    key={i}
                                    positions={
                                        [
                                            [pos[0], pos[1]],
                                            [unit[0].historicRoute[i + 1][0], unit[0].historicRoute[i + 1][1]]
                                        ]
                                    }
                                    color={
                                        (
                                            this.checkGeoFence(pos, thailand) || this.checkGeoFence(unit[0].historicRoute[i + 1], thailand)
                                                ?
                                                'red'
                                                :
                                                '#6CA4CB'
                                        )
                                    }
                                />
                            )
                        }
                    })}
                </Map>
                <HistorySlider
                    replay={replay}
                    unit={unit}
                    updateReplayState={(data) => this.updateReplayState(data)}
                    markerMove={markerMove}
                    currentPosition={currentPosition}
                    animationTime={animationTime}
                    initPlay={initPlay}
                />
            </>
        );
    }
}

export default SingleMap;