import React, { Component } from 'react';
import CountUp from 'react-countup';
import Icon from '@material-ui/core/Icon'
import AlertSnack from '../alertSnack/alertSnack'


import './horizontalBatteryGauge.css'

class BatteryGuageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snack: false,
            snackLevel: 'minor',
            snackText: 'This is a minor alert message. Vehicle has low battery.',
        }
        this.juiceClass = 'juice'
    }

    getJuiceClass() {
        if (this.props.batteryLevel <= 20) {
            return this.props.charging ? 'juiceRedSlow' : 'juiceRed'
        }
        if (this.props.batteryLevel <= 40) {
            return this.props.charging ? 'juiceOrangeSlow' : 'juiceOrange'
        }
        if (this.props.batteryLevel >= 40) {
            return this.props.charging ? 'juiceSlow' : 'juice'
        }
    }

    getDescription() {
        if (this.props.charging) {
            return { text: 'Charging', iconColor: 'fa fa-bolt boltIcon green' }
        }
        if (this.props.batteryLevel === 0) {
            return { text: 'Battery Depleted', iconColor: 'fa fa-bolt boltIcon red' }
        }
        if (this.props.batteryLevel <= 20) {
            return { text: 'Low Battery', iconColor: 'fa fa-bolt boltIcon red' }
        }
        if (this.props.batteryLevel <= 40) {
            return { text: 'Partially Charged', iconColor: 'fa fa-bolt boltIcon orange' }
        }
        if (this.props.batteryLevel < 100) {
            return { text: 'Charged', iconColor: 'fa fa-bolt boltIcon green' }
        }
        if (this.props.batteryLevel === 100) {
            return { text: 'Fully Charged', iconColor: 'fa fa-bolt boltIcon green' }
        }
    }

    getIconColor() {
        if (this.props.batteryLevel) {
            return "fa fa-bolt boltIcon green"
        }
    }

    closeSnackBar() {
        this.setState({ ...this.state, snack: false, snackClosed: true })
    }

    componentDidMount() {
        this.setState({ currentCharge: this.props.batteryLevel })
    }

    /*componentWillReceiveProps() {
        const { unit, batteryLevel, addBatteryAlert } = this.props
        let pushBatteryAlerts = true

        unit.alerts.forEach((alert) => {
            if (batteryLevel === alert.batteryLevel) {
                pushBatteryAlerts = false
            }
        })

        if (batteryLevel <= 20
            && pushBatteryAlerts) {
            addBatteryAlert(unit, unit.position, batteryLevel)
        }
    }*/

    render() {
        const { batteryLevel, charging } = this.props
        let width = this.props.batteryLevel + '%'
        const description = this.getDescription()
        const juiceClass = this.getJuiceClass()

        return (
            <div className="batteryGauge">
                <div className="batteryContainer">
                    <div className="battery">
                        <div style={{ width: width }} className={juiceClass}>
                            <h3 className={batteryLevel < 60 ? "whiteNumber w10" : "whiteNumber"}>
                                <CountUp start={charging ? this.state.currentCharge : batteryLevel + 5} end={batteryLevel} delay={0} duration={charging ? 50 : 6}>
                                    {({ countUpRef }) => (
                                        <>
                                            <span ref={countUpRef} /><span>%</span>
                                        </>
                                    )}
                                </CountUp>
                            </h3>
                        </div>
                    </div>
                    <div className="plusContainer">
                        <div className="plus"></div>
                    </div>
                </div >
                <div className="description">
                    <Icon className={description.iconColor} />
                    {description.text}
                </div>
                <AlertSnack
                    closeSnackBar={() => this.closeSnackBar()}
                    snack={this.state.snack}
                    snackLevel={this.state.snackLevel}
                    snackText={this.state.snackText}
                />
            </div>
        );
    }
}

export default (BatteryGuageComponent);