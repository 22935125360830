export const styles = theme => ({
    cardHeader: {
        textAlign: "center"
    },
    card: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.card.main
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
        margin: "0 auto",
        width: "90px",
        height: "36px",
        borderRadius: "2px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)",
        '&:hover': {
            backgroundColor: theme.palette.darkBlue.main
        }
    },
    input: {
        width: "90%",
        margin: theme.spacing(1),
    },
    icon: {
        padding: "0"
    },
    adornment: {
        color: "rgba(0, 0, 0, 0.54)"
    },
    padding: {
        padding: "16px;"
    },
    logo: {
        width: "120px",
        margin: "0 auto",
        display: "block",
        paddingBottom: "16px"
    },
    text: {
        color: "white",
        lineHeight: "1.5"
    },
    error: {
        color: theme.palette.error.main
    }
}) 