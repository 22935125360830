import { connect } from "react-redux";
import { push } from 'connected-react-router'

import SingleUnitView from './singleUnitView';
import { addGeoAlert, addBatteryAlert, addHistory, saveSelectedUnit, updateBatteryLevel, handleVehicleLock, markAlertAsSeen } from '../appActions';

const mapStateToProps = (state, props) => {

    return {
        units: state.appReducer.units,
        hash: props.location.hash,
        selectedUnit: state.appReducer.selectedUnit,
        userRole: state.appReducer.userRole,
        theme: state.themeReducer.theme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeRoute: (selectedVehicle) => {
            dispatch(push(`singleunit#${selectedVehicle}`))
        },
        addGeoAlert: (unit, point) => {
            dispatch(addGeoAlert({ unit, point }))
        },
        addBatteryAlert: (unit, point, batteryLevel) => {
            dispatch(addBatteryAlert({ unit, point, batteryLevel }))
        },
        addHistory: (unit, point) => {
            dispatch(addHistory({ unit, point }))
        },
        saveSelectedUnit: (hash) => {
            dispatch(saveSelectedUnit(hash))
        },
        handleVehicleLock: (reg) => {
            dispatch(handleVehicleLock(reg))
        },
        updateBatteryLevel: (reg, charge) => {
            dispatch(updateBatteryLevel({ reg, charge }))
        },
        markAlertAsSeen: (unit) => {
            dispatch(markAlertAsSeen({ unit }))
        }
    };
}

const SingleUnitContainer = connect(mapStateToProps, mapDispatchToProps)(SingleUnitView);

export default SingleUnitContainer;