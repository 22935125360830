
import {
    MARK_ALERT_AS_SEEN, ADD_GEO_ALERT, ADD_BATTERY_ALERT, ADD_UNIT, SAVE_LANDING_PAGE_PAGE_INC,
    SAVE_LANDING_PAGE_FILTER, UPDATE_UNITS, ADD_HISTORY, SAVE_SELECTED_UNIT, SET_ACTIVE_IN_FLEET_VIEW,
    UPDATE_BATTERY_LEVEL, HANDLE_VEHICLE_LOCK, CHANGE_USER_ROLE
} from "./appActionTypes";

const initialUnits = [
    {
        id: 1,
        registrationNumber: "REG123",
        batteryLevel: 75,
        nextService: "2021-09-10",
        image: "moped",
        locked: false,
        simCoverage: 1,
        active: true,
        outOffBounds: false,
        position: [13.864413726164663, 100.61553955078126],
        operator: 'AV 003',
        alerts: [
            {
                seen: true,
                level: 3,
                position: [12.775012, 101.570013],
                message: "This is a critical alert message. Vehicle has lost connection.",
                time: '2019-09-24 10:47:50'
            },
            {
                seen: true,
                level: 3,
                position: [12.775012, 101.570013],
                message: "This is a critical alert message. Vehicle has lost connection.",
                time: '2019-09-24 10:48:50'
            },
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
                time: '2019-09-24 10:47:50'
            },
            {
                seen: true,
                level: 1,
                position: [12.775012, 101.570013],
                message: "This is a minor alert message. Vehicle currently has low battery.",
                time: '2019-09-24 10:47:50'
            },
        ],
        alertAmount: 3,
        historicAlerts: [
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
            },
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
            },
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
            },
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
            },
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
            },
        ],
        specs: [
            {
                spec1: 100000,
                spec2: "Alexander"
            }
        ],
        historicUsage: [],
        historicRoute: [
            [13.767980874944765, 100.44435024261475, "00:00"],
            [13.780526903367146, 100.44284820556642, "01:00"],
            [13.783861282662798, 100.44344902038576, "02:00"],
            [13.783757084030322, 100.44344902038576, "03:00"],
            [13.785465935732216, 100.44426441192628, "04:00"],
            [13.78713309607328, 100.44520854949953, "05:00"],
            [13.787070577775301, 100.44525146484376, "06:00"],
            [13.791238427662972, 100.44816970825195, "07:00"],
            [13.796239749294838, 100.45005798339845, "07:20"],
            [13.81074297569721, 100.44954299926759, "07:56"],
            [13.823078343145605, 100.44868469238283, "08:30"],
            [13.823578411831674, 100.45675277709962, "08:42"],
            [13.822744963424938, 100.46190261840822, "09:00"],
            [13.821411439769621, 100.46756744384767, "09:10"],
            [13.822244892949046, 100.47649383544922, "10:00"],
            [13.827578922655793, 100.48765182495117, "11:45"],
            [13.83357955998007, 100.50018310546875, "12:00"],
            [13.825995395340984, 100.50310134887697, "12:03"],
            [13.822619945906636, 100.5040454864502, "12:13"],
            [13.821703148721703, 100.50631999969484, "12:24"],
            [13.820577983607473, 100.50846576690675, "12:50"],
            [13.818369310353877, 100.51477432250978, "13:32"],
            [13.817994250551465, 100.51533222198488, "14:00"],
            [13.81749416987557, 100.51644802093507, "15:23"],
            [13.814410315320993, 100.51683425903322, "15:43"],
            [13.815160445861327, 100.5162763595581, "15:53"],
            [13.815660531546557, 100.51511764526369, "16:00"],
            [13.81703576164631, 100.51623344421387, "16:02"],
            [13.817702536954323, 100.51674842834474, "16:06"],
            [13.824495201633573, 100.52417278289796, "16:20"],
            [13.829120767848632, 100.52927970886232, "16:45"],
            [13.830537589561448, 100.53155422210693, "16:56"],
            [13.83095430018846, 100.53460121154787, "17:01"],
            [13.83016254935918, 100.54211139678956, "17:05"],
            [13.829954193430115, 100.54648876190187, "17:13"],
            [13.83057926065772, 100.55751800537111, "17:21"],
            [13.837913257366067, 100.5571746826172, "17:27"],
            [13.841913521751382, 100.55854797363283, "18:01"],
            [13.866330309499784, 100.57159423828126, "18:17"],
            [13.878912702639083, 100.57846069335939, "18:22"],
            [13.884412077316707, 100.58137893676759, "18:34"],
            [13.884245431515911, 100.5817222595215, "18:41"],
            [13.875663010839741, 100.59627056121828, "18:47"],
            [13.873621514500057, 100.59760093688966, "18:59"],
            [13.864413726164663, 100.61553955078126, "19:11"]
        ]
    },
    {
        id: 2,
        registrationNumber: "BIL320",
        batteryLevel: 15,
        nextService: "2019-10-12",
        image: "moped",
        simCoverage: 3,
        active: true,
        outOffBounds: false,
        position: [13.828888, 100.528888],
        operator: 'AV 001',
        alerts: [
            {
                seen: false,
                level: 3,
                position: [12.775012, 101.570013],
                message: "This is a critical alert message. Vehicle has lost connection.",
                time: '2019-09-24 10:47:50'
            },
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
                time: '2019-09-24 10:47:50'
            },
            {
                seen: true,
                level: 1,
                position: [12.775012, 101.570013],
                message: "This is a minor alert message. Vehicle currently has low battery.",
                time: '2019-09-24 10:47:50'
            },
        ],
        alertAmount: 3,
        historicAlerts: [
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
            },
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
            },
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
            },
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
            },
            {
                seen: true,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
            },
        ],
        specs: [
            {
                spec1: 100000,
                spec2: "Alexander"
            }
        ],
        historicUsage: [],
        historicRoute: [
            [13.780012, 100.485513, "17:21"],
            [13.790012, 100.492513, "17:27"],
            [13.800012, 100.492513, "18:01"],
            [13.809012, 100.500013, "18:17"],
            [13.819012, 100.505013, "18:22"],
            [13.821012, 100.520013, "18:34"],
            [13.838012, 100.530013, "18:41"],
            [13.842012, 100.540013, "18:47"],
            [13.857012, 100.550013, "18:59"],
            [13.863012, 100.560013, "19:11"],
            [13.876012, 100.570013, "19:16"],
            [13.884012, 100.580013, "19:22"],
            [13.828888, 100.528888, "19:28"]
        ]
    },
    {
        id: 3,
        registrationNumber: "TUT666",
        batteryLevel: 75,
        locked: true,
        nextService: "2020-01-05",
        image: "vespa",
        simCoverage: 2,
        active: true,
        outOffBounds: false,
        position: [13.837777, 100.537777],
        operator: 'AV 002',
        alerts: [],
        alertAmount: 0,
        specs: [
            {
                spec1: 100000,
                spec2: "Alexander"
            }
        ],
        historicUsage: [],
        historicRoute: [
            [13.780012, 100.485513, "17:21"],
            [13.790012, 100.492513, "17:27"],
            [13.800012, 100.492513, "18:01"],
            [13.809012, 100.500013, "18:17"],
            [13.819012, 100.505013, "18:22"],
            [13.821012, 100.520013, "18:34"],
            [13.838012, 100.530013, "18:41"],
            [13.842012, 100.540013, "18:47"],
            [13.857012, 100.550013, "18:59"],
            [13.863012, 100.560013, "19:11"],
            [13.876012, 100.570013, "19:16"],
            [13.884012, 100.580013, "19:22"],
            [13.837777, 100.537777, '20:01']
        ]
    },
    {
        id: 4,
        registrationNumber: "REG321",
        batteryLevel: 75,
        nextService: "2020-01-05",
        image: "moped",
        simCoverage: 2,
        active: false,
        outOffBounds: false,
        position: [13.846666, 100.546666],
        alerts: [
            {
                seen: false,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
                time: '2019-09-24 10:47:50'
            }
        ],
        alertAmount: 0,
        historicAlerts: [],
        historicUsage: [],
        specs: [
            {
                spec1: 100000,
                spec2: "Alexander"
            }
        ],
        historicRoute: [
            [13.780012, 100.485513, "17:21"],
            [13.790012, 100.492513, "17:27"],
            [13.800012, 100.492513, "18:01"],
            [13.809012, 100.500013, "18:17"],
            [13.819012, 100.505013, "18:22"],
            [13.821012, 100.520013, "18:34"],
            [13.838012, 100.530013, "18:41"],
            [13.842012, 100.540013, "18:47"],
            [13.857012, 100.550013, "18:59"],
            [13.863012, 100.560013, "19:11"],
            [13.876012, 100.570013, "19:16"],
            [13.884012, 100.580013, "19:22"],
            [13.846666, 100.546666, '19:57']
        ]
    },
    {
        id: 5,
        registrationNumber: "BLA777",
        batteryLevel: 75,
        nextService: "2020-01-05",
        image: "moped",
        simCoverage: 2,
        active: true,
        outOffBounds: false,
        position: [13.855555, 100.555555],
        alerts: [],
        alertAmount: 0,
        historicAlerts: [],
        historicUsage: [],
        specs: [
            {
                spec1: 100000,
                spec2: "Alexander"
            }
        ],
        historicRoute: [
            [13.780012, 100.485513, "17:21"],
            [13.790012, 100.492513, "17:27"],
            [13.800012, 100.492513, "18:01"],
            [13.809012, 100.500013, "18:17"],
            [13.819012, 100.505013, "18:22"],
            [13.821012, 100.520013, "18:34"],
            [13.838012, 100.530013, "18:41"],
            [13.842012, 100.540013, "18:47"],
            [13.857012, 100.550013, "18:59"],
            [13.863012, 100.560013, "19:11"],
            [13.876012, 100.570013, "19:16"],
            [13.884012, 100.580013, "19:22"],
            [13.855555, 100.555555, '19:56']
        ]
    },
    {
        id: 6,
        registrationNumber: "SID666",
        batteryLevel: 75,
        nextService: "2020-01-05",
        image: "vespa",
        simCoverage: 2,
        active: true,
        outOffBounds: false,
        position: [13.864444, 100.564444],
        alerts: [],
        alertAmount: 0,
        historicAlerts: [],
        historicUsage: [],
        specs: [
            {
                spec1: 100000,
                spec2: "Alexander"
            }
        ],
        historicRoute: [
            [13.780012, 100.485513, "17:21"],
            [13.790012, 100.492513, "17:27"],
            [13.800012, 100.492513, "18:01"],
            [13.809012, 100.500013, "18:17"],
            [13.819012, 100.505013, "18:22"],
            [13.821012, 100.520013, "18:34"],
            [13.838012, 100.530013, "18:41"],
            [13.842012, 100.540013, "18:47"],
            [13.857012, 100.550013, "18:59"],
            [13.863012, 100.560013, "19:11"],
            [13.876012, 100.570013, "19:16"],
            [13.884012, 100.580013, "19:22"],
            [13.864444, 100.564444, '19:56']
        ]
    },
    {
        id: 7,
        registrationNumber: "SID888",
        batteryLevel: 75,
        nextService: "2020-01-05",
        image: "moped",
        simCoverage: 2,
        active: true,
        outOffBounds: false,
        position: [13.873333, 100.573333],
        alerts: [
            {
                seen: false,
                level: 2,
                position: [12.775012, 101.570013],
                message: "This is a major alert message. Vehicle has exited geo-fence area.",
                time: '2019-09-24 10:47:50'
            }
        ],
        alertAmount: 1,
        historicAlerts: [],
        historicUsage: [],
        specs: [
            {
                spec1: 100000,
                spec2: "Alexander"
            }
        ],
        historicRoute: [
            [13.780012, 100.485513, "17:21"],
            [13.790012, 100.492513, "17:27"],
            [13.800012, 100.492513, "18:01"],
            [13.809012, 100.500013, "18:17"],
            [13.819012, 100.505013, "18:22"],
            [13.821012, 100.520013, "18:34"],
            [13.838012, 100.530013, "18:41"],
            [13.842012, 100.540013, "18:47"],
            [13.857012, 100.550013, "18:59"],
            [13.863012, 100.560013, "19:11"],
            [13.876012, 100.570013, "19:16"],
            [13.884012, 100.580013, "19:22"],
            [13.873333, 100.573333, "19:56"]
        ]
    },
    {
        id: 8,
        registrationNumber: "INA134",
        batteryLevel: 75,
        nextService: "2020-03-12",
        image: "moped",
        simCoverage: 2,
        active: false,
        outOffBounds: false,
        position: [13.506556, 100.429435],
        alerts: [],
        alertAmount: 1,
        historicAlerts: [],
        historicUsage: [],
        specs: [
            {
                spec1: 100000,
                spec2: "Alexander"
            }
        ],
        historicRoute: [
            [13.506556, 100.429435, "17:21"],
            [13.790012, 100.492513, "17:27"],
            [13.800012, 100.492513, "18:01"],
            [13.809012, 100.500013, "18:17"],
            [13.819012, 100.505013, "18:22"],
            [13.821012, 100.520013, "18:34"],
            [13.838012, 100.530013, "18:41"],
            [13.842012, 100.540013, "18:47"],
            [13.857012, 100.550013, "18:59"],
            [13.863012, 100.560013, "19:11"],
            [13.876012, 100.570013, "19:16"],
            [13.884012, 100.580013, "19:22"],
            [13.506556, 100.429435, '19:56']
        ]
    },
    {
        id: 9,
        registrationNumber: "AVS867",
        batteryLevel: 75,
        nextService: "2019-12-24",
        image: "moped",
        simCoverage: 1,
        active: false,
        outOffBounds: false,
        position: [13.562632, 100.396490],
        alerts: [],
        alertAmount: 1,
        historicAlerts: [],
        historicUsage: [],
        specs: [
            {
                spec1: 100000,
                spec2: "Alexander"
            }
        ],
        historicRoute: [
            [13.562632, 100.396490, "17:21"],
            [13.790012, 100.492513, "17:27"],
            [13.800012, 100.492513, "18:01"],
            [13.809012, 100.500013, "18:17"],
            [13.819012, 100.505013, "18:22"],
            [13.821012, 100.520013, "18:34"],
            [13.838012, 100.530013, "18:41"],
            [13.842012, 100.540013, "18:47"],
            [13.857012, 100.550013, "18:59"],
            [13.863012, 100.560013, "19:11"],
            [13.876012, 100.570013, "19:16"],
            [13.884012, 100.580013, "19:22"],
            [13.562632, 100.396490, '19:56']
        ]
    }
]
const initialState = {
    units: initialUnits,
    initialUnits: initialUnits,
    userRole: 'admin'
};

function addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
        value = '0' + value;
    }
    return value;
}

let today = new Date();
let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + addLeadingZeros(today.getDate()) + ' ' + addLeadingZeros(today.getHours()) + ":" + addLeadingZeros(today.getMinutes()) + ":" + addLeadingZeros(today.getSeconds());

/*Example code*/
export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_UNIT:
            return Object.assign({}, state, {
                unit: state.units.concat(action.payload)
            });
        case UPDATE_UNITS:
            let units = action.payload ? action.payload.concat(initialUnits) : initialUnits
            return Object.assign({}, state, {
                units: units
            });
        case ADD_GEO_ALERT:
            let pushGeoAlerts = false
            let newGeoAlerts = state.units.map((unit) => {
                if (unit.registrationNumber === action.payload.unit.registrationNumber) {
                    if (unit.alerts.length) {
                        unit.alerts.forEach((alert) => {
                            if (alert.position[0] !== action.payload.point[0] && alert.position[1] !== action.payload.point[1]) {
                                pushGeoAlerts = true
                            }
                        })
                    } else {
                        pushGeoAlerts = true
                    }
                    if (pushGeoAlerts) {
                        unit.alerts.push({
                            seen: false,
                            level: 2,
                            message: "This is a major alert message. Vehicle has exited geo-fence area.",
                            position: action.payload.point,
                            time: date
                        })
                    }
                }
                return unit
            });
            return Object.assign({}, state, {
                units: newGeoAlerts
            });

        case ADD_BATTERY_ALERT:
            let pushBatteryAlerts = false
            let newBatteryAlerts = state.units.map((unit) => {
                if (unit.registrationNumber === action.payload.unit.registrationNumber) {
                    if (unit.alerts.length) {
                        unit.alerts.forEach((alert) => {
                            if (alert.position[0] !== action.payload.point[0] && alert.position[1] !== action.payload.point[1] && action.payload.batteryLevel !== alert.batteryLevel) {
                                pushBatteryAlerts = true
                            }
                        })
                    } else {
                        pushBatteryAlerts = true
                    }
                    if (pushBatteryAlerts) {
                        unit.alerts.push({
                            seen: false,
                            batteryLevel: action.payload.batteryLevel,
                            level: 1,
                            message: "This is a minor alert message. Vehicle currently has low battery.",
                            position: action.payload.point,
                            time: date
                        })
                    }
                }
                return unit
            });

            return Object.assign({}, state, {
                units: newBatteryAlerts
            });


        case ADD_HISTORY:
            let newHistory = state.units.map((unit) => {
                if (unit.registrationNumber === action.payload.unit.registrationNumber) {
                    unit.historicRoute.push(action.payload.point)
                }
                return unit
            })
            return Object.assign({}, state, {
                units: newHistory
            });
        case SAVE_SELECTED_UNIT:
            return { ...state, selectedUnit: action.payload }
        case SET_ACTIVE_IN_FLEET_VIEW:
            return { ...state, activeInFleetViewReg: action.payload.reg, activeInFleetViewPos: action.payload.pos }

        case UPDATE_BATTERY_LEVEL:
            let updatedBatteryUnits = state.units
            updatedBatteryUnits.map((unit) => {
                if (unit.registrationNumber === action.payload.reg) {
                    if (action.payload.charge) {
                        unit.batteryLevel = action.payload.charge
                        unit.charging = true
                    } else if (unit.batteryLevel >= 5) {
                        unit.batteryLevel = unit.batteryLevel - 5
                        unit.charging = false
                    } else {
                        unit.batteryLevel = 0
                        unit.charging = false
                    }
                }
                return unit
            })
            return { ...state, units: updatedBatteryUnits }
        case HANDLE_VEHICLE_LOCK:
            let lockedUnits = state.units
            lockedUnits.map((unit) => {
                if (unit.registrationNumber === action.payload) {
                    unit.locked = unit.locked ? false : true
                }
                return unit
            })
            return { ...state, units: lockedUnits }
        case CHANGE_USER_ROLE:
            return {
                ...state,
                userRole: action.payload
            }
        case SAVE_LANDING_PAGE_FILTER:
            return {
                ...state,
                landingPageFilter: action.payload
            }
        case SAVE_LANDING_PAGE_PAGE_INC:
            return {
                ...state,
                landingPagePageInc: action.payload
            }
        case MARK_ALERT_AS_SEEN: {
            let newAlerts = state.units.map((unit) => {
                if (unit.registrationNumber === action.payload.unit.registrationNumber) {
                    unit.alerts.map((alert) => {
                        alert.seen = true
                        return alert
                    })
                }
                return unit
            });

            return Object.assign({}, state, {
                units: newAlerts
            });
        }
        default:
            return state;
    }
}
