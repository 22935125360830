import React from "react"
import Chart from "react-apexcharts"
import { withStyles, Paper, FormControl, Select, MenuItem } from '@material-ui/core'

import Card from '../card'
import CardFooter from '../commons/cardFooter'
import CardChartBox2 from '../commons/cardChartBox2'
import { styles } from './barChartComponentStyle'

class BarChartContainer extends React.Component {

    constructor(props) {
        super(props)
        const { colors, categories, series, suffix } = this.props
        this.state = {
            options: {
                colors: colors,
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                chart: {
                    toolbar: {
                        show: false,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true | '<img src="/static/icons/reset.png" width="20">',
                            customIcons: []
                        },
                        autoSelected: 'zoom'
                    },
                },
                grid: {
                    show: true,
                    borderColor: '#e7e7e7',
                    strokeDashArray: 8,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true
                        },
                    },
                    row: {
                        colors: undefined,
                        opacity: 0.5
                    },
                    column: {
                        colors: undefined,
                        opacity: 0.8
                    },
                },
                dataLabels: {
                    enabled: false,
                    formatter: function (val) {
                        return val + suffix;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#ffffff"]
                    },
                    dropShadow: {
                        enabled: true,
                        top: 1,
                        left: 1,
                        blur: 1,
                        opacity: 0.45
                    }
                },
                xaxis: {
                    categories: categories,
                    position: 'bottom',
                    labels: {
                        offsetY: -0,
                        style: {
                            colors: "white",
                            fontSize: '12px',
                            fontFamily: 'Roboto, sans-serif',
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#ffffff',
                                colorTo: '#ffffff',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val + suffix ? suffix : ""
                            }
                        }
                    }
                },

                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + suffix
                        }
                    }
                },

                fill: {
                    colors: colors,
                    opacity: 0.9,
                },
                yaxis: {
                    labels: {
                        show: true,
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 160,
                        style: {
                            color: "white",
                            fontSize: '12px',
                            fontFamily: 'Roboto, sans-serif',
                        },
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    labels: {
                        colors: "#ffffff",
                        useSeriesColors: false
                    },
                    markers: {
                        size: 5,
                        opacity: 0.9,
                        colors: colors,
                        strokeColor: "#3E3E3E",
                        strokeWidth: 2,
                    },
                }
            },
            series: series
        }
    }

    render() {
        const { classes, title, height, barchartContainerClass } = this.props;
        const { options, series } = this.state
        return (
            <Paper className={`${classes.paper} ${barchartContainerClass}`} >
                <Card>
                    <CardChartBox2>
                        <Chart
                            className={classes.paper}
                            options={options}
                            series={series}
                            type="bar"
                            height={height}
                        />
                    </CardChartBox2>
                    <div className={classes.selectHolder}>
                        <div className={classes.selectHolderInner}>
                            <p className={classes.leftFat}>
                                {title}
                            </p>
                            <FormControl className={classes.formControl} variant="standard">
                                <Select
                                    disableUnderline
                                    value={3}
                                    onChange={() => { }}
                                    inputProps={{
                                        name: 'age',
                                        id: 'outlined-age-simple',
                                    }}
                                >
                                    <MenuItem disabled value={1}>Week</MenuItem>
                                    <MenuItem disabled value={2}>Month</MenuItem>
                                    <MenuItem value={3}>Year</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <CardFooter>
                        Updated 5 minutes ago
                    </CardFooter>
                </Card>
            </ Paper>

        );
    }
}

export default withStyles(styles)(BarChartContainer);