import React from 'react'
import { Typography, Grid, RadioGroup, Radio, FormControlLabel } from '@material-ui/core/'

import { styles } from './roleSwitcherStyle'


export default function DrawerRight(props) {
    const classes = styles();

    return (
        <>
            <Typography><b>Change role</b></Typography>
            <Grid container justify="flex-start" alignItems="flex-end">
                <RadioGroup aria-label="role" name="role" value={props.userRole} onChange={(e, v) => { props.changeUserRole(v) }}>
                    <FormControlLabel
                        value="default"
                        control={<Radio className={classes.radio} color="secondary" />}
                        label="Default"
                    />
                    <FormControlLabel
                        value="technician"
                        control={<Radio className={classes.radio} color="secondary" />}
                        label="Technician"
                    />
                    <FormControlLabel
                        value="admin"
                        control={<Radio className={classes.radio} color="secondary" />}
                        label="Admin"
                    />
                </RadioGroup>
            </Grid>
            <br />
        </>
    )
}