import { connect } from "react-redux";
import SortableMapView from './sortableMapView';
import { setCurrentMarker } from '../../../commonActions/commonActions';
import { expandMarkerInfo } from '../../../commonActions/commonActions';
import { switchPolygon } from '../../../commonActions/commonActions';
import { updateUnits } from '../../../appActions';

const mapStateToProps = (state, props) => {
    return {
        currentSelectedMarker: state.currentMarker.currentSelectedMarker,
        expanded: state.expandMarkerInfoReducer.expanded,
        units: props.units,
        initialUnits: state.appReducer.initialUnits,
        mapConfig: state.mapReducer.mapConfig
    };
};

const mapDispatchToProps = dispatch => {
    return {
        expandMarkerInfo: expanded => dispatch(expandMarkerInfo(expanded)),
        setCurrentMarker: marker => dispatch(setCurrentMarker(marker)),
        switchPolygon: mapConfig => dispatch(switchPolygon(mapConfig)),
        updateUnits: data => {
            dispatch(updateUnits(data))
        }
    };
}

const SortableMapContainer = connect(mapStateToProps, mapDispatchToProps)(SortableMapView);

export default SortableMapContainer;