import React from 'react';
import CountUp from 'react-countup';
import { withStyles, Paper, Grid, CardContent, Avatar, Typography, Icon } from '@material-ui/core/';
import classNames from 'classnames';

import CardFooter from '../commons/cardFooter';
import Card from '../card';
import { styles } from './overviewStyle'

class Overview extends React.Component {
    state = {}

    renderCardContent(classes, item) {
        return (
            <>
                <Typography
                    align="right"
                    className={classes.title}
                >
                    {item.name}
                </Typography>
                <Typography
                    align="right"
                    className={classes.text}
                >
                    <CountUp start={0} end={item.value} delay={0} suffix={" " + item.suffix} duration="3">
                        {({ countUpRef }) => (
                            <span ref={countUpRef} />
                        )}
                    </CountUp>
                </Typography>
            </>
        )
    }


    render() {
        const { classes, units } = this.props;
        const data = [
            { name: "Revenue", value: 654, suffix: "€", icon: <Icon className={classNames(classes.icon, "fa fa-hand-holding-usd")} /> },
            { name: "Distance Traveled", value: 13800, suffix: "Km", icon: <Icon className={classNames(classes.icon, "fa fa-location-arrow")} /> },
            { name: "Vehicles", value: units.length, suffix: "Units", icon: <Icon className={classNames(classes.icon, "fa fa-motorcycle")} /> },
            { name: "Kw Consumption", value: 12600, suffix: "Kw", icon: <Icon className={classNames(classes.icon, "fa fa-bolt")} /> }
        ]

        const items = data.map((item, index) => {
            if (index === 2) {
                return (
                    <Grid key={index} item xs={12} md={6} lg={3}>
                        <Paper className={classes.paper}>
                            <Card>
                                <CardContent>
                                    <Avatar aria-label="recipe" className={classes.avatar2}>
                                        {item.icon}
                                    </Avatar>
                                    {this.renderCardContent(classes, item)}
                                </CardContent>
                                <CardFooter>
                                    Updated 4 hours ago
                            </CardFooter>
                            </Card>
                        </Paper>
                    </Grid>
                )
            } else {
                return (
                    <Grid key={index} item xs={12} md={6} lg={3}>
                        <Paper className={classes.paper}>
                            <Card>
                                <CardContent>
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        {item.icon}
                                    </Avatar>
                                    {this.renderCardContent(classes, item)}
                                </CardContent>
                                <CardFooter>
                                    Updated 4 hours ago
                            </CardFooter>
                            </Card>
                        </Paper>
                    </Grid>
                )
            }
        });

        return (
            <div className={classes.root}>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    className={classes.grid}
                >
                    {items}
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Overview);
