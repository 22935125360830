import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './rawDataModalStyle';

function createData(name, value) {
    return { name, value };
}

const RawDataTable = (props) => {
    const { open, handleClose, scrollType, unit, classes } = props
    const raw = unit[0]
    const rawData = [
        createData('RegistrationNr', raw.registrationNumber),
        createData('Latitude', raw.position[0]),
        createData('Longitude', raw.position[1]),
        createData('Sim Coverage', raw.simCoverage),
        createData('Next Service', raw.nextService),
        createData('Engine Temperature', '87°C'),
        createData('Battery Level', raw.batteryLevel),
        createData('Power', 'Off'),
        createData('Idle', 'yes'),
        createData('Total Distance Driven', '4235 Km'),
        createData('Last Distance Driven', '16 Km'),
        createData('Max Speed', '35 Km/h'),
        createData('Tire Pressure (Front)', '28 PSI'),
        createData('Tire Pressure (Back)', '27 PSI'),
    ];
    
    return ( 
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            scroll={scrollType}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{"Raw Data"}</DialogTitle>
            <DialogContent>
                <Table className={classes.alertsTable}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Data</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rawData.map(row => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog> 
     );
}
 
export default withStyles(styles)(RawDataTable);