export const styles = (theme) => ({
    switchContainer: {
        width: "100%",
        margin: "16px 0px"
    },
    button: {
        color: theme.palette.white.main
    },
    typography: {
        marginBottom: '10px'
    }
}); 