import { connect } from "react-redux";
import LogoutView from './logoutView';
import { login } from '../../commonActions/commonActions';

const mapStateToProps = state => {
    return {
        isLoggedIn: state.loginReducer.auth,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: isLoggedIn => dispatch(login(isLoggedIn)),
    };
}

const LogoutContainer = connect(mapStateToProps, mapDispatchToProps)(LogoutView);

export default LogoutContainer;