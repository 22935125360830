let initialUnits = getUnits()
function createUnit(long, lat, index) {
    let randomBattery = getRandomBattery(101)
    return {
        id: index,
        registrationNumber: getRegLetters(3) + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        nextService: "2020-10-12",
        batteryLevel: randomBattery,
        simCoverage: Math.floor(Math.random() * (3 - 1 + 1) + 1),
        active: true,
        outOffBounds: false,
        position: [long, lat],
        operator: 'AV ' + Math.floor(Math.random() * (999 - 100 + 1) + 100),
        alerts: randomBattery > 20 ? getRandomAlert(21) : [{ level: 1, position: [12.775012, 101.570013], message: "This is a minor alert message. Vehicle has low battery.", time: '2019-09-24 10:47:50' }],
        alertAmount: randomBattery > 20 ? getRandomAlert(21) ? 1 : 0 : 1,
        specs: [
            {
                spec1: Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
                spec2: "Alexander"
            }
        ],
        historicUsage: [],
        historicRoute: [
            [13.780012, 100.485513, "17:21"],
            [13.790012, 100.492513, "17:27"],
            [13.800012, 100.492513, "18:01"],
            [13.809012, 100.500013, "18:17"],
            [13.819012, 100.505013, "18:22"],
            [13.821012, 100.520013, "18:34"],
            [13.838012, 100.530013, "18:41"],
            [13.842012, 100.540013, "18:47"],
            [13.857012, 100.550013, "18:59"],
            [13.863012, 100.560013, "19:11"],
            [13.876012, 100.570013, "19:16"],
            [13.884012, 100.580013, "19:22"],
            [13.899999, 100.599999, "19:58"]
        ]
    }
}

function getUnits() {
    let units = []
    for (let i = 0; i < 90; i++) {
        units.push(createUnit(0, 0, i))
    }
    return units
}

function changeCoordinates() {
    // What is this?
    initialUnits.map((unit, i) => {
        let histRoute = [
            [13.780012, 100.485513, "17:21"],
            [13.790012, 100.492513, "17:27"],
            [13.800012, 100.492513, "18:01"],
            [13.809012, 100.500013, "18:17"],
            [13.819012, 100.505013, "18:22"],
            [13.821012, 100.520013, "18:34"],
            [13.838012, 100.530013, "18:41"],
            [13.842012, 100.540013, "18:47"],
            [13.857012, 100.550013, "18:59"],
            [13.863012, 100.560013, "19:11"],
            [13.876012, 100.570013, "19:16"],
            [13.884012, 100.580013, "19:22"],
            [13.899999, 100.599999, "19:45"],
            [13.899999, 100.599999, "19:45"]
        ]
        let pos
        if (i > 0 && i < 20) {
            pos = [(Math.random() * (13.700001 - 13.799999) + 13.700001).toFixed(6), (Math.random() * (100.500001 - 100.599999) + 100.500001).toFixed(6)]
            unit.position = pos
            histRoute.push([unit.position[0], unit.position[1], '20:00'])
            unit.historicRoute = histRoute
        } else if (i > 20 && i < 40) {
            pos = [(Math.random() * (13.800001 - 13.899999) + 13.800001).toFixed(6), (Math.random() * (100.600001 - 100.699999) + 100.600001).toFixed(6)]
            unit.position = pos
            histRoute.push([unit.position[0], unit.position[1], '20:00'])
            unit.historicRoute = histRoute
        } else if (i > 40 && i < 60) {
            pos = [(Math.random() * (13.800001 - 13.899999) + 13.800001).toFixed(6), (Math.random() * (100.700001 - 100.799999) + 100.700001).toFixed(6)]
            unit.position = pos
            histRoute.push([unit.position[0], unit.position[1], '20:00'])
            unit.historicRoute = histRoute
        } else if (i > 60 && i < 63) {
            pos = [(Math.random() * (14.090001 - 14.199999) + 14.090001).toFixed(6), (Math.random() * (100.700001 - 100.799999) + 100.700001).toFixed(6)]
            unit.position = pos
            histRoute.push([unit.position[0], unit.position[1], '20:00'])
            unit.historicRoute = histRoute
            unit.outOffBounds = true
            unit.alerts = [{
                level: 3,
                message: "This is a critical alert message. Vehicle has lost connection.",
                position: [13.890012, 100.480013],
                time: '2019-09-24 10:47:50'
            }]
        } else if (i > 63 && i < 67) {
            pos = [(Math.random() * (13.600001 - 13.699999) + 13.600001).toFixed(6), (Math.random() * (100.850001 - 100.899999) + 100.850001).toFixed(6)]
            unit.position = pos
            unit.outOffBounds = true
            histRoute.push([unit.position[0], unit.position[1], '20:00'])
            unit.historicRoute = histRoute
            unit.alerts = [{
                level: 3,
                message: "This is a critical alert message. Vehicle has lost connection.",
                position: [13.890012, 100.480013],
                time: '2019-09-24 10:47:50'
            }]
        } else {
            pos = [(Math.random() * (13.900001 - 13.999999) + 13.900001).toFixed(6), (Math.random() * (100.800001 - 100.899999) + 100.800001).toFixed(6)]
            unit.position = pos
            histRoute.push([unit.position[0], unit.position[1], '20:00'])
            unit.historicRoute = histRoute
        }
    })
    return initialUnits
}

function getRegLetters(length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return result;
}

function getRandomAlert(units) {
    let result = []
    let random
    for (let i = 0; i < units; i++) {
        random = Math.floor(Math.random() * Math.floor(21))
    }
    if (random > 19) {
        result = [
            {
                level: 3,
                position: [12.775012, 101.570013],
                message: "This is a critical alert message. Vehicle has lost connection.",
                time: '2019-09-24 10:47:50'
            }
        ];
    }
    return result
}

function getRandomBattery(units) {
    let result = []
    for (let i = 0; i < units; i++) {
        result = Math.floor(Math.random() * Math.floor(101))
    }
    return result
}

export function getCordinates() {
    return {
        units: changeCoordinates()
    }
}

// setInterval(broadcastRandomMovement, 75000)
