export const latLong =
    [
        [100.327877099999995, 13.804184299999999],
        [100.331850299999999, 13.8038721],
        [100.341678900000005, 13.803489799999999],
        [100.350251099999994, 13.8032451],
        [100.351910000000004, 13.8031972],
        [100.370775899999998, 13.802619699999999],
        [100.373786899999999, 13.802527599999999],
        [100.375024699999997, 13.802489700000001],
        [100.380305800000002, 13.8023609],
        [100.3986144, 13.801820299999999],
        [100.398806399999998, 13.801814],
        [100.404858700000005, 13.801617],
        [100.408487600000001, 13.801334900000001],
        [100.411066399999996, 13.801299],
        [100.415219800000003, 13.801155700000001],
        [100.415454499999996, 13.8011418],
        [100.426540299999999, 13.8004795],
        [100.430059299999996, 13.800163700000001],
        [100.432506200000006, 13.8000071],
        [100.444872500000002, 13.7992154],
        [100.448600200000001, 13.798976700000001],
        [100.450311499999998, 13.798853100000001],
        [100.458853899999994, 13.798259399999999],
        [100.462273199999998, 13.7976177],
        [100.463202199999998, 13.797401600000001],
        [100.4640038, 13.7972149],
        [100.466301200000004, 13.7963073],
        [100.468172100000004, 13.7953703],
        [100.468764199999995, 13.7951552],
        [100.469264999999993, 13.794939899999999],
        [100.469740599999994, 13.794748200000001],
        [100.469415499999997, 13.794078300000001],
        [100.468568000000005, 13.793123599999999],
        [100.467857899999998, 13.792013600000001],
        [100.4671412, 13.7909124],
        [100.466619199999997, 13.7898976],
        [100.466267700000003, 13.789155900000001],
        [100.466516900000002, 13.7892136],
        [100.470249499999994, 13.7900884],
        [100.472104999999999, 13.790533],
        [100.473535200000001, 13.7909513],
        [100.475068899999997, 13.7914926],
        [100.476066000000003, 13.791871199999999],
        [100.476420500000003, 13.7920388],
        [100.478325600000005, 13.7927214],
        [100.478672000000003, 13.792803299999999],
        [100.480451200000005, 13.793411900000001],
        [100.481927200000001, 13.793949100000001],
        [100.488496900000001, 13.7962995],
        [100.492547099999996, 13.797734999999999],
        [100.496736600000006, 13.7992139],
        [100.4997659, 13.8003211],
        [100.501087699999999, 13.8010047],
        [100.5019621, 13.801561700000001],
        [100.5028918, 13.802281600000001],
        [100.505573699999999, 13.804697300000001],
        [100.510109999999997, 13.808827300000001],
        [100.514811800000004, 13.813368499999999],
        [100.514413300000001, 13.8137241],
        [100.512248999999997, 13.8149649],
        [100.509634199999994, 13.8156968],
        [100.506321799999995, 13.816492],
        [100.506800499999997, 13.817587700000001],
        [100.506664499999999, 13.8179889],
        [100.506415599999997, 13.8186973],
        [100.506278199999997, 13.8190057],
        [100.506321200000002, 13.819430799999999],
        [100.506501400000005, 13.8196975],
        [100.5066731, 13.8200059],
        [100.506836100000001, 13.8204893],
        [100.507033500000006, 13.821006000000001],
        [100.507378200000005, 13.8213861],
        [100.5075571, 13.8215811],
        [100.507582900000003, 13.821831100000001],
        [100.507565700000001, 13.8221478],
        [100.507565700000001, 13.8223979],
        [100.507574300000002, 13.8226396],
        [100.507737300000002, 13.822898],
        [100.507951800000001, 13.822972999999999],
        [100.508166500000002, 13.823073000000001],
        [100.508346700000004, 13.823198],
        [100.508509799999999, 13.8233397],
        [100.508638500000004, 13.8234564],
        [100.508938900000004, 13.8235314],
        [100.509196500000002, 13.8235397],
        [100.509445400000004, 13.823656400000001],
        [100.509728600000003, 13.8238064],
        [100.509960300000003, 13.8240564],
        [100.509968900000004, 13.8242648],
        [100.5099175, 13.8244898],
        [100.509831500000004, 13.8245732],
        [100.509702799999999, 13.8246232],
        [100.509419500000007, 13.824673199999999],
        [100.509204999999994, 13.8248566],
        [100.509179200000005, 13.825098199999999],
        [100.509204999999994, 13.8253316],
        [100.509256500000006, 13.8254983],
        [100.509411, 13.82559],
        [100.509582600000002, 13.8255733],
        [100.510020400000002, 13.825373300000001],
        [100.5103723, 13.825223299999999],
        [100.510526799999994, 13.825264900000001],
        [100.510749899999993, 13.825456600000001],
        [100.510878700000006, 13.82579],
        [100.510921600000003, 13.8260234],
        [100.510998799999996, 13.8261567],
        [100.511041700000007, 13.826181699999999],
        [100.511788499999994, 13.826056700000001],
        [100.5119945, 13.8259484],
        [100.512157500000001, 13.825765000000001],
        [100.5122863, 13.82549],
        [100.512518, 13.8251483],
        [100.512612399999995, 13.8248649],
        [100.512780300000003, 13.8246903],
        [100.512981499999995, 13.824673199999999],
        [100.513110299999994, 13.824756499999999],
        [100.513127400000002, 13.8249566],
        [100.513127400000002, 13.8253983],
        [100.513110299999994, 13.8258233],
        [100.513144600000004, 13.826098399999999],
        [100.513264800000002, 13.8261734],
        [100.513410699999994, 13.8261234],
        [100.513384900000005, 13.826265100000001],
        [100.513608099999999, 13.8263734],
        [100.513908499999999, 13.8264067],
        [100.514268999999999, 13.8265818],
        [100.514775400000005, 13.826831800000001],
        [100.514947000000006, 13.8268568],
        [100.515075800000005, 13.826923499999999],
        [100.515247400000007, 13.8269485],
        [100.515779600000002, 13.827573599999999],
        [100.515796800000004, 13.828332],
        [100.515839700000001, 13.8284737],
        [100.515934099999996, 13.828565299999999],
        [100.5168611, 13.829132100000001],
        [100.517676499999993, 13.829890499999999],
        [100.518757899999997, 13.8306489],
        [100.519470299999995, 13.831398999999999],
        [100.520028199999999, 13.831799],
        [100.521023799999995, 13.8327908],
        [100.521401499999996, 13.8330658],
        [100.521996000000001, 13.833618100000001],
        [100.5222598, 13.8338576],
        [100.522474399999993, 13.833999199999999],
        [100.522877800000003, 13.8345159],
        [100.523341299999998, 13.8348076],
        [100.523521500000001, 13.8350993],
        [100.524208200000004, 13.8356327],
        [100.524628699999994, 13.8359577],
        [100.524731700000004, 13.836091100000001],
        [100.525581500000001, 13.836841099999999],
        [100.525862700000005, 13.837061800000001],
        [100.525962399999997, 13.8371706],
        [100.526753099999993, 13.837806],
        [100.527708899999993, 13.838652700000001],
        [100.528434099999998, 13.839267299999999],
        [100.529229299999997, 13.839941400000001],
        [100.529787200000001, 13.840491399999999],
        [100.530036100000004, 13.840749799999999],
        [100.530285000000006, 13.840949800000001],
        [100.530456599999994, 13.8410414],
        [100.530559600000004, 13.8411998],
        [100.530748500000001, 13.841324800000001],
        [100.531126099999994, 13.841741499999999],
        [100.531709800000002, 13.8420915],
        [100.531864299999995, 13.8422248],
        [100.532121799999999, 13.842541499999999],
        [100.532319200000003, 13.842749899999999],
        [100.532525199999995, 13.8428749],
        [100.532662500000001, 13.8430582],
        [100.532945699999999, 13.843308199999999],
        [100.533289199999999, 13.8435082],
        [100.533417900000003, 13.8436916],
        [100.533709700000003, 13.8440499],
        [100.534155900000002, 13.844458299999999],
        [100.534337100000002, 13.844524],
        [100.534965700000001, 13.845052600000001],
        [100.535437000000002, 13.845543299999999],
        [100.536060300000003, 13.8460891],
        [100.536299, 13.8462286],
        [100.536507700000001, 13.846266699999999],
        [100.536672100000004, 13.8463227],
        [100.536945500000002, 13.8464917],
        [100.537277700000004, 13.846598],
        [100.537542700000003, 13.8466893],
        [100.537762000000001, 13.8469078],
        [100.538001300000005, 13.8471251],
        [100.538307599999996, 13.847298],
        [100.539281000000003, 13.8476993],
        [100.5399496, 13.848008399999999],
        [100.540801400000007, 13.848361499999999],
        [100.541374399999995, 13.8486584],
        [100.542275599999996, 13.849016799999999],
        [100.542754200000005, 13.8492569],
        [100.543255400000007, 13.849388599999999],
        [100.5436871, 13.849690000000001],
        [100.543697600000002, 13.8497342],
        [100.543720399999998, 13.8498301],
        [100.543726000000007, 13.8498514],
        [100.543767799999998, 13.850014099999999],
        [100.543794599999998, 13.8500818],
        [100.543929800000001, 13.850632600000001],
        [100.544342900000004, 13.8523774],
        [100.545281700000004, 13.856429500000001],
        [100.548615799999993, 13.870554500000001],
        [100.550295399999996, 13.8776697],
        [100.551895999999999, 13.884443299999999],
        [100.551843300000002, 13.8845638],
        [100.554310799999996, 13.89508],
        [100.554364199999995, 13.895308],
        [100.554406400000005, 13.8954883],
        [100.554386199999996, 13.895546400000001],
        [100.556956400000004, 13.9063319],
        [100.558893699999999, 13.9144732],
        [100.560040299999997, 13.9194142],
        [100.563502499999998, 13.9340922],
        [100.563641000000004, 13.9346052],
        [100.567599799999996, 13.951352999999999],
        [100.568163900000002, 13.9516296],
        [100.568256500000004, 13.9516849],
        [100.568721999999994, 13.9518228],
        [100.569002100000006, 13.952086400000001],
        [100.569589199999996, 13.9523628],
        [100.570292199999997, 13.952771500000001],
        [100.570906199999996, 13.9530561],
        [100.572011799999999, 13.9537049],
        [100.5728419, 13.954145],
        [100.573089100000004, 13.9543184],
        [100.575037899999998, 13.954304499999999],
        [100.575589699999995, 13.9542626],
        [100.576323299999999, 13.953986199999999],
        [100.577957900000001, 13.9536623],
        [100.586245599999998, 13.9517639],
        [100.586882700000004, 13.951705499999999],
        [100.588300799999999, 13.9512967],
        [100.588857500000003, 13.951213600000001],
        [100.589997199999999, 13.9509287],
        [100.591332899999998, 13.950452],
        [100.593773299999995, 13.9494095],
        [100.596811599999995, 13.9480019],
        [100.599902099999994, 13.946710400000001],
        [100.602888800000002, 13.9452611],
        [100.605711900000003, 13.9441261],
        [100.604456900000002, 13.9535187],
        [100.606866100000005, 13.953789499999999],
        [100.606754499999994, 13.955017099999999],
        [100.6128073, 13.9551874],
        [100.617030200000002, 13.9529633],
        [100.620197399999995, 13.951414],
        [100.622094200000006, 13.950539300000001],
        [100.622655199999997, 13.9502024],
        [100.623795599999994, 13.9494855],
        [100.624149599999996, 13.9492054],
        [100.624598899999995, 13.9487696],
        [100.6248887, 13.9480459],
        [100.625081199999997, 13.947359000000001],
        [100.625039599999994, 13.9463627],
        [100.625015399999995, 13.9449577],
        [100.625011099999995, 13.944846],
        [100.624992700000007, 13.9443777],
        [100.624983900000004, 13.943132500000001],
        [100.624899600000006, 13.9379826],
        [100.639653699999997, 13.937294100000001],
        [100.639777800000005, 13.9372884],
        [100.639607100000006, 13.933037300000001],
        [100.639606499999999, 13.9326618],
        [100.639623599999993, 13.932360600000001],
        [100.639702099999994, 13.932213600000001],
        [100.639889100000005, 13.932002300000001],
        [100.640287799999996, 13.9316996],
        [100.640605699999995, 13.9315251],
        [100.640895799999996, 13.9314009],
        [100.641311999999999, 13.9313114],
        [100.641643799999997, 13.9312887],
        [100.662566699999999, 13.9303711],
        [100.664390999999995, 13.9303606],
        [100.664931999999993, 13.930360800000001],
        [100.667841699999997, 13.930361700000001],
        [100.676399399999994, 13.9306631],
        [100.686381499999996, 13.930922600000001],
        [100.689772500000004, 13.9310455],
        [100.689766700000007, 13.929403600000001],
        [100.689729900000003, 13.929127599999999],
        [100.689447900000005, 13.927785800000001],
        [100.689412700000005, 13.927378600000001],
        [100.689339599999997, 13.926071800000001],
        [100.689287699999994, 13.925812499999999],
        [100.6888431, 13.9253599],
        [100.688683100000006, 13.925046],
        [100.6885774, 13.924815199999999],
        [100.688472300000001, 13.9238824],
        [100.687824800000001, 13.9228966],
        [100.687327199999999, 13.9216265],
        [100.687239399999996, 13.9213612],
        [100.687115399999996, 13.9208531],
        [100.6871613, 13.919848999999999],
        [100.687257000000002, 13.9189737],
        [100.687294699999995, 13.918616],
        [100.687504099999998, 13.917617999999999],
        [100.687519499999993, 13.9173209],
        [100.6874368, 13.917105400000001],
        [100.686829200000005, 13.916273500000001],
        [100.686939699999996, 13.9162763],
        [100.715076100000005, 13.9171066],
        [100.750078299999998, 13.918259000000001],
        [100.752667400000007, 13.9184792],
        [100.792575799999995, 13.931611699999999],
        [100.914140799999998, 13.9462063],
        [100.909189900000001, 13.9074381],
        [100.906523699999994, 13.880937599999999],
        [100.906298399999997, 13.860371900000001],
        [100.905796899999999, 13.859928399999999],
        [100.905468999999997, 13.8590371],
        [100.904704499999994, 13.858859199999999],
        [100.902644699999996, 13.850698100000001],
        [100.902497299999993, 13.849567],
        [100.902215799999993, 13.848847900000001],
        [100.902783799999995, 13.848733599999999],
        [100.908057400000004, 13.847689799999999],
        [100.912674699999997, 13.846822],
        [100.912398699999997, 13.8461613],
        [100.911424999999994, 13.8444685],
        [100.909867000000006, 13.8426027],
        [100.908930299999994, 13.841017900000001],
        [100.908420199999995, 13.8395771],
        [100.908049300000002, 13.838685699999999],
        [100.907696900000005, 13.838172399999999],
        [100.909546700000007, 13.8367424],
        [100.938444899999993, 13.8144244],
        [100.931222399999996, 13.8050131],
        [100.9303101, 13.8036788],
        [100.929518099999996, 13.802842399999999],
        [100.929002800000006, 13.8018716],
        [100.928309499999997, 13.8011879],
        [100.925534600000006, 13.799735699999999],
        [100.919713200000004, 13.7961659],
        [100.914246199999994, 13.793078700000001],
        [100.909915799999993, 13.7905482],
        [100.904965300000001, 13.781777999999999],
        [100.890518400000005, 13.756125000000001],
        [100.880430000000004, 13.7400883],
        [100.880390399999996, 13.7400295],
        [100.867686899999995, 13.7198393],
        [100.859429500000005, 13.706296699999999],
        [100.8551559, 13.6993955],
        [100.858346499999996, 13.6980887],
        [100.860755100000006, 13.697028899999999],
        [100.859338600000001, 13.689779],
        [100.829049699999999, 13.7015707],
        [100.802420999999995, 13.711646500000001],
        [100.797389600000002, 13.7134213],
        [100.787966299999994, 13.716723200000001],
        [100.730715599999996, 13.716844],
        [100.730348199999995, 13.7184905],
        [100.716295500000001, 13.7184179],
        [100.716305800000001, 13.7170211],
        [100.709964299999996, 13.7169724],
        [100.709917300000001, 13.716970999999999],
        [100.7099963, 13.7164947],
        [100.710041899999993, 13.716020500000001],
        [100.710149200000004, 13.715619200000001],
        [100.7103745, 13.7142772],
        [100.710524699999993, 13.7130265],
        [100.710703499999994, 13.7122137],
        [100.711042399999997, 13.7096129],
        [100.711455099999995, 13.706999400000001],
        [100.711619099999993, 13.705626199999999],
        [100.711930300000006, 13.704208599999999],
        [100.712358499999993, 13.701274400000001],
        [100.712298200000006, 13.7012258],
        [100.711052300000006, 13.701221800000001],
        [100.709874600000006, 13.695125900000001],
        [100.710214899999997, 13.6950471],
        [100.709802499999995, 13.690936000000001],
        [100.709537499999996, 13.690090700000001],
        [100.709141599999995, 13.689012200000001],
        [100.708912799999993, 13.6882471],
        [100.708640099999997, 13.6878291],
        [100.708327199999999, 13.687044800000001],
        [100.708010299999998, 13.686489099999999],
        [100.707761599999998, 13.6857793],
        [100.707309100000003, 13.684885899999999],
        [100.706700999999995, 13.681229],
        [100.706456099999997, 13.679363199999999],
        [100.706410000000005, 13.678610900000001],
        [100.7054878, 13.678799100000001],
        [100.704665500000004, 13.674778399999999],
        [100.704736600000004, 13.674651300000001],
        [100.705113499999996, 13.6744997],
        [100.705188000000007, 13.6743199],
        [100.704591399999998, 13.672780899999999],
        [100.703970100000006, 13.671469],
        [100.703356299999996, 13.670472],
        [100.701633700000002, 13.667398],
        [100.700461300000001, 13.665146],
        [100.699685900000006, 13.663679500000001],
        [100.699314299999998, 13.662489600000001],
        [100.698979899999998, 13.6611098],
        [100.698596600000002, 13.659324399999999],
        [100.698231800000002, 13.655863099999999],
        [100.693473100000006, 13.6571514],
        [100.692896700000006, 13.6573384],
        [100.690498599999998, 13.657798400000001],
        [100.6881123, 13.658287],
        [100.686019700000003, 13.658962199999999],
        [100.6834104, 13.6597831],
        [100.681107299999994, 13.6605437],
        [100.6783717, 13.661428000000001],
        [100.676909600000002, 13.662285900000001],
        [100.676680399999995, 13.6624666],
        [100.675861100000006, 13.6633254],
        [100.675739399999998, 13.6633973],
        [100.675358299999999, 13.6635027],
        [100.6747266, 13.663751],
        [100.673506000000003, 13.664059200000001],
        [100.672357899999994, 13.664543699999999],
        [100.672282300000006, 13.6646611],
        [100.672249100000002, 13.6649271],
        [100.672182199999995, 13.6649788],
        [100.670913799999994, 13.665456199999999],
        [100.666448299999999, 13.6671657],
        [100.666535300000007, 13.667341800000001],
        [100.666870900000006, 13.668176900000001],
        [100.666454799999997, 13.668371199999999],
        [100.665980899999994, 13.6686409],
        [100.665202800000003, 13.6691909],
        [100.6634514, 13.670259],
        [100.662369699999999, 13.671253200000001],
        [100.662222700000001, 13.6713358],
        [100.662160900000003, 13.6713358],
        [100.661922000000004, 13.6713007],
        [100.661769899999996, 13.6713174],
        [100.661155800000003, 13.6716956],
        [100.660975399999998, 13.671612],
        [100.660755100000003, 13.671305],
        [100.6604928, 13.670924400000001],
        [100.659898299999995, 13.6701242],
        [100.659132600000007, 13.670597600000001],
        [100.6583282, 13.670988400000001],
        [100.658123099999997, 13.6711046],
        [100.657759999999996, 13.671360999999999],
        [100.657263799999996, 13.6716268],
        [100.656590899999998, 13.671916],
        [100.656559599999994, 13.6719259],
        [100.655746199999996, 13.672181800000001],
        [100.655405000000002, 13.6722948],
        [100.655339400000003, 13.672151599999999],
        [100.654733500000006, 13.6708277],
        [100.654491300000004, 13.670428599999999],
        [100.654263700000001, 13.670010400000001],
        [100.653921699999998, 13.669332499999999],
        [100.653828099999998, 13.669015999999999],
        [100.653819100000007, 13.6689858],
        [100.653735100000006, 13.668702100000001],
        [100.653582599999993, 13.6682717],
        [100.653383599999998, 13.667744600000001],
        [100.653169899999995, 13.667127600000001],
        [100.652918700000001, 13.666365799999999],
        [100.652703399999993, 13.665637800000001],
        [100.652441400000001, 13.664685199999999],
        [100.652242700000002, 13.6638135],
        [100.651676199999997, 13.6625079],
        [100.651333899999997, 13.6616863],
        [100.651121700000004, 13.661140899999999],
        [100.650900500000006, 13.6606609],
        [100.650789799999998, 13.660294],
        [100.650699000000003, 13.659520199999999],
        [100.650657199999998, 13.659253400000001],
        [100.649983500000005, 13.652366600000001],
        [100.6407557, 13.6545723],
        [100.640601099999998, 13.6538421],
        [100.640246200000007, 13.653867999999999],
        [100.640077199999993, 13.653263000000001],
        [100.639656500000001, 13.6532816],
        [100.638807099999994, 13.651148600000001],
        [100.637933000000004, 13.651680300000001],
        [100.637181200000001, 13.650198400000001],
        [100.635806799999997, 13.6487187],
        [100.630138500000001, 13.6493632],
        [100.630448799999996, 13.650632099999999],
        [100.624994400000006, 13.6524628],
        [100.624864599999995, 13.653201899999999],
        [100.618482599999993, 13.655660900000001],
        [100.618797299999997, 13.656637999999999],
        [100.617520499999998, 13.657123199999999],
        [100.615698199999997, 13.653656700000001],
        [100.614038600000001, 13.6542853],
        [100.613860700000004, 13.6540201],
        [100.608400399999994, 13.6563575],
        [100.608451799999997, 13.6565096],
        [100.606883699999997, 13.657155899999999],
        [100.607410099999996, 13.6585725],
        [100.605077399999999, 13.6594585],
        [100.604619799999995, 13.658101500000001],
        [100.6037216, 13.658417399999999],
        [100.603638000000004, 13.6581557],
        [100.602089500000005, 13.658762899999999],
        [100.601389699999999, 13.657076],
        [100.600940499999993, 13.6572605],
        [100.601184500000002, 13.657844600000001],
        [100.600194599999995, 13.6582218],
        [100.600486399999994, 13.659459200000001],
        [100.599078300000002, 13.659773700000001],
        [100.597683700000005, 13.660345299999999],
        [100.596044399999997, 13.6608614],
        [100.596107399999994, 13.661183299999999],
        [100.595764200000005, 13.661236600000001],
        [100.595977599999998, 13.662324],
        [100.581196500000004, 13.6680756],
        [100.579458799999998, 13.669284899999999],
        [100.582473399999998, 13.673218200000001],
        [100.5848522, 13.6761602],
        [100.587370800000002, 13.6793247],
        [100.588784000000004, 13.683074299999999],
        [100.5888104, 13.6831917],
        [100.589461999999997, 13.6860894],
        [100.588831400000004, 13.6888196],
        [100.588549299999997, 13.690041799999999],
        [100.587803800000003, 13.6914339],
        [100.586094500000002, 13.6942199],
        [100.584835699999999, 13.695666599999999],
        [100.583198600000003, 13.697081900000001],
        [100.582634600000006, 13.6975941],
        [100.581092799999993, 13.698660500000001],
        [100.578944199999995, 13.6998818],
        [100.566134199999993, 13.7047794],
        [100.563012099999995, 13.7057647],
        [100.562563900000001, 13.705910599999999],
        [100.558862099999999, 13.7064602],
        [100.556065000000004, 13.7061323],
        [100.5548936, 13.7055302],
        [100.553097899999997, 13.7040544],
        [100.551796600000003, 13.701921199999999],
        [100.551406200000002, 13.7002475],
        [100.551326599999996, 13.698701700000001],
        [100.551526899999999, 13.6964006],
        [100.551788500000001, 13.694885599999999],
        [100.552262299999995, 13.691273600000001],
        [100.552539400000001, 13.6878016],
        [100.552088699999999, 13.6807968],
        [100.550842399999993, 13.6777028],
        [100.548018499999998, 13.6727539],
        [100.546653399999997, 13.671859299999999],
        [100.544010400000005, 13.6701269],
        [100.540359600000002, 13.6691387],
        [100.5392808, 13.669176200000001],
        [100.536664700000003, 13.669373800000001],
        [100.535976399999996, 13.669415900000001],
        [100.533614600000007, 13.6704472],
        [100.531320399999998, 13.671548],
        [100.529513499999993, 13.6728551],
        [100.527898899999997, 13.6741533],
        [100.526617299999998, 13.6750709],
        [100.524025300000005, 13.677113800000001],
        [100.522832100000002, 13.678167800000001],
        [100.520504900000006, 13.6757969],
        [100.520373300000003, 13.6756166],
        [100.520410400000003, 13.675396599999999],
        [100.520539099999993, 13.6752152],
        [100.520607799999993, 13.675118299999999],
        [100.521277900000001, 13.675174],
        [100.521667199999996, 13.675091399999999],
        [100.521835499999995, 13.674905499999999],
        [100.521988199999996, 13.674296399999999],
        [100.521992600000004, 13.6735156],
        [100.521675900000005, 13.6732791],
        [100.521600100000001, 13.6729039],
        [100.521534799999998, 13.6725277],
        [100.5216207, 13.672143999999999],
        [100.521508699999998, 13.6719583],
        [100.5210948, 13.6719086],
        [100.521019800000005, 13.671727000000001],
        [100.521044799999999, 13.671590999999999],
        [100.520949799999997, 13.6714097],
        [100.5205512, 13.6712329],
        [100.520413300000001, 13.6709706],
        [100.520509200000006, 13.6708119],
        [100.520664800000006, 13.6707018],
        [100.520708499999998, 13.6705881],
        [100.520605700000004, 13.6702513],
        [100.520624900000001, 13.6701675],
        [100.520796599999997, 13.6700424],
        [100.520897599999998, 13.669749100000001],
        [100.520973999999995, 13.669503199999999],
        [100.521088899999995, 13.669321999999999],
        [100.521088399999996, 13.669124999999999],
        [100.520959700000006, 13.6691083],
        [100.520796599999997, 13.6691083],
        [100.520719299999996, 13.668999899999999],
        [100.520745099999999, 13.668833100000001],
        [100.520624900000001, 13.6687747],
        [100.520415999999997, 13.6688969],
        [100.520330900000005, 13.668857300000001],
        [100.5203384, 13.6687637],
        [100.520446000000007, 13.668617299999999],
        [100.520502399999998, 13.6684877],
        [100.520475599999997, 13.668375299999999],
        [100.520305199999996, 13.6682772],
        [100.520115000000004, 13.668264199999999],
        [100.5200155, 13.668271499999999],
        [100.519929700000006, 13.668207600000001],
        [100.519903900000003, 13.668124199999999],
        [100.519626900000006, 13.668018999999999],
        [100.519100199999997, 13.6677518],
        [100.518799999999999, 13.667583],
        [100.518724700000007, 13.667605399999999],
        [100.518598800000007, 13.6676556],
        [100.518482899999995, 13.667635300000001],
        [100.518345100000005, 13.6674586],
        [100.518127199999995, 13.6673569],
        [100.517972799999995, 13.667248499999999],
        [100.517863800000001, 13.667217300000001],
        [100.517783899999998, 13.667256800000001],
        [100.517655199999993, 13.6673653],
        [100.517595099999994, 13.667298499999999],
        [100.517577099999997, 13.6672274],
        [100.517646600000006, 13.667031700000001],
        [100.517629700000001, 13.666826199999999],
        [100.517580499999994, 13.6666457],
        [100.517603699999995, 13.6664979],
        [100.517612299999996, 13.666130900000001],
        [100.517636699999997, 13.665832399999999],
        [100.517641900000001, 13.665393399999999],
        [100.517560799999998, 13.6646214],
        [100.517485800000003, 13.6638129],
        [100.5175634, 13.6629889],
        [100.517557400000001, 13.6618417],
        [100.517534999999995, 13.6615021],
        [100.517466400000004, 13.661427099999999],
        [100.517474899999996, 13.6612186],
        [100.517380500000002, 13.6606431],
        [100.517337600000005, 13.659633899999999],
        [100.517268900000005, 13.6590668],
        [100.5170715, 13.656155999999999],
        [100.516436400000003, 13.6552969],
        [100.516573699999995, 13.651935699999999],
        [100.516808800000007, 13.645564],
        [100.516242800000001, 13.6455077],
        [100.515869800000004, 13.6454082],
        [100.515863600000003, 13.6447687],
        [100.516153099999997, 13.643728400000001],
        [100.515955700000006, 13.642810900000001],
        [100.515706800000004, 13.642102],
        [100.515749700000001, 13.641126099999999],
        [100.515380699999994, 13.640292000000001],
        [100.515063100000006, 13.639482900000001],
        [100.515405599999994, 13.6387196],
        [100.5160312, 13.6385015],
        [100.516946300000001, 13.6377393],
        [100.516772599999996, 13.637338400000001],
        [100.5168003, 13.636586899999999],
        [100.516824099999994, 13.6364543],
        [100.517141699999996, 13.6358537],
        [100.517635799999994, 13.6351481],
        [100.518042899999998, 13.634919500000001],
        [100.517954799999998, 13.63471],
        [100.517630999999994, 13.634021000000001],
        [100.5174454, 13.63363],
        [100.517201799999995, 13.6330177],
        [100.516909999999996, 13.632517200000001],
        [100.516395000000003, 13.6316664],
        [100.516045300000002, 13.6311438],
        [100.515546599999993, 13.630650599999999],
        [100.515275900000006, 13.6303947],
        [100.5151501, 13.630292600000001],
        [100.514995900000002, 13.630126499999999],
        [100.514910099999994, 13.6299481],
        [100.514913500000006, 13.629633999999999],
        [100.514942099999999, 13.629313],
        [100.5149495, 13.629093599999999],
        [100.514650000000003, 13.6289946],
        [100.514495800000006, 13.6289289],
        [100.514291900000003, 13.6287308],
        [100.514220699999996, 13.628621600000001],
        [100.514248800000004, 13.628363500000001],
        [100.514316600000001, 13.6280564],
        [100.514321499999994, 13.627767],
        [100.514314200000001, 13.6276128],
        [100.514347900000004, 13.627463300000001],
        [100.514385000000004, 13.6273798],
        [100.514446500000005, 13.627289299999999],
        [100.514678399999994, 13.6270601],
        [100.514919899999995, 13.6267668],
        [100.514984400000003, 13.6266359],
        [100.515017, 13.6265315],
        [100.515037300000003, 13.626378799999999],
        [100.515011900000005, 13.6261522],
        [100.515011700000002, 13.6259523],
        [100.515057600000006, 13.6257967],
        [100.515114600000004, 13.625603],
        [100.515034799999995, 13.6249468],
        [100.515045900000004, 13.6247772],
        [100.515022500000001, 13.624695600000001],
        [100.514958800000002, 13.6244975],
        [100.514934400000001, 13.6243351],
        [100.515029299999995, 13.6241398],
        [100.515212500000004, 13.623885599999999],
        [100.515342399999994, 13.623673999999999],
        [100.515399700000003, 13.6234264],
        [100.515418600000004, 13.623267999999999],
        [100.515474100000006, 13.623046799999999],
        [100.515503800000005, 13.622398799999999],
        [100.515534799999998, 13.622268399999999],
        [100.515723399999999, 13.621964],
        [100.515775500000004, 13.6216241],
        [100.515618900000007, 13.6212394],
        [100.515651899999995, 13.621093800000001],
        [100.515989899999994, 13.620421500000001],
        [100.516176400000006, 13.6197654],
        [100.516257899999999, 13.6191561],
        [100.516365300000004, 13.618909499999999],
        [100.516441, 13.618722099999999],
        [100.516444699999994, 13.618510300000001],
        [100.516088400000001, 13.617952300000001],
        [100.516059600000006, 13.617789399999999],
        [100.5162981, 13.6173862],
        [100.5164884, 13.6171586],
        [100.516556199999997, 13.616655400000001],
        [100.516740900000002, 13.6162264],
        [100.516688299999998, 13.615067099999999],
        [100.516616400000004, 13.614694500000001],
        [100.516869499999999, 13.614086199999999],
        [100.516862700000004, 13.613935700000001],
        [100.516671700000003, 13.613414499999999],
        [100.516445099999999, 13.613098799999999],
        [100.516374999999996, 13.6129563],
        [100.516348500000007, 13.612684099999999],
        [100.516427899999996, 13.612640000000001],
        [100.516580000000005, 13.6125068],
        [100.516786300000007, 13.6123557],
        [100.516959999999997, 13.6121728],
        [100.517260500000006, 13.612056000000001],
        [100.517639299999999, 13.6122321],
        [100.517845899999998, 13.6119518],
        [100.518253400000006, 13.611493899999999],
        [100.517887000000002, 13.611004899999999],
        [100.518429600000005, 13.6106418],
        [100.518705100000005, 13.6101627],
        [100.518849700000004, 13.6100577],
        [100.519277599999995, 13.6099116],
        [100.519534800000002, 13.6099455],
        [100.520020500000001, 13.609184600000001],
        [100.5202697, 13.609141899999999],
        [100.520385899999994, 13.608843500000001],
        [100.520393299999995, 13.608243699999999],
        [100.520685099999994, 13.606500199999999],
        [100.521016700000004, 13.6051441],
        [100.520618400000004, 13.6050697],
        [100.519946200000007, 13.6049965],
        [100.519508799999997, 13.6048487],
        [100.518859000000006, 13.6045815],
        [100.5183672, 13.604396899999999],
        [100.517985499999995, 13.6042565],
        [100.516912199999993, 13.603873699999999],
        [100.516079899999994, 13.6034878],
        [100.515023499999998, 13.6031093],
        [100.513823900000006, 13.6026235],
        [100.513637299999999, 13.6024501],
        [100.513300200000003, 13.601972200000001],
        [100.513226399999994, 13.601678400000001],
        [100.512931499999993, 13.6013339],
        [100.512762899999998, 13.600744000000001],
        [100.512442199999995, 13.599757500000001],
        [100.511927900000003, 13.5982947],
        [100.511505700000001, 13.597021],
        [100.511489900000001, 13.5968891],
        [100.511480599999999, 13.5968111],
        [100.508038999999997, 13.597548],
        [100.507685499999994, 13.5976056],
        [100.507224800000003, 13.597441399999999],
        [100.506683100000004, 13.597432],
        [100.506326999999999, 13.5975565],
        [100.505853900000005, 13.5977452],
        [100.505587500000004, 13.597625499999999],
        [100.504837199999997, 13.597689300000001],
        [100.504288099999997, 13.5975821],
        [100.503453199999996, 13.5970011],
        [100.502853799999997, 13.5965063],
        [100.502377199999998, 13.596406099999999],
        [100.502155700000003, 13.5962779],
        [100.501768200000001, 13.595985600000001],
        [100.5014161, 13.5958553],
        [100.500753700000004, 13.595666400000001],
        [100.500544099999999, 13.595461500000001],
        [100.500347599999998, 13.5953924],
        [100.499628000000001, 13.5956329],
        [100.499273200000005, 13.594870800000001],
        [100.499212200000002, 13.593841400000001],
        [100.498969000000002, 13.5926844],
        [100.499101800000005, 13.592145500000001],
        [100.498851999999999, 13.5916528],
        [100.498569099999997, 13.5910812],
        [100.497911200000004, 13.590517699999999],
        [100.496916499999998, 13.589149900000001],
        [100.496028199999998, 13.5881738],
        [100.495356299999997, 13.587895400000001],
        [100.494900799999996, 13.587729],
        [100.494646299999999, 13.5876456],
        [100.494352199999994, 13.5876961],
        [100.494043599999998, 13.5878044],
        [100.493719299999995, 13.5882573],
        [100.491916900000007, 13.588132099999999],
        [100.490848600000007, 13.5884313],
        [100.489535200000006, 13.588423799999999],
        [100.488363500000006, 13.5895087],
        [100.488198299999993, 13.589525800000001],
        [100.487797, 13.5895671],
        [100.486761900000005, 13.590268500000001],
        [100.485511900000006, 13.590866500000001],
        [100.484037000000001, 13.591571999999999],
        [100.483312600000005, 13.5920404],
        [100.482252299999999, 13.5924286],
        [100.482162799999998, 13.592509700000001],
        [100.481853599999994, 13.5927889],
        [100.481582299999999, 13.5932537],
        [100.481500600000004, 13.5944761],
        [100.481033600000004, 13.596182799999999],
        [100.480879099999996, 13.597350799999999],
        [100.480604400000004, 13.5980849],
        [100.480235300000004, 13.5982684],
        [100.479059500000005, 13.598185000000001],
        [100.478475700000004, 13.5982517],
        [100.4776174, 13.598894100000001],
        [100.477007999999998, 13.5992278],
        [100.476879299999993, 13.599644899999999],
        [100.477377099999998, 13.600937999999999],
        [100.477737599999998, 13.6016888],
        [100.477651699999996, 13.6021226],
        [100.476926399999996, 13.602968000000001],
        [100.476518799999994, 13.6024396],
        [100.475660500000004, 13.602214399999999],
        [100.475214199999996, 13.601880700000001],
        [100.474853699999997, 13.6012133],
        [100.474055399999997, 13.600312300000001],
        [100.473737900000003, 13.599811799999999],
        [100.473111299999999, 13.5992111],
        [100.472913899999995, 13.5974342],
        [100.473583399999995, 13.5939303],
        [100.469712400000006, 13.5927039],
        [100.468879799999996, 13.5946394],
        [100.468210400000004, 13.5954487],
        [100.467746899999995, 13.595782399999999],
        [100.466862800000001, 13.5969336],
        [100.4666912, 13.597918],
        [100.467051699999999, 13.5982851],
        [100.467051699999999, 13.5988524],
        [100.466107500000007, 13.6002873],
        [100.4659616, 13.6010214],
        [100.465764199999995, 13.6016888],
        [100.465815699999993, 13.603065300000001],
        [100.4659616, 13.603649300000001],
        [100.465772900000005, 13.603982999999999],
        [100.465463799999995, 13.6038161],
        [100.465146200000007, 13.6040247],
        [100.464828600000004, 13.6046336],
        [100.464287900000002, 13.6050007],
        [100.464090499999998, 13.6056931],
        [100.463618400000001, 13.606010100000001],
        [100.463060499999997, 13.606068499999999],
        [100.462588499999995, 13.6058433],
        [100.462133600000001, 13.605776499999999],
        [100.461601400000006, 13.6050925],
        [100.460931900000006, 13.6050424],
        [100.460331100000005, 13.6052927],
        [100.459927699999994, 13.6052593],
        [100.459755999999999, 13.604984],
        [100.459352600000003, 13.6048422],
        [100.458897699999994, 13.6049256],
        [100.458468600000003, 13.6048756],
        [100.458039400000004, 13.6044418],
        [100.457524399999997, 13.6044418],
        [100.457079500000006, 13.604721899999999],
        [100.456348599999998, 13.6048756],
        [100.456127199999997, 13.605018299999999],
        [100.455865399999993, 13.604991699999999],
        [100.455749499999996, 13.605235199999999],
        [100.455636200000001, 13.6052927],
        [100.454863700000004, 13.605009000000001],
        [100.454632000000004, 13.6050925],
        [100.454649099999997, 13.6052593],
        [100.454477499999996, 13.6056264],
        [100.454323000000002, 13.6056931],
        [100.454220000000007, 13.605651399999999],
        [100.454134100000005, 13.6058433],
        [100.453876699999995, 13.6060269],
        [100.453722099999993, 13.605442800000001],
        [100.453833700000004, 13.6045669],
        [100.453773600000005, 13.604008],
        [100.453490400000007, 13.6035074],
        [100.453361700000002, 13.6030069],
        [100.452760799999993, 13.602422900000001],
        [100.452417499999996, 13.601588700000001],
        [100.452142899999998, 13.5982851],
        [100.450967000000006, 13.5968085],
        [100.450941200000003, 13.5964581],
        [100.451138599999993, 13.595740599999999],
        [100.450941200000003, 13.595323499999999],
        [100.4508297, 13.5943725],
        [100.450125799999995, 13.593496500000001],
        [100.449911299999997, 13.5925121],
        [100.449421999999998, 13.592278500000001],
        [100.449267500000005, 13.5920115],
        [100.449267500000005, 13.5913608],
        [100.449267500000005, 13.59066],
        [100.448855499999993, 13.5902178],
        [100.4484092, 13.5899258],
        [100.4484092, 13.589575399999999],
        [100.447696800000003, 13.5892],
        [100.447550899999996, 13.588699399999999],
        [100.447636799999998, 13.5882322],
        [100.447439299999999, 13.5878151],
        [100.446907199999998, 13.5872978],
        [100.446649699999995, 13.587047500000001],
        [100.446649699999995, 13.586755500000001],
        [100.446735500000003, 13.5863634],
        [100.446263500000001, 13.584795],
        [100.445877199999998, 13.582917800000001],
        [100.446392200000005, 13.5809155],
        [100.446563900000001, 13.5795806],
        [100.444847300000006, 13.555884900000001],
        [100.444847300000006, 13.5527142],
        [100.447078899999994, 13.5410322],
        [100.453352899999999, 13.5041207],
        [100.453288499999999, 13.503985],
        [100.452901800000006, 13.503742600000001],
        [100.450541400000006, 13.503033200000001],
        [100.448739000000003, 13.502762000000001],
        [100.448095300000006, 13.501280599999999],
        [100.445069700000005, 13.500153900000001],
        [100.442044199999998, 13.499340200000001],
        [100.441808199999997, 13.498630800000001],
        [100.440220299999993, 13.498401299999999],
        [100.438911399999995, 13.498964600000001],
        [100.438396400000002, 13.4992567],
        [100.438267699999997, 13.4992985],
        [100.437795600000001, 13.4981092],
        [100.437044599999993, 13.4982343],
        [100.437151900000003, 13.4968155],
        [100.436199700000003, 13.4968091],
        [100.436057500000004, 13.4975041],
        [100.435413800000006, 13.497545799999999],
        [100.435392300000004, 13.498025699999999],
        [100.435349900000006, 13.498132500000001],
        [100.434696400000007, 13.498089],
        [100.4348344, 13.4969407],
        [100.434512600000005, 13.49644],
        [100.433225100000001, 13.496252200000001],
        [100.430554299999997, 13.4962252],
        [100.427023800000001, 13.496189599999999],
        [100.425672000000006, 13.497817],
        [100.425178500000001, 13.4970242],
        [100.424470400000004, 13.4975041],
        [100.423740800000004, 13.496460799999999],
        [100.422088599999995, 13.4959183],
        [100.421763799999994, 13.495973299999999],
        [100.421520900000004, 13.4949561],
        [100.420344099999994, 13.4949016],
        [100.419484800000006, 13.495337599999999],
        [100.417819600000001, 13.4952182],
        [100.416757500000003, 13.4944112],
        [100.415935599999997, 13.4950469],
        [100.415879599999997, 13.4958825],
        [100.415561999999994, 13.495937],
        [100.414519900000002, 13.495937],
        [100.414535999999998, 13.4953731],
        [100.414086299999994, 13.4953194],
        [100.414385199999998, 13.4943022],
        [100.413581899999997, 13.494229600000001],
        [100.4134885, 13.495228600000001],
        [100.413320400000003, 13.495210399999999],
        [100.412610599999994, 13.495010600000001],
        [100.412143599999993, 13.4948835],
        [100.412517199999996, 13.494229600000001],
        [100.412143599999993, 13.4941569],
        [100.411153499999998, 13.4946655],
        [100.410742600000006, 13.495210399999999],
        [100.410032700000002, 13.4947745],
        [100.409858200000002, 13.4956443],
        [100.409772399999994, 13.4958008],
        [100.409210799999997, 13.495628200000001],
        [100.409416300000004, 13.4941932],
        [100.409136099999998, 13.4934303],
        [100.409004600000003, 13.493420499999999],
        [100.405536900000001, 13.507818800000001],
        [100.406395200000006, 13.5083196],
        [100.406395200000006, 13.535357899999999],
        [100.417038199999993, 13.535357899999999],
        [100.416866499999998, 13.5393633],
        [100.419613100000007, 13.5425342],
        [100.420299799999995, 13.545371299999999],
        [100.4175532, 13.549376499999999],
        [100.4127467, 13.553214799999999],
        [100.414635000000004, 13.556552399999999],
        [100.413261700000007, 13.5607243],
        [100.409656799999993, 13.559889999999999],
        [100.407253499999996, 13.559556199999999],
        [100.4043353, 13.559889999999999],
        [100.402962000000002, 13.559055600000001],
        [100.399872099999996, 13.558721800000001],
        [100.398327100000003, 13.5572199],
        [100.394722200000004, 13.5572199],
        [100.392833999999993, 13.56256],
        [100.390602400000006, 13.5675662],
        [100.389229099999994, 13.5687342],
        [100.3864825, 13.5697355],
        [100.385109200000002, 13.572071599999999],
        [100.383907600000001, 13.5767439],
        [100.385179899999997, 13.583744299999999],
        [100.385463099999996, 13.583986299999999],
        [100.385523199999994, 13.584236600000001],
        [100.386003799999997, 13.584954099999999],
        [100.3859523, 13.5850375],
        [100.385677700000002, 13.5850709],
        [100.385677700000002, 13.585221000000001],
        [100.385918000000004, 13.5854046],
        [100.385634800000005, 13.585513000000001],
        [100.385317200000003, 13.585387900000001],
        [100.385231399999995, 13.585571399999999],
        [100.385720599999999, 13.585805000000001],
        [100.385420199999999, 13.5860386],
        [100.385076900000001, 13.5860553],
        [100.384862299999995, 13.586180499999999],
        [100.385034000000005, 13.586322300000001],
        [100.385334400000005, 13.586389],
        [100.385351499999999, 13.586472499999999],
        [100.384922399999994, 13.5868479],
        [100.384836500000006, 13.587123200000001],
        [100.384973900000006, 13.58724],
        [100.385162699999995, 13.587265],
        [100.385059699999999, 13.587473599999999],
        [100.384716400000002, 13.587490300000001],
        [100.384544700000006, 13.5874319],
        [100.384218599999997, 13.587657200000001],
        [100.384197, 13.587709800000001],
        [100.384149899999997, 13.587823999999999],
        [100.384476100000001, 13.588241200000001],
        [100.384416000000002, 13.5889086],
        [100.384192799999994, 13.589551],
        [100.383849499999997, 13.589601],
        [100.383617700000002, 13.5895343],
        [100.383188599999997, 13.589884700000001],
        [100.383077, 13.590135],
        [100.382527699999997, 13.590326900000001],
        [100.382201499999994, 13.590602199999999],
        [100.382072800000003, 13.590869100000001],
        [100.381686599999995, 13.5909692],
        [100.381514899999999, 13.5912696],
        [100.381326099999995, 13.5913697],
        [100.3810857, 13.5913697],
        [100.380982700000004, 13.5917201],
        [100.380656599999995, 13.5918285],
        [100.379987099999994, 13.5923041],
        [100.379987099999994, 13.5927212],
        [100.379755399999993, 13.592829699999999],
        [100.379583699999998, 13.593105],
        [100.379300499999999, 13.593355300000001],
        [100.378922799999998, 13.593371899999999],
        [100.378485100000006, 13.594189500000001],
        [100.378536600000004, 13.5943731],
        [100.378811200000001, 13.5944231],
        [100.378854099999998, 13.59459],
        [100.378742599999995, 13.594706800000001],
        [100.378914199999997, 13.594773500000001],
        [100.378742599999995, 13.5950238],
        [100.378982899999997, 13.595399199999999],
        [100.379137400000005, 13.5960082],
        [100.378879900000001, 13.596258499999999],
        [100.379085900000007, 13.596400300000001],
        [100.378982899999997, 13.596709000000001],
        [100.379068700000005, 13.5969426],
        [100.378897100000003, 13.5971511],
        [100.379068700000005, 13.5973764],
        [100.378794099999993, 13.5977184],
        [100.378725399999993, 13.5981606],
        [100.378751100000002, 13.598277400000001],
        [100.3786396, 13.598661099999999],
        [100.378579500000001, 13.5990115],
        [100.378519400000002, 13.600012599999999],
        [100.378519400000002, 13.6004714],
        [100.378450700000002, 13.600680000000001],
        [100.378536600000004, 13.6011638],
        [100.378347700000006, 13.6013307],
        [100.378321999999997, 13.6014725],
        [100.378450700000002, 13.6016811],
        [100.378450700000002, 13.6020982],
        [100.377163300000007, 13.6064528],
        [100.3773178, 13.6067365],
        [100.3771804, 13.609063900000001],
        [100.376837100000003, 13.609939799999999],
        [100.376837100000003, 13.610190100000001],
        [100.376639699999998, 13.6102902],
        [100.376665500000001, 13.610757400000001],
        [100.376708399999998, 13.6111494],
        [100.376725500000006, 13.6112913],
        [100.376622499999996, 13.611624900000001],
        [100.376622499999996, 13.611900199999999],
        [100.376493800000006, 13.612008700000001],
        [100.376296400000001, 13.611958599999999],
        [100.375807199999997, 13.6132933],
        [100.375506700000003, 13.6145947],
        [100.375054199999994, 13.6179787],
        [100.375536600000004, 13.618801400000001],
        [100.374950999999996, 13.619506100000001],
        [100.373915999999994, 13.620699699999999],
        [100.373054600000003, 13.6209454],
        [100.372079600000006, 13.6209098],
        [100.371857399999996, 13.622017100000001],
        [100.371228700000003, 13.6243225],
        [100.371219699999997, 13.624576299999999],
        [100.371471400000004, 13.624870599999999],
        [100.371301799999998, 13.6252318],
        [100.370199799999995, 13.626333199999999],
        [100.369263000000004, 13.627709100000001],
        [100.368720400000001, 13.6291741],
        [100.368622900000005, 13.6301421],
        [100.367516300000005, 13.6301057],
        [100.367281000000006, 13.6302041],
        [100.366374500000006, 13.632042],
        [100.366825800000001, 13.632225200000001],
        [100.365964599999998, 13.6336905],
        [100.365157800000006, 13.632998300000001],
        [100.364845000000003, 13.633307200000001],
        [100.360054700000006, 13.6334114],
        [100.360076599999999, 13.6330346],
        [100.359407899999994, 13.6329058],
        [100.358926299999993, 13.637448600000001],
        [100.358202899999995, 13.6371407],
        [100.357872700000001, 13.637025700000001],
        [100.356189799999996, 13.6366858],
        [100.355247700000007, 13.6365441],
        [100.354491499999995, 13.6391502],
        [100.354270999999997, 13.639909899999999],
        [100.3516458, 13.6395404],
        [100.351567799999998, 13.640144400000001],
        [100.350352999999998, 13.640193399999999],
        [100.350312099999996, 13.6407744],
        [100.348411799999994, 13.6407101],
        [100.348617500000003, 13.6437811],
        [100.345980100000006, 13.6436008],
        [100.345008699999994, 13.6480385],
        [100.344953799999999, 13.648770799999999],
        [100.346215900000004, 13.649117],
        [100.346775800000003, 13.652366499999999],
        [100.346723499999996, 13.6553193],
        [100.346284999999995, 13.6551913],
        [100.345430199999996, 13.654774400000001],
        [100.345113100000006, 13.6547178],
        [100.344311099999999, 13.654685799999999],
        [100.342570800000004, 13.6548623],
        [100.341759499999995, 13.654923500000001],
        [100.339368399999998, 13.654965199999999],
        [100.3394014, 13.6562584],
        [100.339788499999997, 13.657835],
        [100.339930800000005, 13.6580618],
        [100.340614000000002, 13.658515400000001],
        [100.340841699999999, 13.6588916],
        [100.340904300000005, 13.6595388],
        [100.341035199999993, 13.660163900000001],
        [100.341279999999998, 13.660855400000001],
        [100.341086500000003, 13.661812400000001],
        [100.340733499999999, 13.662796999999999],
        [100.340723400000002, 13.6628442],
        [100.340567500000006, 13.663566400000001],
        [100.340300900000003, 13.664456599999999],
        [100.340124700000004, 13.665303099999999],
        [100.340124700000004, 13.6664844],
        [100.340007999999997, 13.666828600000001],
        [100.339307500000004, 13.6677087],
        [100.339263299999999, 13.6682367],
        [100.3391989, 13.6685379],
        [100.338816399999999, 13.6694493],
        [100.338771199999996, 13.669667199999999],
        [100.338372699999994, 13.6730137],
        [100.338236100000003, 13.6750162],
        [100.337590000000006, 13.6773767],
        [100.336043500000002, 13.6769534],
        [100.335854699999999, 13.6775327],
        [100.335268299999996, 13.6784824],
        [100.335072999999994, 13.678812199999999],
        [100.334157599999998, 13.680339699999999],
        [100.333542899999998, 13.681371199999999],
        [100.333502100000004, 13.6815943],
        [100.333501999999996, 13.6817796],
        [100.333563999999996, 13.6822301],
        [100.3338021, 13.6832779],
        [100.333814500000003, 13.683614499999999],
        [100.333644899999996, 13.6842796],
        [100.333335700000006, 13.6852518],
        [100.332986899999995, 13.6864694],
        [100.332711799999998, 13.6872291],
        [100.332405300000005, 13.688421],
        [100.332185100000004, 13.690056200000001],
        [100.332092700000004, 13.6905786],
        [100.332011300000005, 13.6913514],
        [100.332036200000005, 13.6919415],
        [100.332067699999996, 13.692167100000001],
        [100.332075200000006, 13.692723300000001],
        [100.331951599999996, 13.692939600000001],
        [100.331926300000006, 13.6929839],
        [100.331803699999995, 13.693364499999999],
        [100.331441499999997, 13.6958532],
        [100.3312612, 13.697297000000001],
        [100.331190399999997, 13.697637200000001],
        [100.331064299999994, 13.6979395],
        [100.330719700000003, 13.6987097],
        [100.330267800000001, 13.699859999999999],
        [100.330275799999995, 13.7006575],
        [100.330286599999994, 13.7016086],
        [100.330163200000001, 13.702762999999999],
        [100.330388499999998, 13.702906199999999],
        [100.331600800000004, 13.704292499999999],
        [100.332339000000005, 13.705486000000001],
        [100.333435399999999, 13.7076593],
        [100.333532000000005, 13.708326400000001],
        [100.333532000000005, 13.7089309],
        [100.333446199999997, 13.7095042],
        [100.333178000000004, 13.710140000000001],
        [100.332956899999999, 13.7111026],
        [100.332311099999998, 13.712467999999999],
        [100.334068400000007, 13.7130689],
        [100.335280299999994, 13.711126500000001],
        [100.335870900000003, 13.711307400000001],
        [100.336595099999997, 13.7115841],
        [100.338783199999995, 13.712779100000001],
        [100.338263400000002, 13.7135275],
        [100.338183999999998, 13.713817199999999],
        [100.337466800000001, 13.7148205],
        [100.336525300000005, 13.715111800000001],
        [100.336128400000007, 13.7154662],
        [100.336639599999998, 13.7166663],
        [100.335473899999997, 13.7179468],
        [100.334675700000005, 13.7183382],
        [100.332994499999998, 13.7210923],
        [100.335821499999994, 13.7223483],
        [100.335763600000007, 13.722918399999999],
        [100.336215800000005, 13.7230075],
        [100.336171300000004, 13.725857599999999],
        [100.336718500000003, 13.726003499999999],
        [100.336678800000001, 13.7262369],
        [100.336656099999999, 13.726284],
        [100.335868199999993, 13.727913900000001],
        [100.336015200000006, 13.7279582],
        [100.335586599999999, 13.7291437],
        [100.334768999999994, 13.729915500000001],
        [100.334519099999994, 13.7304017],
        [100.335521099999994, 13.7309056],
        [100.335280800000007, 13.7313189],
        [100.335012599999999, 13.7322256],
        [100.335956699999997, 13.732496599999999],
        [100.336686299999997, 13.732517400000001],
        [100.337565900000001, 13.7327259],
        [100.337490799999998, 13.733945200000001],
        [100.337415699999994, 13.7350917],
        [100.337517099999999, 13.7359708],
        [100.337478399999995, 13.7360817],
        [100.337051000000002, 13.7373011],
        [100.337017700000004, 13.737603399999999],
        [100.336260199999998, 13.7400155],
        [100.336214600000005, 13.740381299999999],
        [100.335893900000002, 13.740391199999999],
        [100.335749000000007, 13.7412729],
        [100.336115899999996, 13.7413709],
        [100.335763499999999, 13.7430748],
        [100.335269999999994, 13.745565600000001],
        [100.336004399999993, 13.745822499999999],
        [100.335602600000001, 13.7472122],
        [100.335581099999999, 13.7478791],
        [100.335584299999994, 13.7480321],
        [100.335623999999996, 13.749984299999999],
        [100.335441599999996, 13.753392],
        [100.334540399999995, 13.7532461],
        [100.333977099999998, 13.755299600000001],
        [100.332794800000002, 13.7553658],
        [100.3330196, 13.7601782],
        [100.331958, 13.7602001],
        [100.331708000000006, 13.761207799999999],
        [100.330227399999998, 13.7612495],
        [100.330345399999999, 13.763844300000001],
        [100.330055799999997, 13.765678299999999],
        [100.329993000000002, 13.7660993],
        [100.330243499999995, 13.7694975],
        [100.330141600000005, 13.77117],
        [100.330206000000004, 13.7729727],
        [100.330460200000005, 13.7736573],
        [100.330798200000004, 13.7757273],
        [100.332462199999995, 13.7797489],
        [100.332351200000005, 13.7812242],
        [100.333177000000006, 13.7812755],
        [100.333145799999997, 13.7820775],
        [100.333311399999999, 13.7821032],
        [100.333282600000004, 13.782749000000001],
        [100.333112799999995, 13.7831738],
        [100.333877799999996, 13.783394700000001],
        [100.331224500000005, 13.7929683],
        [100.330945600000007, 13.7957816],
        [100.331775899999997, 13.7959917],
        [100.328945399999995, 13.801918300000001],
        [100.327877099999995, 13.804184299999999]
    ]

export const thailand = []

for (let index = 0; index < latLong.length; index++) {
    const position = latLong[index].reverse();
    thailand.push(position)
}

export default {
    thailand,
}