export const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingTop: "32px",
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    grid: {
        paddingBottom: "8px",
    },
    gridItem: {
        padding: "8px !important",
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
        margin: "0 auto",
        width: "100%",
        height: "36px",
        borderRadius: "2px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)",
        '&:hover': {
            backgroundColor: theme.palette.darkBlue.main
        }
    },
    avatar: {
        zIndex: "1000",
        width: "80px",
        height: "80px",
        boxShadow: "1px 2px 3px -1px rgba(0,0,0,.7)",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "3px",
        padding: "15px",
        marginTop: "-56px",
        marginRight: "15px",
        float: "left",
    },
    icon: {
        fontSize: "1.5em",
        width: "auto"
    },
    title: {
        fontWeight: "700",
        color: theme.palette.black.main,
        borderBottom: "3px solid",
        borderBottomColor: theme.palette.primary.main,
        marginTop: '-24px'
    },
    table: {
        paddingTop: "8px",
        '& th': {
            fontSize: "0.775rem",
            padding: "10px 20px 10px 12px"
        },
        '& td': {
            fontSize: "0.775rem",
            padding: "10px 20px 10px 12px"
        },
    },
    alertsTable: {
        paddingTop: "8px",
        '& th': {
            fontSize: "0.775rem",
        },
        '& td': {
            fontSize: "0.775rem",
        },
    },
    iconCritical: {
        margin: "0px 8px",
        color: theme.palette.error.main
    },
    iconMajor: {
        margin: "0px 8px",
        color: theme.palette.warning.main
    },
    iconMinor: {
        margin: "0px 8px",
        color: theme.palette.minor.main
    },
    alertButtonContainer: {
        paddingTop: "24px"
    },
    card: {
        overflow: 'visible'
    },
    tableCard: {
        paddingBottom: '8px !important',
        paddingTop: '42px'
    },
    td: {
        textAlign: 'center !important'
    },
    th: {
        display: 'flex  !important',
        justifyContent: 'center !important'
    },
    fleetTable: {
        paddingTop: '28px !important',
        boxShadow: "none !important"
    }
})