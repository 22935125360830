export const styles = theme => ({
    container: {
        marginTop: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    table: {
        paddingTop: "8px",
        '& th': {
            fontSize: "0.775rem",
            padding: "8px 0px 8px 0px",
            borderBottom: "none"
        },
        '& td': {
            fontSize: "0.775rem",
            padding: "8px 0px 8px 0px",
            borderBottom: "none"
        },
    },
    valueText: {
        fontSize: "2em"
    },
    chipUp: {
        backgroundColor: theme.palette.primary.transparent,
        color: theme.palette.primary.main,
    },
    chipDown: {
        backgroundColor: theme.palette.error.transparent,
        color: theme.palette.error.main,
    }
}); 