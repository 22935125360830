import React, { Component } from 'react';
import { Button, Icon } from '@material-ui/core/';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from "react-router-dom";
import classNames from 'classnames';

import { styles } from './caseSwitcherStyle'

class caseSwitcher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: this.props.theme,
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.buttonContainer}>
                <Link to="/">
                    <Button
                        className={classes.button}
                    >
                        SWITCH CASE <Icon className={classNames(classes.icon, "fa fa-exchange-alt")} />
                    </Button>
                </Link>
            </div>
        );
    }
}

export default withStyles(styles)(caseSwitcher);