export const styles = theme => ({
    item: {
        height: "80vh",
        display: "grid",
        alignContent: "center",
        margin: "30px 0px"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "inherit",
        backgroundSize: "cover !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundBlendMode: "multiply"
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
        margin: "0 auto",
        width: "100%",
        maxWidth: "200px",
        height: "36px",
        borderRadius: "2px",
        top: "16px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)",
        '&:hover': {
            backgroundColor: theme.palette.darkBlue.main
        }
    },
    buttonDisabled: {
        backgroundColor: "#e7e7e7",
        color: theme.palette.white.main,
        margin: "0 auto",
        width: "100%",
        maxWidth: "200px",
        height: "36px",
        borderRadius: "2px",
        top: "16px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)",
        '&:hover': {
            backgroundColor: theme.palette.darkBlue.main
        }
    },
    link: {
        textDecoration: "none",
        margin: "0 auto",
        width: "100px",
    },
    logo: {
        width: "100px",
        height: "100px",
        paddingBottom: "24px",
        margin: "0 auto",
    },
    text: {
        color: theme.palette.white.main,
        padding: "16px 0px",
        maxWidth: "300px",
    },
    span: {
        fontWeight: "100",
        color: theme.palette.white.main,
        fontSize: "0.9rem",
    }
})
