import * as React from "react"
import { Map, TileLayer, Polygon } from "react-leaflet"
import { DriftMarker } from "leaflet-drift-marker"
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import { withStyles } from '@material-ui/core/styles'
import "react-leaflet-markercluster/dist/styles.min.css"
import { thailand } from '../geoFences/thailand'
import "./style.css"
import { styles } from './sortableMapViewStyle'
import './sortableMap.css'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

let iconOptions = {
    iconSize: [52, 42],
    iconAnchor: [21, 42],
    popupAnchor: [-3, -76],
    shadowSize: null,
    shadowAnchor: null
}

let greyIconOptions = {
    iconSize: [52, 42],
    iconAnchor: [21, 42],
    popupAnchor: [-3, -76],
    shadowSize: null,
    shadowAnchor: null,
    className: 'greyIcon'
}

let newIcon = L.Icon.extend({
    options: iconOptions
})

let newGreyIcon = L.Icon.extend({
    options: greyIconOptions
})


L.icon = function (options) {
    return new L.Icon(options)
}

class OverviewMap extends React.Component {
    constructor(props) {
        super(props)
        const { units, height } = this.props
        this.state = {
            height: height,
            center: [13.756717, 100.723186],
            zoom: 11,
            newZoom: 11,
            markersToMove: units,
            outOffBoundsChecked: true,
            insideBoundsChecked: true,
            alertsChecked: true,
            healthyChecked: true,
            unitsAmount: 25,
            pageIncrement: 25,
        }
    }

    setCurrentSelectedMarker(marker) {
        this.props.setCurrentMarker(marker)
    }

    expand() {
        this.props.expandMarkerInfo()
    }

    centerMapToMarker(marker) {
        this.setState({
            center: marker.position,
            zoom: this.state.newZoom
        })
    }

    getCurrentZoom = () => {
        this.setState({
            newZoom: this.map.leafletElement._zoom
        })
    }

    setMarkerIcon(marker) {
        let icon

        if (marker.locked) {
            icon = new newIcon({ iconUrl: require('../../../../assets/images/icons/mopedLocked.png') })
        } else if (marker.outOffBounds) {
            icon = new newIcon({ iconUrl: require('../../../../assets/images/icons/mopedRed.png') })
        } else if (marker.batteryLevel < 11) {
            icon = new newIcon({ iconUrl: require('../../../../assets/images/icons/battery.png') })
        } else if (marker.alerts.length > 0) {
            icon = new newIcon({ iconUrl: require('../../../../assets/images/icons/warning.png') })
        } else if (!marker.active) { 
            icon = new newIcon({ iconUrl: require('../../../../assets/images/icons/mopedGrey.png') })
        } else {
            icon = new newIcon({ iconUrl: require('../../../../assets/images/icons/mopedBlue.png') })
        }

        if (this.props.currentSelectedMarker) {
            if (this.props.currentSelectedMarker.registrationNumber !== marker.registrationNumber) {
                if (marker.locked) {
                    icon = new newGreyIcon({ iconUrl: require('../../../../assets/images/icons/mopedLocked.png') })
                } else if (marker.outOffBounds) {
                    icon = new newGreyIcon({ iconUrl: require('../../../../assets/images/icons/mopedRed.png') })
                } else if (marker.batteryLevel < 11) {
                    icon = new newGreyIcon({ iconUrl: require('../../../../assets/images/icons/battery.png') })
                } else if (marker.alerts.length > 0) {
                    icon = new newGreyIcon({ iconUrl: require('../../../../assets/images/icons/warning.png') })
                } else if (!marker.active) { 
                    icon = new newIcon({ iconUrl: require('../../../../assets/images/icons/mopedGrey.png') })
                } else {
                    icon = new newGreyIcon({ iconUrl: require('../../../../assets/images/icons/mopedBlue.png') });
                }
            }
        }
        return icon
    }

    render() {
        const { units, classes } = this.props
        const { center, zoom, height } = this.state

        return (
            <div className={classes.root}>
                <Map
                    maxBounds={L.latLngBounds([-90, -180], [90, 180])}
                    ref={(ref) => { this.map = ref }}
                    center={center}
                    zoom={zoom}
                    className={classes.mapBox}
                    maxZoom="18"
                    minZoom="2"
                    style={{ height: height }}
                    onZoomEnd={this.getCurrentZoom}
                    zoomControl={false}
                >
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        noWrap={true}
                    />
                    {units.map((marker, i) => {
                        return (
                            <DriftMarker
                                key={marker.registrationNumber}
                                icon={this.setMarkerIcon(marker)}
                                position={marker.position}
                                duration={75500}
                                onClick={() => {
                                    this.setCurrentSelectedMarker(marker)
                                    this.expand()
                                    this.centerMapToMarker(marker)
                                }}
                            ></DriftMarker>
                        )
                    })}
                    <Polygon positions={thailand} color="grey" />
                </Map>
            </div>
        )
    }
}

export default withStyles(styles)(OverviewMap)