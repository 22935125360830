export const FOUND_BAD_WORD = "FOUND_BAD_WORD";
export const SWITCH_THEME = "SWITCH_THEME";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_CURRENT_MARKER = "SET_CURRENT_MARKER";
export const EXPAND_MARKER_INFO = "EXPAND_MARKER_INFO";
export const TOGGLE_MARKER_INFO = "TOGGLE_MARKER_INFO";
export const SWITCH_CASE_1 = "SWITCH_CASE_1";
export const SWITCH_CASE_2 = "SWITCH_CASE_2";
export const SWITCH_CASE_3 = "SWITCH_CASE_3";
export const SWITCH_POLYGON = "SWITCH_POLYGON";