import * as React from "react";
import { Slide } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar'
import { Close } from '@material-ui/icons';

function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
}

class AlertSnack extends React.Component {

    getSnackClass() {
        const { alert } = this.props
        if (!alert) {
            if (this.level) {
                return this.level
            } else {
                return 'minor'
            }
        } else {
            return this.getClassText(alert.level)
        }
    }

    getClassText(level) {
        if (level === 1) {
            return 'minor'
        }
        if (level === 2) {
            return 'major'
        }
        if (level === 3) {
            return 'critical'
        }
    }

    componentWillReceiveProps() {
        if (this.props.alert) {
            this.level = this.getClassText(this.props.alert.level)
            this.text = this.props.alert.message
        }
    }

    render() {
        const { snack, closeSnackBar, alert } = this.props
        return (
            <Snackbar
                className="singleUnitSnack"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => closeSnackBar()}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    'className': this.getSnackClass()
                }}
                TransitionComponent={SlideTransition}
                message={
                    <span id="message-id">
                        {alert ? alert.message : this.text}
                        <Close
                            onClick={() => closeSnackBar()}
                            className="closeIcon"
                        />
                    </span>
                }
            />
        )
    }
}

export default AlertSnack;