import React from 'react'
import { Grid, Container, Paper, CardContent, Typography, List, ListItem, Table, TableBody, TableRow, TableCell, Chip } from '@material-ui/core'
import CardHeader1 from '../components/commons/cardHeader1'
import CardFooter from '../components/commons/cardFooter'
import AreaChartComponent from '../components/charts/areaChartComponent'
import BarChartComponent from '../components/charts/barChartComponent'
import { withStyles } from '@material-ui/core/styles'
import { styles } from './dashboardStyle'

// Dashboard barchart data
const BarChartSeries = [{
    name: '2018',
    data: [1000, 800, 1700, 2100, 1200, 4800, 5900, 3100, 6800, 5500, 6800, 1300]
},
{
    name: '2019',
    data: [1200, 1000, 1900, 2500, 1300, 5200, 6000, 3350, 6900, 5950, 0, 0]
}]
// Dashboard BarChart categories
const BarChartCategories = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
    ]

const AreaChartSeries = [{
    name: 'Incomes',
    data: [3100, 4000, 2800, 5100, 4200, 10900, 10000, 4200, 10900, 10000, 4500, 6500]
}, 
{
    name: 'Expenses',
    data: [1100, 3200, 4500, 3200, 3400, 5200, 4100, 1100, 3200, 4500, 3200, 5700]
}]

function createData(name, value, result, trending) {
    return { name, value, result, trending };
}

class DashboardMain extends React.Component {
    state = {  }
    render() { 
        const summary = [
            createData('Revenue', "5959 €", "+ 15 %", true),
            createData('Avrage Vehicle Usage', '65 %', "- 7 %", false),
            createData('Error tickets', '12', "- 57 %", true),
            createData('New users', '254', "+ 15 %", true),
        ];

        const { classes, theme } = this.props
        return ( 
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={6}>
                        <Paper className={classes.paper}>
                            <CardHeader1 className={classes.header}>
                                SUMMARY
                            </CardHeader1>
                            <CardContent>
                                <List>
                                    <ListItem>
                                        <Typography>
                                            <b>Last 30 days statistics</b>
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                    <Table className={classes.table}>
                                        <TableBody>
                                            {summary.map(row => (
                                                <TableRow key={row.name}>
                                                    <TableCell component="th" scope="row">
                                                        <Typography className={classes.valueText}>
                                                            {row.value}
                                                        </Typography>
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Chip className={row.trending ? classes.chipUp : classes.chipDown} variant="outlined" label={row.result} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    </ListItem>
                                </List>
                            </CardContent>
                            <CardFooter>
                                Updated 2 Hours ago
                            </CardFooter>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <AreaChartComponent
                            height="355px"
                            showGrid={true}
                            series={AreaChartSeries}
                            fillColors={[theme.palette.white.main, theme.palette.grey.main]}
                            colors={[theme.palette.white.main, theme.palette.grey.main]}
                            strokeColors={[theme.palette.grey.main, theme.palette.white.main]}
                            markerColors={[theme.palette.white.main, theme.palette.grey.main]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BarChartComponent
                            height="340px"
                            title={"Revenue Comparison"}
                            series={BarChartSeries}
                            categories={BarChartCategories}
                            colors={[theme.palette.grey.main, theme.palette.white.main]}
                            strokeColors={[theme.palette.white.main, theme.palette.grey.main]}
                            suffix="€"
                        />
                    </Grid>
                </Grid>
            </>
        );
    }
}
 
export default withStyles(styles) (DashboardMain);