import React from 'react';
import Button from '@material-ui/core/Button'
import './interact.css'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { styles } from './interactStyle'


class interact extends React.Component {
    constructor(props) {
        super(props);

        // TODO: Add local environment
        const url = "wss://fc66bt3qu3.execute-api.eu-west-1.amazonaws.com/Prod"
        this.connection = new WebSocket(url)

        this.connection.onerror = (error) => {
            console.log(`WebSocket error: ${error}`)
        }
    }

    componentWillUnmount() {
        this.connection.send('close')
    }

    sendTriggers(value) {
        this.connection.send(JSON.stringify({
            action: "sendmessage",
            data: {
                route: "singleMovementUpdate",
                value: value
            }
        }))
    }

    triggerReset() {
        this.sendTriggers("reset6")
        setTimeout(() => this.sendTriggers("reset7"), 2600)
    }

    triggerNewPosition() {
        this.sendTriggers("trigger1")
        setTimeout(() => this.sendTriggers("trigger2"), 2600)
        setTimeout(() => this.sendTriggers("trigger3"), 5200)
        setTimeout(() => this.sendTriggers("trigger4"), 7800)
        setTimeout(() => this.sendTriggers("trigger5"), 10400)
        setTimeout(() => this.sendTriggers("trigger6"), 13000)
    }

    triggerCharge() {
        this.connection.send(JSON.stringify({
            action: "sendmessage",
            data: {
                route: "chargeBattery"
            }
        }))
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="buttonHolder">
                <Container maxWidth={false} className={classes.container}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                    >
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <CardContent>
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    <Icon className={classNames(classes.icon, "fas fa-angle-double-right")} />
                                </Avatar>
                                <Typography
                                    align="right"
                                    className={classes.title}
                                >
                                    MOVE
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Click the button to trigger Movement on the map on singleView page.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" className={classes.button} variant="contained" onClick={() => this.triggerNewPosition()}>
                                    TRIGGER MOVEMENT
                                </Button>
                            </CardActions>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <CardContent>
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    <Icon className={classNames(classes.icon, "fa fa-redo")} />
                                </Avatar>
                                <Typography
                                    align="right"
                                    className={classes.title}
                                >
                                    RESET
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Click the button to reset the vehicle on the map on singleView page.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" className={classes.button} variant="contained" onClick={() => this.triggerReset()}>
                                    RESET MOVEMENT
                                </Button>
                            </CardActions>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <CardContent>
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    <Icon className={classNames(classes.icon, "fas fa-battery-full")} />
                                </Avatar>
                                <Typography
                                    align="right"
                                    className={classes.title}
                                >
                                    CHARGE
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Click the button to trigger charging on the singleView page.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" className={classes.button} variant="contained" onClick={() => this.triggerCharge()}>
                                    TRIGGER CHARGE
                                </Button>
                            </CardActions>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
{/*                 <Button color="primary" variant="contained" onClick={() => this.triggerNewPosition()}>Trigger Movement</Button>
                <Button color="primary" variant="contained" onClick={() => this.triggerReset()}>Reset Movement</Button>
                <Button className="charge" color="primary" variant="contained" onClick={() => this.triggerCharge()}>Charge Vehicle</Button> */}
            </div>
        )
    }
}

export default withStyles(styles)(interact)