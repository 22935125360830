import mopedBg from '../../../assets/images/mopedBG.jpg'
const drawerWidth = 240;

export const styles = theme => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: theme.palette.body.main,
        '& .MuiIcon-root': {
            width: "auto"
        }
    },
    appBar: {
        width: "calc(100% - 73px)",
        marginLeft: "73px",
        zIndex: theme.zIndex.drawer - 1,
        backgroundColor: theme.palette.white.main,
        color: theme.palette.black.main,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    breadcrumbs: {
        display: "flex",
        width: "100%"
    },
    menuButton: {
        color: theme.palette.white.main,
        position: "absolute",
        bottom: "5px",
        right: "12.5px",
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        border: "none",
        width: drawerWidth,
        minHeight: "100vh",
        overflowX: "hidden",
        background: "url(" + mopedBg + ") rgba(0,0,0,.7)",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    drawerClose: {
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        border: "none",
        minWidth: "73px",
        minHeight: "100vh",
        background: "url(" + mopedBg + ") rgba(0,0,0,.7)",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        zIndex: "100",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    icons: {
        color: theme.palette.white.main,
        paddingLeft: "6.5px",
    },
    logo: {
        width: "58px",
        margin: "0 auto",
    },
    text: {
        color: theme.palette.white.main,
        textDecoration: "none"
    },
    link: {
        textDecoration: "none"
    },
    logotype: {
        margin: "0 auto",
        color: theme.palette.white.main,
    },
});