import appReducer from './app/appReducer';
import themeReducer from './app/commonReducers/themeReducer';
import loginReducer from './app/commonReducers/loginReducer';
import mapReducer from './app/commonReducers/mapReducer';
import currentMarker from './app/commonReducers/currentMarkerReducer';
import expandMarkerInfoReducer from './app/commonReducers/expandMarkerInfoReducer';
import fleetReducer from './app/fleet/fleetReducer';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    appReducer,
    loginReducer,
    currentMarker,
    expandMarkerInfoReducer,
    fleetReducer,
    themeReducer,
    mapReducer
})

export default createRootReducer