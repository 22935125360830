import React, { Component } from 'react'
import clsx from 'clsx'
import {
    Drawer, Container, AppBar, Icon, Toolbar, List, Grid,
    Divider, MenuItem, Typography, IconButton, CssBaseline,
    ListItemIcon, ListItemText, Breadcrumbs
} from '@material-ui/core/'

import { withStyles } from '@material-ui/core/styles'
import BadgeComponent from '../badge/badgeComponent'
import DrawerRight from './drawerRight'
import '../navigation/navigation.css'

import DashboardContainer from '../../dashboard/dashboardContainer'
import FleetContainer from '../../fleet/fleetContainer'
import SingleUnitContainer from '../../singleUnit/singleUnitContainer'
import LandingPageContainer from '../../landingPage/landingPageContainer'
import SettingsView from '../../settings/settingsView'
import { styles } from './navigationSmallStyle'
import * as randomMovement from "../../../randomMovement.js"
import { Route, Link, NavLink, Switch } from "react-router-dom"


const list = [
    {
        text: 'LandingPage',
        icon: <Icon className="fa fa-map-marked-alt" />,
        url: "/map"
    },
    {
        text: 'Dashboard',
        icon: <Icon className="fa fa-columns" />,
        url: "/dashboard"
    },
    {
        text: 'Fleet overview',
        icon: <Icon className="fa fa-th-list" />,
        url: "/fleet"
    },
    {
        text: 'Single Unit',
        icon: <Icon className="fa fa-motorcycle" />,
        url: "/singleunit"
    },
    {
        text: 'Setting',
        icon: <Icon className="fa fa-cog" />,
        url: "/settings"
    },
]

const breadcrumbNameMap = {
    '/dashboard': 'Dashboard',
    '/fleet': 'Fleet Overview',
    '/singleunit': 'Single Unit',
    '/map': 'Map',
    '/settings': 'Settings',
}

const DrawerContainer = ({ classes, open, handleDrawerClose, handleDrawerOpen, match, theme }) => {
    return (
        <div className="overlay">
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >
                <div className={classes.toolbar}>
                    <img src={theme.logotype.logo}
                        alt="Logotype"
                        className={classes.logo}
                    />
                </div>
                <Divider />
                <List className={classes.drawer}>
                    {
                        list.map((item, index) => (
                            <NavLink
                                to={match.url + item.url}
                                key={index}
                                className={classes.link}
                                activeClassName="selected"
                            >
                                <MenuItem
                                    button
                                    icon={item.icon}
                                    text={item.text}
                                >
                                    <ListItemIcon className={classes.icons}>{item.icon}</ListItemIcon>
                                    <ListItemText className={classes.text}>{item.text}</ListItemText>
                                </MenuItem>
                            </NavLink>
                        ))
                    }
                </List>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                    })}
                >
                    <Icon className="fa fa-chevron-right" />
                </IconButton>
                <IconButton
                    color="inherit"
                    aria-label="closed drawer"
                    onClick={handleDrawerClose}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: !open,
                    })}
                >
                    <Icon className="fa fa-chevron-left" />
                </IconButton>
            </Drawer>
        </div>
    )
}

class NavigationSmall extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            setOpen: false,
            test: 1,
        }
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
        this.handleDrawerClose = this.handleDrawerClose.bind(this)
    }

    handleDrawerOpen() {
        this.setState({
            open: true,
        })
    }

    handleDrawerClose() {
        this.setState({
            open: false,
        })
    }

    // RENDER METHODS

    renderBreadcrumbs(classes) {
        return (
            <Route>
                {({ location }) => {
                    const pathnames = location.pathname.split('/').filter(x => x)


                    return (
                        <Breadcrumbs separator="" aria-label="breadcrumb" className={classes.breadcrumbs}>
                            {pathnames.map((value, index) => {
                                const last = index === pathnames.length - 1
                                const to = `/${pathnames.slice(1, index + 1).join('/')}`
                                return last ? (
                                    <Typography variant="h6" color="textPrimary" key={to}>
                                        {breadcrumbNameMap[to]}
                                    </Typography>
                                ) : (
                                        <Link color="inherit" to={to} key={to}>
                                            {breadcrumbNameMap[to]}
                                        </Link>
                                    )
                            })}
                        </Breadcrumbs>
                    )
                }}
            </Route>
        )
    }

    componentDidMount() {
        const data = randomMovement.getCordinates()
        this.props.updateUnits(data.units)
    }

    // RENDER METHODS END
    render() {
        const { classes, match, units, theme, changeUserRole, themeName, userRole } = this.props
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Toolbar display="flex">

                        {this.renderBreadcrumbs(classes)} {/* Breadcrubs */}

                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >

                            <BadgeComponent units={units} /> {/* Badges top-right corner in navbar */}

                            <DrawerRight userRole={userRole} changeUserRole={changeUserRole} themeName={themeName} /> {/* Settings drawer in top-right corner in navbar */}

                        </Grid>
                    </Toolbar>
                </AppBar>

                <DrawerContainer
                    classes={classes}
                    open={this.state.open}
                    handleDrawerClose={this.handleDrawerClose}
                    handleDrawerOpen={this.handleDrawerOpen}
                    match={match}
                    theme={theme}
                />

                <main className={classes.content} onClick={this.handleDrawerClose}>
                    <div className={classes.toolbar} />
                    <Container maxWidth="xl" style={{ padding: '0px' }}>
                        <div style={{ padding: '0px' }}>
                            <Switch>
                                <Route exact path={`${match.url}/map`} component={LandingPageContainer} />
                                <Route path={`${match.url}/dashboard`} component={DashboardContainer} />
                                <Route path={`${match.url}/fleet`} component={FleetContainer} />
                                <Route path={`${match.url}/singleunit`} component={SingleUnitContainer} />
                                <Route path={`${match.url}/settings`} component={SettingsView} />
                            </Switch>
                        </div>
                    </Container>
                </main>
            </div>
        )
    }
}

export default withStyles(styles)(NavigationSmall)