import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core/';
import { styles } from './AlertHistoryStyle';
import NoAlerts from './NoAlerts';
import AlertTabs from './AlertTabs';

const AlertModal = (props) => {
    const { classes, unit } = props;
    const [open, setOpen] = React.useState(false);
    const scrollType = 'paper'

    const handleClickOpen = scrollType => () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <div className={classes.alertButtonContainer}>
            <Button className={classes.button} onClick={handleClickOpen('paper')}>
                Show all alerts ({unit[0].alerts.length})
            </Button>
                <AlertTabs
                    open={open}
                    handleClose={handleClose}
                    scroll={scrollType}
                    classes={classes}
                    unit={unit}
                />
        </div>
    );
}

export default withStyles(styles)(AlertModal);