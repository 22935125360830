import { ADD_VISIBLE_MARKER } from "./actionTypes";

const initialState = {
    visibleMarkers: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_VISIBLE_MARKER:
            return Object.assign({}, state, {
                visibleMarkers: action.payload
            });

        default:
            return state
    }
}