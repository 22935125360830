export const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: "94px 24px 24px 16px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        position: "relative",
        zIndex: "999",
        minWidth: "300px",
        maxWidth: "300px",
        marginBottom: "8px",
        pointerEvents: "auto"
    },
    avatar: {
        width: "80px",
        height: "80px",
        boxShadow: "1px 2px 3px -1px rgba(0,0,0,.7)",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "3px",
        padding: "15px",
        marginTop: "-36px",
        marginRight: "15px",
        float: "left",
    },
    icon: {
        fontSize: "1.5em",
    },
    title: {
        fontSize: "16px",
        fontWeight: "700"
    },
    text: {
        color: theme.palette.black.main,
        fontSize: "28px"
    },
    leftFat: {
        margin: "0",
        padding: "0px 16px",
        textAlign: "left",
        fontSize: "16px",
        fontWeight: "700"
    },
    rightFat: {
        float: "right",
        fontSize: "16px",
        fontWeight: "700",
        color: "rgba(0, 185, 85, 0.87)"
    },
    fade: {
        visibility: "visible !important",
        zIndex: "999",
        position: "relative",
        pointerEvents: "none"
    },
    fadeButton: {
        zIndex: "1000",
        position: "absolute",
        right: "24px",
        top: "80px",
        color: theme.palette.white.main,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.darkBlue.main,
        }
    }
});