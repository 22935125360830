import React from "react"
import Chart from "react-apexcharts"
import { Paper, FormControl, Select, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Card from '../card'
import CardChartBox1 from '../commons/cardChartBox1'
import CardFooter from '../commons/cardFooter'
import { styles } from './lineChartComponentStyle'
import ThemeProvider from "@material-ui/core/styles/MuiThemeProvider";


class LineChartComponent extends React.Component {

    constructor(props) {
        super(props)
        const { colors, opacity, dataLabels, strokeColors, showGrid, markerColors, fillColors } = this.props
        this.state = {
            options: {
                colors: colors,
                chart: {
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true | '<img src="/static/icons/reset.png" width="20">',
                            customIcons: []
                        },
                        autoSelected: 'zoom'
                    },
                },
                fill: {
                    colors: fillColors,
                    opacity: opacity,
                    type: 'solid',
                    gradient: {
                        shade: 'light',
                        type: "horizontal",
                        shadeIntensity: 0,
                        gradientToColors: undefined,
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 100],
                        colorStops: []
                    },
                },
                dataLabels: {
                    enabled: dataLabels,
                    style: {
                        colors: ['#ffffff']
                    },
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: ["#ffffff"],
                    width: 3,
                    dashArray: 0,
                },
                title: {
                    text: '',
                    align: 'left'
                },
                markers: {
                    size: 5,
                    opacity: 0.9,
                    colors: markerColors,
                    strokeColor: ["#3E3E3E","#ffffff"],
                    strokeWidth: 2,

                    hover: {
                        size: 7,
                    }
                },
                grid: {
                    show: true,
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: strokeColors,
                    width: 3,
                    dashArray: 0,
                },
                xaxis: {
                    categories: ['1/10', '2/10', '3/10', '4/10', '5/10', '6/10', '7/10', '8/10', '9/10', '10/10', '11/10', '12/10', '13/10', '14/10', '15/10', '16/10', '17/10', '18/10', '19/10', '20/10', '21/10', '22/10', '23/10', '24/10', '25/10', '26/10', '27/10', '28/10', '29/10', '30/10', '31/10'],
                    labels: {
                        show: true,
                        style: {
                            colors: '#ffffff',
                        },
                    },
                    title: {
                        text: 'October',
                        style: {
                            color: "white",
                            fontSize: '12px',
                            fontFamily: 'Roboto, sans-serif',
                        },
                    },
                    axisBorder: {
                        show: true,
                        color: '#ffffff',
                        height: 1,
                        width: '100%',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    min: 0,
                    title: {
                        text: 'km / day',
                        style: {
                            color: "white",
                            fontSize: '12px',
                            fontFamily: 'Roboto, sans-serif',
                        },
                    },
                    labels: {
                        show: true,
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 160,
                        style: {
                            color: "white",
                            fontSize: '12px',
                            fontFamily: 'Roboto, sans-serif',
                        },
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    labels: {
                        colors: "#ffffff",
                        useSeriesColors: false
                    },
                    markers: {
                        show: true,
                        size: 5,
                        opacity: 0.9,
                        colors: markerColors,
                        strokeColor: "#3E3E3E",
                        strokeWidth: 2,
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " km"
                        }
                    }
                },
            },
            series: [
                {
                    name: "2018",
                    data: [14, 16, 7, 22, 13, 21, 20, 17, 10, 9, 14, 16, 7, 22, 13, 21, 20, 17, 10, 9, 14, 16, 7, 22, 13, 21, 20, 17, 10, 9, 13],
                },
                { 
                    name: "2019",
                    data: [12, 15, 9, 16, 10, 18, 24, 18, 10, 6, 11, 19, 9, 25, 17, 20, 22, 15, 16, 11, 17, 11, 9, 20, 15, 23, 27, 15, 8, 6, 11]
                }
            ],
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.paper}>
                <Card>
                    <CardChartBox1>
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="line"
                            width="100%"
                            height="244px"
                        />
                    </CardChartBox1>
                    <div className={classes.selectHolder}>
                        <div className={classes.selectHolderInner}>
                            <p className={classes.leftFat}>
                                Usage per
                            </p>
                            <FormControl className={classes.formControl} variant="standard">
                                <Select
                                    disableUnderline
                                    value={2}
                                    onChange={() => { }}
                                    inputProps={{
                                        name: 'age',
                                        id: 'outlined-age-simple',
                                    }}
                                >
                                    <MenuItem disabled value={1}>Week</MenuItem>
                                    <MenuItem value={2}>Month</MenuItem>
                                    <MenuItem disabled value={3}>Year</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <CardFooter>
                        Updated 7 days ago
              </CardFooter>
                </Card>
            </Paper>

        );
    }
}

export default withStyles(styles)(LineChartComponent);