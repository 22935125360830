export const styles = theme => ({
    cardFooter: {
        padding: "8px 16px 0px 16px !important",
        textAlign: "right",
        fontSize: "0.9em",
        fontWeight: "100",
        borderRadius: "0",
        justifyContent: "space-between",
        alignItems: "left",
    },
});