import React from 'react'
import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/images/marker-shadow.png'
import { withStyles } from '@material-ui/core/styles'
import { Checkbox, FormControlLabel, Paper, Icon, IconButton, FormControl, Select, MenuItem, Divider } from '@material-ui/core'
import { styles } from './filteringPagingUnitsStyle'

function FilteringPagingUnits(props) {

    const { classes, outOffBoundsChecked, insideBoundsChecked, alertsChecked, healthyChecked, setState,
        subtractPaging, addPaging, changePageInc, pageIncrement, unitsAmount, units, reinitializeMovement } = props

    return (
        <Paper className={classes.filter}>
            <div className={classes.filterInner}>
                <p className={classes.filterText}>Filters:</p>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={outOffBoundsChecked}
                            onChange={() => {
                                setState({
                                    insideBoundsChecked: insideBoundsChecked,
                                    alertsChecked: alertsChecked,
                                    healthyChecked: healthyChecked,
                                    outOffBoundsChecked: !outOffBoundsChecked
                                })
                                reinitializeMovement()
                            }}
                            value="checkedB"
                            color="primary"
                        />
                    }
                    label="Out off bounds"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={insideBoundsChecked}
                            onChange={() => {
                                setState({
                                    insideBoundsChecked: !insideBoundsChecked,
                                    alertsChecked: alertsChecked,
                                    healthyChecked: healthyChecked,
                                    outOffBoundsChecked: outOffBoundsChecked
                                })
                                reinitializeMovement()
                            }}
                            value="checkedB"
                            color="primary"
                        />
                    }
                    label="Inside bounds"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={alertsChecked}
                            onChange={() => {
                                setState({
                                    insideBoundsChecked: insideBoundsChecked,
                                    alertsChecked: !alertsChecked,
                                    healthyChecked: healthyChecked,
                                    outOffBoundsChecked: outOffBoundsChecked
                                })
                                reinitializeMovement()
                            }}
                            value="checkedB"
                            color="primary"
                        />
                    }
                    label="Alerts"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={healthyChecked}
                            onChange={() => {
                                setState({
                                    insideBoundsChecked: insideBoundsChecked,
                                    alertsChecked: alertsChecked,
                                    healthyChecked: !healthyChecked,
                                    outOffBoundsChecked: outOffBoundsChecked
                                })
                                reinitializeMovement()
                            }}
                            value="checkedB"
                            color="primary"
                        />
                    }
                    label="Healthy"
                />
            </div>
            <Divider className={classes.filterFooter} />
            <div className={classes.filterPaging}>
                <div className={classes.pagingLeft}>
                    <p className={classes.pagingText}>Units on map</p>
                    <FormControl className={classes.formControl} variant="standard">
                        <Select
                            className={classes.pagingDropdown}
                            disableUnderline
                            value={pageIncrement}
                            onChange={(e, d) => changePageInc(d)}
                            inputProps={{
                                name: 'units',
                                id: 'outlined-units-simple',
                            }}
                        >
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.pagingRight}>
                    {unitsAmount - pageIncrement} - {unitsAmount} of {units.length}
                    <IconButton className={classes.pagingButton} onClick={() => subtractPaging()}>
                        <Icon className={`fa fa-chevron-left ${classes.pagingIcon}`} />
                    </IconButton>
                    <IconButton className={classes.pagingButton} onClick={() => addPaging()}>
                        <Icon className={`fa fa-chevron-right ${classes.pagingIcon}`} />
                    </IconButton>
                </div>
            </div>
        </Paper>
    );
}

export default withStyles(styles)(FilteringPagingUnits)