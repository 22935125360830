export const styles = theme => ({
    progress: {
        color: "inherit",
        fontSize: "inherit"
    },
    text: {
        fontSize: ".7em"
    },
    textExpired: {
        fontSize: ".7em",
        color: theme.palette.error.main
    }
}); 