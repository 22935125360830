import { LOGIN } from "../commonActions/commonActionTypes";

const initialState = {
    auth: localStorage.getItem('rememberMe')
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return {
                auth: !state.auth
            }
        default:
            return state;
    }
} 