import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, CardContent, Avatar, Typography, Table, TableBody, TableCell, TableRow, Icon } from '@material-ui/core/';
import AlertModal from '../alertHistory/AlertModal';
import RawDataModal from '../rawDataModal/rawDataModal';
import classNames from 'classnames';
import Card from '../card';
import { styles } from './dataListsSingleStyle'


function createData(name, value) {
    return { name, value };
}

const DataListSingle = ({ classes, unit }) => {
    const generalInformation = [
        createData('Brand', "Zundapp"),
        createData('Model', '441'),
        createData('Generation', '4'),
        createData('Modification (Engine)', '107 cc, 2 stroke'),
        createData('Start of production', '1991 year'),
        createData('End of production', '1994 year'),
        createData('Body type', 'Standard'),
        createData('Seats', '1'),
    ];

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={3}
                direction="row"
                justify="space-evenly"
                alignItems="stretch"
                className={classes.grid}
            >
                <Grid className={classes.gridItem} item xs={12} md={6} lg={6}>
                    <Paper className={classes.paper}>
                        <Card>
                            <CardContent>
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    <Icon className={classNames(classes.icon, "fa fa-wrench")} />
                                </Avatar>
                                <Typography
                                    align="right"
                                    className={classes.title}
                                >
                                    Unit specs
                                </Typography>
                                <Table className={classes.table}>
                                    <TableBody>
                                        {generalInformation.map(row => (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>{row.value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CardContent>
                            <RawDataModal unit={unit} />
                        </Card>
                    </Paper>
                </Grid>
                <Grid className={classes.gridItem} item xs={12} md={6} lg={6}>
                    <Paper className={classes.paper}>
                        <Card>
                            <CardContent>
                                <Avatar aria-label="recipe" className={classes.avatar}>
                                    <Icon className={classNames(classes.icon, "fa fa-bell")} />
                                </Avatar>
                                <Typography
                                    align="right"
                                    className={classes.title}
                                >
                                    Active alerts ({unit[0].alerts.length})
                                </Typography>
                                <Table className={classes.alertsTable}>
                                    <TableBody>
                                        {unit[0].alerts.slice(0, 3).map((alert, index) => {
                                            if (alert.level === 3) {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            <Icon className={classNames(classes.icon, classes.iconCritical, "fa fa-exclamation-circle")} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {alert.message}
                                                        </TableCell>
                                                        <TableCell>
                                                            {alert.time}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            } else if (alert.level === 2) {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            <Icon className={classNames(classes.icon, classes.iconMajor, "fa fa-exclamation-circle")} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {alert.message}
                                                        </TableCell>
                                                        <TableCell>
                                                            {alert.time}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            } else {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            <Icon className={classNames(classes.icon, classes.iconMinor, "fa fa-exclamation-circle")} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {alert.message}
                                                        </TableCell>
                                                        <TableCell>
                                                            {alert.time}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                                {Object.keys(unit[0].alerts).length === 0 ? "No active alerts" : ""}
                                <AlertModal unit={unit} />
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default withStyles(styles)(DataListSingle);
